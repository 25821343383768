import { constants } from "../../config/config";
import GlobalDataStore from "../../GlobalDataStore";
import request from "../base/baseService";

let url = `${constants.api.url}api/user`;
let adminUrl = `${constants.api.url}api/adminuser`;

const getUser = () => {
    return request({
        url: `${url}/${GlobalDataStore.getTenantId()}`,
        method: 'GET',
    })
}

const resetPassword = (username) => {
    return request({
        url: `${url}/resetpassword/${GlobalDataStore.getTenantId()}/${encodeURIComponent(username)}`,
        method: 'PUT'
    })
}

const validateTempPassword = (guid) => {
    return request({
        url: `${url}/isrecoverycodevalid/${guid}`,
        method: 'POST'
    })
}

const setNewPassword = (userChangePassword) => {
    return request({
        url: `${url}/setnewpassword`,
        method: 'POST',
        data: userChangePassword
    })
}

const registerUser = (user) => {
    return request({
        url: `${url}/register/${GlobalDataStore.getTenantId()}`,
        method: 'POST',
        data: user
    })
}

const confirmEmail = (guid) => {
    return request({
        url: `${url}/confirmemail/${guid}`,
        method: 'POST'
    })
}

const resendConfirmEmail = (user) => {
    return request({
        url: `${url}/resendconfirmationemail/${GlobalDataStore.getTenantId()}`,
        method: 'POST',
        data: user
    })
}

const getRole = () => {
    return request({
        url: `${url}/role`,
        method: 'GET'
    })
}


const getSalesForTenant = (filters) => {
    return request({
        url: `${adminUrl}/getsalesfortenat`,
        method: 'POST',
        data: filters
    })
}

const changePassword = () => request({
    url: `${url}/change-password`,
    method: 'POST'
});


const changeUserCulture = (culture) => {
    return request({
        url: `${url}/change-culture/${culture}`,
        method: 'POST',
        // data: culture
    })
}

export const UsersService = {
    getUser,
    resetPassword,
    validateTempPassword,
    setNewPassword,
    registerUser,
    confirmEmail,
    resendConfirmEmail,
    getRole,
    getSalesForTenant,
    changePassword,
    changeUserCulture,
}


export default UsersService;