/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import { FormControl, Select, InputLabel, MenuItem, TextField } from '@mui/material';
import {addHours, sub, startOfToday, startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfYear, endOfYear } from 'date-fns';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import { it } from 'date-fns/locale';
import { usePersistentState } from '../persistentState.js';
import { useTranslation } from 'react-i18next';

const setStyles = (theme) => makeStyles(() => ({
    dateContainer: {
        display: 'flex',
    },
    dateContainerResize: {
        display: 'flex',
        [theme.breakpoints.down(1300)]: {
            display: 'block',
        }

    },
    rangeContainerResize: {
        [theme.breakpoints.down(1300)]: {
            marginLeft: '-258px'
        }
    }
}))()

const SelectItems = {
    Today: 'today',
    Yesterday: 'yesterday',
    ThisWeek: 'thisWeek',
    LastWeek: 'lastWeek',
    ThisMonth: 'thisMonth',
    LastMonth: 'lastMonth',
    ThisYear: 'thisYear',
    LastYear: 'lastYear',
    CustomPeriod: 'customPeriod'
};

const DefaultItem = 'ThisMonth';

const DateRange = forwardRef(({
    resize,
    changeCallback,
    persistKey
}, ref) => {

    const theme = useTheme();
    const classes = setStyles(theme);

    const [args] = useState({ changeCallback });
    const [t] = useTranslation();
    const [from, setFrom] = usePersistentState(
        persistKey, 'DateRangeFrom', null);

    const [to, setTo] = usePersistentState(
        persistKey, 'DateRangeTo', null);

    const [selected, setSelected] = usePersistentState(
        persistKey, 'DateRangeSelected', DefaultItem);

    const applyChanges = () => {
        if (typeof args.changeCallback === 'function') {
            args.changeCallback(from, to);
        }
    };

    const onRangeChange = event => {
        const item = typeof event === 'string' ? event
            : event?.target?.value || selected || DefaultItem;
        setSelected(item);
        const setter = setters['set' + item];
        if (typeof setter !== 'function') {
            throw new Error(`function 'set${item}()' not found`);
        }
        setter();
    };

    useEffect(applyChanges, [from, to]);
    useEffect(onRangeChange, []);

    useImperativeHandle(ref, () => ({
        isDefaultRange: () => selected === DefaultItem,
        setDefaultRange: () => onRangeChange(DefaultItem)
    }));

    const setters = {
        setToday: () => {
            const date = addHours(startOfToday(),2);
            setFrom(date);
            setTo(date);
        },
        setYesterday: () => {
            const date = addHours(startOfToday(),2);
            date.setDate(date.getDate() - 1);
            setFrom(date);
            setTo(date);
        },
        setThisWeek: () => {
            const date = addHours(startOfToday());
            setFrom(startOfWeek(date, { weekStartsOn: 1 }));
            setTo(endOfWeek(date, { weekStartsOn: 1 }));
        },
        setLastWeek: () => {
            const date = sub(addHours(startOfToday(),2), { weeks: 1 });
            setFrom(startOfWeek(date, { weekStartsOn: 1 }));
            setTo(endOfWeek(date, { weekStartsOn: 1 }));
        },
        setThisMonth: () => {
            const date = addHours(startOfToday(),2);
            setFrom(startOfMonth(date));
            setTo(endOfMonth(date));
        },
        setLastMonth: () => {
            const date = sub(addHours(startOfToday(),2), { months: 1 });
            setFrom(startOfMonth(date));
            setTo(endOfMonth(date));
        },
        setThisYear: () => {
            const date = addHours(startOfToday(),2);
            setFrom(startOfYear(date));
            setTo(endOfYear(date));
        },
        setLastYear: () => {
            const date = sub(addHours(startOfToday(),2), { years: 1 });
            setFrom(startOfYear(date));
            setTo(endOfYear(date));
        },
        setCustomPeriod: () => { }
    };

    return (
        <>
            <div className={resize ? classes.dateContainerResize : classes.dateContainer}>
                <FormControl variant="standard" sx={{
                    m: 1,
                    minWidth: '15em'
                }}>
                    <InputLabel id="lblDateRange">{t('filter.period')}</InputLabel>
                    <Select
                        labelId="lblDateRange"
                        value={selected}
                        label={t('filter.period')}
                        onChange={onRangeChange}
                    >
                        {Object.keys(SelectItems).map(key => (
                            <MenuItem key={key} value={key}>
                                {t("period." + SelectItems[key])}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {selected === 'CustomPeriod' &&
                    <div className={resize ? classes.rangeContainerResize : null}>
                        <LocalizationProvider locale={it} dateAdapter={DateAdapter}>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: '15em' }}>
                                <DatePicker
                                    label={t('filter.from')}
                                    value={from}
                                    onChange={date => setFrom(date)}
                                    renderInput={params => <TextField variant="standard" {...params} />}
                                    shouldDisableDate={date => date > to}
                                />
                            </FormControl>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: '15em' }}>
                                <DatePicker
                                    label={t('filter.to')}
                                    value={to}
                                    onChange={date => setTo(date)}
                                    renderInput={params => <TextField variant="standard" {...params} />}
                                    shouldDisableDate={date => date < from}
                                />
                            </FormControl>
                        </LocalizationProvider>
                    </div>
                }
            </div>
        </>
    );
});

DateRange.propTypes = {
    resize: PropTypes.bool,
    changeCallback: PropTypes.func.isRequired,
    persistKey: PropTypes.string,
};

export default DateRange;
