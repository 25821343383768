/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect, useState } from 'react'
import Access from '../Access';
import { useParams } from "react-router-dom";
import UsersService from '../../../service/commons/userService';
import GlobalDataStore from '../../../GlobalDataStore';
import { constants } from '../../../config/config';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import useLoaderHook from '../../../hooks/useLoaderHook';
import { useHistory } from 'react-router-dom';
import { disableLoading } from '../../../redux/slices/loaderSlice';
import { useTranslation } from 'react-i18next';

const setStyles = makeStyles((theme) => {
    const themeBackground = theme && theme.background ? theme.background : null;
    const themeTitle = theme && theme.firstTitle && theme.firstTitle.color ? theme.firstTitle.color : null;
    return {
        root: {
            width: '100vw',
            height: '100vh',
            background: themeBackground,
        },
        logo: {
            display: "flex",
            justifyContent: 'center',
            width: '100%',
            paddingTop: '50px',
            height: '130px',
            zIndex: '100',
            background: themeBackground,
            '& img': {
                height: '80px'
            }
        },
        containerForm: {
            display: "flex !important",
            justifyContent: "center",
            alignItems: "flex-start",
            marginTop: '50px'
        },
        formStyle: {
            width: '320px',
            display: 'block',
            boxShadow: '3px 3px 6px rgba(0 0 0 / 0.16)',
            padding: '16px 27px',
            background: 'rgba(255, 255, 255, .3)',
            borderRadius: '5px',
        },
        submitStyle: {
            margin: '10px 0 0 0',
            padding: '5px 8px',
            border: '1px solid',
            borderRadius: '5px',
            width: '100%',
            textTransform: 'uppercase',
            fontSize: '14px',
            display: 'block',
            background: 'rgba(255, 255, 255, .2)',
            cursor: 'pointer',
        },
        errorPswConf: {
            fontSize: '12px',
            color: 'red'
        },
        invalidPsw: {
            fontSize: '12px',
            '& p': {
                margin: '2px 0px'
            }
        },
        linkExpired: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            color: themeTitle,
            cursor: 'pointer',
            marginTop: '24px'
        }
    }
});

const requestEmpty = {
    oldPassword: null,
    newPassword: null,
    isResetFromRecoveryCode: true
}


function ResetPassword() {
    const classes = setStyles();
    const history = useHistory();
    let { guid } = useParams();

    const [, initLoading, disableLoadingHook, ,] = useLoaderHook();

    const [logo, setLogo] = useState({});

    const [valid, setValid] = useState(null);

    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    //true quando le psw sono uguali e non vuote
    const [compare, setCompare] = useState(false);

    const [request, setRequest] = useState(requestEmpty);

    //true quando all'invio le due psw non coincidono
    const [errorPswConf, setErrorPswConf] = useState(false)

    const [invalidPsw, setInvalidPsw] = useState(false)
    const [t] = useTranslation();

    /**
     * get Logo
     */
    useEffect(() => {
        setLogo(constants.paths.logo(GlobalDataStore.getTenantId()));
    }, [])


    useEffect(async () => {
        try {
            initLoading();
            setValid(await UsersService.validateTempPassword(guid));
        } catch (ex) {
            setValid(false);
            throw ex;
        } finally {
            disableLoading();
        }
    }, [])

    useEffect(() => {
        let rec = {
            ...request,
            oldPassword: guid,
            newPassword: password
        };
        setRequest(rec);
    }, [guid, password])


     /**
     * Controllo che la passwor inserita nel primo campo coincida con la seconda
     */
    useEffect(() => {
        if (password && password === passwordConfirm) {
            setCompare(true);
        }
        else {
            setCompare(false);
        }
    }, [passwordConfirm, password])


    /**
     * se le psw non sono uguali svuota la seconda e mostra errore;
     * se psw non volida mostra messaggio;
     */
    const reset = () => {
        if (password && password === passwordConfirm) {
            setErrorPswConf(false)
            if (checkPassword(password)) {
                toSetNewPassword();
            } else {
                setInvalidPsw(true);
            }
        } else {
            if (checkPassword(password)) {
                setInvalidPsw(false);
                setErrorPswConf(true);
            } else {
                setInvalidPsw(true);
                setErrorPswConf(false);
            }
            setPasswordConfirm('');
        }
    }

    const toSetNewPassword = () => {
        initLoading(true);

        UsersService.setNewPassword(request)
            .then((resp) => {
                window.location.replace("/Login");
                disableLoadingHook();
            }).catch((err) => {
                disableLoadingHook();
            })
    }

    /**
     * Controllo che la stringa contenga una maiuscola - minuscola - numero - carattere speciale e che sia lunga almeno 8 caratteri
     * @param {string} str 
     * @returns 
     */
    const checkPassword = (str) => {
        const re = (/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/);
        return re.test(str)
    }

    const navigateToHome = () => {
        history.replace('')
    }

    return (
        <>
            <div className={classes.root}>
                <div id='loginLogo' className={classNames(classes.flexCenter, classes.logo)}>
                    <img alt='logo' src={logo}></img>
                </div>
                {valid === true && (
                    <div className={classes.containerForm}>
                        <div className={classes.formStyle}>

                            <div>
                                <Access
                                    label={t('password.newpassword')}
                                    type={"password"}
                                    initValue={password}
                                    outputValue={(value) => setPassword(value)}>
                                </Access>
                            </div>

                            <div className={password.length === 0 ? 'disabled' : null}>
                                <Access
                                    label={t('password.repeatpassword')}
                                    type={"password"}
                                    initValue={passwordConfirm}
                                    outputValue={(value) => setPasswordConfirm(value)} >
                                </Access>
                            </div>

                           
                            {(password !== "" && passwordConfirm !== "") && !compare && (
                                <span className={classes.errorPswConf}>{t('password.doNotMatch')} </span>
                            )}


                            {invalidPsw && (
                                <div className={classes.invalidPsw}>
                                    <p>{t('password.invalidpswrow1')} </p>
                                    <p>{t('password.invalidpswrow2')} </p>
                                    <p>{t('password.invalidpswrow3')}</p>
                                    <p>{t('password.invalidpswrow4')}</p>
                                </div>
                            )}

                            {/* outputValue={(value) => setPasswordSecond(value)} */}
                            <button onClick={() => reset()} className={classes.submitStyle} type="submit">{t('password.send')}</button>
                        </div>
                    </div>
                )}
                {valid === false && (
                    <div className={classes.linkExpired} onClick={() => navigateToHome()} >{t('password.linkexpired')}</div>
                )}
            </div>
        </>
    )
}


export default ResetPassword;
