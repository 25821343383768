/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import ShowHideLoader from '../../../hooks/showHideLoader';
import RequestParameters from './RequestParameters';
import RequestDescription from './RequestDescription';
import CustomRequestService from '../../../service/adminServices/customRequestService';
import CustomRequestUserService from '../../../service/usersService/customRequestUserService';
import RequestMissingDR from './RequestMissingDR';
import DigitalResourceService from '../../../service/digitalResourceService';
import SidebarInfoContainer from '../CustomRequestDetails/SidebarInfoContainer';
import { Button } from '@mui/material';
import MustBeLogged from '../../commons/MustBeLogged';
import GlobalDataStore from '../../../GlobalDataStore';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'rxjs';

const setStyles = makeStyles((theme) => {
    const themeTitleColor = theme && theme.firstTitle && theme.firstTitle.color ? theme.firstTitle.color : null;
    const themeTitleFont = theme && theme.firstTitle && theme.firstTitle.font ? theme.firstTitle.font : null;
    return {
        root: {
            margin: '20px 60px 0 60px',
        },
        headerContainer: {
            textAlign: 'center',
            fontWeight: '500',
            color: themeTitleColor,
            fontFamily: themeTitleFont,
            marginBottom: '2.5em'
        },
        title: {
            fontSize: '1.5em'
        },
        subtitle: {
            fontSize: '1em',
        },
        bodyContainer: {
            paddingBottom: '100px',
            display: 'flex',
            justifyContent: 'space-between',
        },
        widthLarge: {
            minWidth: 'calc(100% - 250px)',
            maxWidth: '1200px',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        flexEnd: {
            display: 'flex',
            justifyContent: 'flex-end',
            maxWidth: '1532px',
            margin: '0 auto'
        },
        requestContainer: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            // margin: '0 auto',
            marginRight: '20px',
            marginBottom: '2em',
            width: '85%',
            maxWidth: '1400px',
        },
        requestBannerHeader: {
            width: 'calc(100% + 1.1em)',
            display: 'flex'
        },
        requestBanner: {
            width: '100%',
            backgroundColor: themeTitleColor,
        },
        requestBannerArrowHeader: {
            width: 0,
            height: 0,
            borderTop: '24px solid transparent',
            borderLeft: '18px solid' + themeTitleColor,
            borderBottom: '24px solid transparent'
        },
        requestBody: {
            display: 'flex',
            width: '100%',
            margin: '0 auto',
            background: 'rgba(255, 255, 255, .3)',
            boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
        },
        requestDescriptionContainer: {
            width: '65%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            justifyContent: 'space-between'
        },
        sentRequestButton: {
            border: '1px solid #707070!important',
            padding: '.2em 3em!important',
            marginBottom: '1.3em!important',
            marginRight: '20px !important',
            color: '#707070!important',
            '&.Mui-disabled': {
                opacity: '.5',
                backgroundColor: '#f0f0f0'
            }
        }
    }
})


/**
 * Componente per la creazione di una nuova richiesta
 */
export default function RequestCreation() {
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const idDigitalResource = parseInt(query.get('idDigitalResource')) || null;
    const showRenewConcession = query.get('showRenewConcession')?.toLowerCase() === 'true';
    const providedTitle = query.get('title');
    const providedInventoryNumber = query.get('inventoryNumber');

    const classes = setStyles();
    const history = useHistory();
    const [showLoader, hideLoader] = ShowHideLoader();
    const [newRequestData, setNewRequestData] = useState({});
    const [missingDRConfirmed, setMissingDRConfirmed] = useState(!!idDigitalResource);
    const [drMetadata, setDrMetadata] = useState();
    const [drInventoryNumber, setDrInventoryNumber] = useState();
    const [t] = useTranslation();
    const [cmwParameters, setCmwParameters] = useState();
    const [loadedParameters, setLoadedParameter] = useState(false);

    const setNewRequestValue = (value) => {
        setNewRequestData({
            ...newRequestData,
            ...value
        })

    };

    useEffect(async () => {
        if (idDigitalResource) {
            try {
                showLoader();
                const data = await DigitalResourceService.getDetails(idDigitalResource);
                setDrMetadata(data.resourceMetadata);
                setDrInventoryNumber(data.inventoryNumber);
            } finally {
                hideLoader();
            }
        }
    }, []);

    useEffect(() => {
        setLoadedParameter(true);
        if (providedTitle && providedInventoryNumber) {
            setCmwParameters({
                title: providedTitle,
                inventoryNumber: providedInventoryNumber
            });
        }
    }, [])

    const createNewRequest = async () => {
        try {
            showLoader();
            const state = (await CustomRequestService.getStatesList())
                .find(x => x.stateLabel.toUpperCase() === 'CREATED_BY_CUSTOMER');
            if (!state) {
                throw new Error('cannot find state "CREATED_BY_CUSTOMER"');
            }
            const data = {
                idDigitalResource,
                idCustomRequestState: state.idState,
                messages: [{
                    messageText: newRequestData.description
                }],
                parameters: newRequestData.parameters,
                renewConcession: newRequestData.renewConcession
            };
            await CustomRequestUserService.createNewRequest(data);
            history.push('/App/CRsList');
        } finally {
            hideLoader();
        }
    };

    if (!GlobalDataStore.isLoggedIn()) {
        return (
            <div>
                <MustBeLogged />
            </div>
        );
    }

    return (
        <div id='requestCreation' className={classes.root}>

            <div className={classes.headerContainer}>
                <h2 className={classes.title}>{t('requestcreation.customrequest')}</h2>
                <p className={classes.subtitle}>{t('requestcreation.messagerequestcreation')}.</p>
            </div>
            <div className={classes.bodyContainer}
                style={{
                    display: idDigitalResource ? 'flex' : 'block',
                    alignItems: idDigitalResource ? 'start' : '',
                }}>
                {idDigitalResource && (
                    // Spalla sx coi dati della risorsa digitale presente a sistema
                    <SidebarInfoContainer
                        item={drMetadata}
                        drInventoryNumber={drInventoryNumber}
                        idDigitalResource={idDigitalResource}
                        isExistentDR={idDigitalResource ? true : false}
                    />
                )}
                {!idDigitalResource && loadedParameters && (
                    // Form di inserimento dei dati della risorsa digitale mancante
                    <RequestMissingDR
                        onParametersConfirmed={confirmed => setMissingDRConfirmed(confirmed)}
                        onParametersChanged={args => setNewRequestValue({
                            parameters: { ...newRequestData.parameters, ...args }
                        })}
                        cmwParameters={cmwParameters && isEmpty(cmwParameters) ? cmwParameters : null}
                    />
                )}
                <div className={idDigitalResource ? classes.widthLarge : classes.flexEnd}>
                    <div className={classes.requestContainer}>
                        <div className={classes.requestBannerHeader}>
                            <div className={classes.requestBanner}></div>
                            <div className={classes.requestBannerArrowHeader}></div>
                        </div>
                        <div className={classes.requestBody}>

                            <RequestParameters
                                onParametersChanged={args => setNewRequestValue({
                                    parameters: { ...newRequestData.parameters, ...args }
                                })}>
                            </RequestParameters>

                            <div className={classes.requestDescriptionContainer}>
                                <RequestDescription
                                    showRenewConcession={showRenewConcession}
                                    onParametersChanged={setNewRequestValue}>
                                </RequestDescription>

                                <Button
                                    disabled={!newRequestData.description || !missingDRConfirmed}
                                    onClick={createNewRequest}
                                    className={classes.sentRequestButton}
                                >
                                    {t('requestcreation.customrequest.send')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};