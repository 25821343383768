import React from 'react';
import { makeStyles } from '@mui/styles';

const setStyles = makeStyles(() => ({
  root: {
    width: '262px',
    minWidth: '262px',
    padding: ' 8px',
    background: '#f5f5f5',
    maxHeight: 'calc(100vh - 70px)',
    // height: '100vh',
    marginRight: '6px',
    overflowY: 'scroll'
  },
  mainImageContainer: {
    width: "9em",
    height: "9em",
    borderRadius: "100%",
    margin: "auto",
    marginTop: '1em',
    backgroundRepeat: 'round'
  }
}))


const LeftColumn = ({
  renderInformation,
  isFilterBox,
  backgroundImage
}) => {
  const classes = setStyles();
  const imageUrl = backgroundImage ||
    '/images/empty-profile-picture.png';

  return (
    <div className={classes.root}>
      {!isFilterBox && <div className={classes.mainImageContainer} style={{
        backgroundImage: `url(${imageUrl})`
      }}>
      </div>}
      {renderInformation()}
    </div>
  )
}

export default LeftColumn;
