import Loader from '../commons/Loader';
import { enableLoading, disableLoading } from '../redux/slices/loaderSlice';
import { useSelector, useDispatch } from 'react-redux';

const countCheckUrls = [
    '/App/NewCR/'
];

let openedCount = 0;

// todo: da rivedere dove si apre/chiude il loader. in teoria si dovrebbe chiudere
// tutte le volte che si apre, ma non è così perché a volte rimane aperto. allora
// la modalità "rigorosa" la usiamo per ora solo dove serve.
const checkOpenedCount = () => {
    const path = window.location.pathname?.toLowerCase() || '';
    return !!countCheckUrls.find(x => path.startsWith(x.toLocaleLowerCase()));
};

const useLoaderHook = () => {

    const dispatch = useDispatch();


    const initLoader = (initLoading = false) => {
        initLoading ? enableLoadingHook() : disableLoadingHook();
    }

    const disableLoadingHook = () => {
        if (!checkOpenedCount() || --openedCount === 0) {
            dispatch(disableLoading());
        }
        // loadingSubject.next(false);
    }

    const enableLoadingHook = () => {
        if (checkOpenedCount()) {
            if (++openedCount < 1) {
                openedCount = 1;
            }
        } else {
            openedCount = 0;
        }
        dispatch(enableLoading());
        // loadingSubject.next(true);
    }

    const renderSpinner = () => {
        if (loading) {
            return <Loader class="loader" />;
        }
    }

    const loading = useSelector((state) => {
        const loading = state && state.loader ? state.loader.isLoading : false;
        return loading;
    });


    return [loading, initLoader, disableLoadingHook, enableLoadingHook, renderSpinner];

}

export default useLoaderHook;