/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import ShowHideLoader from '../../hooks/showHideLoader';
import DigitalResourceService from '../../service/adminServices/digitalResourceService';

export default function CmwPlugin() {
    const { idDR, idTenant } = useParams();
    const [showLoader, hideLoader] = ShowHideLoader();
    const [t] = useTranslation();
    const { search } = useLocation();
    const query = new URLSearchParams(search);

    useEffect(async () => {
        try {
            showLoader();
            const url = await DigitalResourceService.getRighlyId(
                idDR,
                idTenant,
                query.get('title'),
                query.get('inventoryNumber')
            );
            window.location.href = url;
        } finally {
            hideLoader();
        }
    }, []);

    return (
        <div>
            <br />
            <br />
            <center>
                <span>{t('cmwplugin.redirect')}</span>
            </center>
        </div>
    );
};