/* eslint react-hooks/exhaustive-deps: "off" */
import { lazy } from 'react';
import RequestCreation from '../../UsersArea/CustomRequest/NewRequest/RequestCreation';

const HomePage = lazy(() => import('../../UsersArea/HomePage/HomePage'));
const UserCreation = lazy(() => import('../../UsersArea/UserCreation/RegisterUser'));
const Login = lazy(() => import('../../UsersArea/Login/Login'));
const Orders = lazy(() => import('../../UsersArea/OrdersList'));
const OrdersDetails = lazy(() => import('../../UsersArea/OrdersList/OrdersTable/OrderDetail/OrderDetail'));
const Basket = lazy(() => import('../../UsersArea/Basket/Basket'));
const OrderSummary = lazy(() => import('../../UsersArea/OrderSummary/OrderSummary'))
const CustomRequestSummary = lazy(() => import('../../UsersArea/CustomRequest/CustomRequestSummary/CustomRequestSummary'))
const CustomRequestDetailUser = lazy(() => import('../../UsersArea/CustomRequest/CustomRequestDetails/CustomRequestDetails'));
const CustomRequestsListUser = lazy(() => import('../../UsersArea/CustomRequest/CustomRequestsListUser'));
const DigitalResourceDetails = lazy(() => import('../../UsersArea/DigitalResourceDetails/DigitalResourceDetails'));
const Support = lazy(() => import('../../UsersArea/Support/SupportPage'));

const userRoutes = [
    {
        path: '/App',
        component: HomePage,
        name: 'HomePage',
        exact: true
    },
    {
        path: '/App/UserRegistration',
        component: UserCreation,
        name: 'UserCreation',
    },
    {
        path: '/App/Login',
        component: Login,
        name: 'Login',
    },
    {
        path: '/App/Orders',
        component: Orders,
        name: 'Orders',
        exact: true
    },
    {
        path: '/App/Orders/Details/:idOrder',
        component: OrdersDetails,
        name: 'OrdersDetails',
    },
    {
        path: '/App/Basket',
        component: Basket,
        name: 'Basket',
    },
    {
        path: '/App/OrderSummary',
        component: OrderSummary,
        name: 'OrderSummary',
    },
    {
        path: '/App/CustomRequestSummary/:id',
        component: CustomRequestSummary,
        name: 'CustomRequestSummary',
    },
    {
        path: '/App/CustomRequest/Details/:idCR',
        component: CustomRequestDetailUser,
        name: 'CustomRequestDetailUser'
    },
    {
        path: '/App/DigitalResourceDetails/:id',
        component: DigitalResourceDetails,
        name: 'DigitalResourceDetails'
    },
    {
        path: '/App/CRsList',
        component: CustomRequestsListUser,
        name: 'CustomRequestsListUser'
    },
    {
        path: '/App/NewCR',
        component: RequestCreation,
        name: 'RequestCreation'
    },
    {
        path: '/App/Support',
        component: Support,
        name: 'Support'
    },

];

export default userRoutes;
