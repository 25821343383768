/**
 * Servizio per i pagamenti NEXI e PagoPA
 */

import request from "./base/baseService";
import { constants } from "../config/config";

const urlOrders = `${constants.api.url}api/orders`;
const urlNexi = `${constants.api.url}api/nexi`;
const urlPagoPa = `${constants.api.url}api/pagopa`;

// Url PaymentsSettings
const urlSettings = `${constants.api.url}api/paymentsettings`;

const ORDER_PAID_STATUS = {
    NOT_PAID: 'NotPaid',
    PAYING: 'Paying',
    PAID_OK: 'PaidOk'
};

const hasOrderBeenPaidByIdOrder = idOrder => hasOrderBeenPaid('O-' + idOrder);
const hasOrderBeenPaidByIdPayment = idPayment => hasOrderBeenPaid('P-' + idPayment);

const hasOrderBeenPaid = identifier => request({
    url: `${urlOrders}/has-been-paid/${identifier}`,
    method: 'GET'
});

const nexiCheckPaymentResult = queryString => request({
    url: `${urlNexi}/check-payment-result/?queryString=${encodeURIComponent(queryString)}`,
    method: 'GET'
});

const nexiGetPaymentForm = idOrder => request({
    url: `${urlNexi}/get-payment-form/${idOrder}`,
    method: 'GET'
});

const pagoPaStartPayment = idOrder => request({
    url: `${urlPagoPa}/start-payment/${idOrder}`,
    method: 'POST'
});

const pagoPaCheckResult = paymentIdOrSessionId => request({
    url: `${urlPagoPa}/check-result/${paymentIdOrSessionId}`,
    method: 'POST'
});

const pagoPaWaitForPayment = (sessionId, counter = null) => {
    return new Promise(resolve => {
        if (!counter) {
            counter = { count: 0 };
        }
        setTimeout(async () => {
            const status = await hasOrderBeenPaid('S-' + sessionId);
            if (
                ++counter.count < constants.payments.maxRetryCount()
                && status === ORDER_PAID_STATUS.PAYING
            ) {
                resolve(await pagoPaWaitForPayment(sessionId, counter));
            } else {
                resolve(status);
            }
        }, constants.payments.retryInterval());
    });
};

const getPaymentSettings = () => request({
    url: `${urlSettings}/getpaymentsettings`,
    method: 'GET'
});

const updatePaymentSettings = (data) => request({
    url: `${urlSettings}/updatepaymentsettings`,
    method: 'PUT',
    data: data
});

export const PaymentsService = {
    hasOrderBeenPaidByIdOrder,
    hasOrderBeenPaidByIdPayment,
    nexiCheckPaymentResult,
    nexiGetPaymentForm,
    pagoPaStartPayment,
    pagoPaCheckResult,
    getPaymentSettings,
    updatePaymentSettings,
    pagoPaWaitForPayment,
    ORDER_PAID_STATUS
};
