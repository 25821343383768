import i18n from 'i18next';
import moment from 'moment';
import axios from 'axios';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { constants } from './config';

const pendingKeys = new Set();
const alreadySentKeys = new Set();

let loaded = false;

function getApiClient() {
  // const tokenCookie = cookie.load('Token');
  let authHeader = localStorage.getItem('Token');
  // console.log('TOKEN COOKIE', tokenCookie);
  // if (tokenCookie) {
  //   authHeader = [
  //     tokenCookie.token_type,
  //     tokenCookie.access_token,
  //   ].join(' ');
  // }

  if (!authHeader) {
    return;
  }

  const apiClient = axios.create({
    baseURL: constants.api.url,
    responseType: 'json',
    headers: {
      'Content-type': 'application/json',
      'Authorization': authHeader,
    },
  });

  return apiClient;
}

// const ld = new LanguageDetector(i18n.services, { order: ['querystring', 'path', 'cookie', 'sessionStorage', 'localStorage', 'navigator', 'htmlTag'] })
// const lng = ld.detect()

// const options = {
//   order: ['querystring', 'navigator'],
//   lookupQuerystring: 'lng'
// }


i18n.on('loaded', () => {
  loaded = true;
});

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // languages, namespaces, resoucres
    // lng: 'it', // <--- turn off for detection to work
    // detection: options,
    fallbackLng: false, // key as a fallback value for missing keys
    whitelist: ['it', 'en'],


    ns: ['common'],
    defaultNS: 'common',
    supportedLngs: ['it', 'en'],


    // logging
    debug: false,

    // plugin options
    backend: {
      loadPath: `${constants.api.url}api/translations/{{lng}}`,
    },
    detection: {
      order: ['localStorage', 'navigator', 'fallbackLanguage'],
      lookupLocalStorage: 'i18nextLng',
      fallbackLng: 'en', // fallback for language detector
      caches: ['localStorage'],
      excludeCacheFor: ['cimode']
    },

    react: {
      wait: true,
      useSuspense: true,
    },

    // misc
    keySeparator: false,
    nsSeparator: ':',
    pluralSeparator: '_',
    contextSeparator: '_',

    // translation defaults
    returnNull: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      formatSeparator: ',',
      format: (value, format) => {
        if (format === 'uppercase') {
          return value.toUpperCase();
        }

        if (value instanceof Date) {
          return moment(value).format(format);
        }

        return value;
      },
    },

    // missing keys
    saveMissing: true,
    saveMissingPlurals: true,
    missingKeyHandler: (lng, ns, key) => {
      if (loaded && !alreadySentKeys.has(key)) {
        const apiClient = getApiClient();

        if (apiClient) {
          apiClient.post(`api/translations/${lng}`, { key });
          alreadySentKeys.add(key);

          pendingKeys.forEach((el) => {
            apiClient.post(`api/translations/${lng}`, { key: el });
            alreadySentKeys.add(el);
          });
          pendingKeys.clear();
        } else {
          pendingKeys.add(key);
        }
      }
    },
  });

export default i18n;