import React from 'react';
import compose from 'recompose/compose';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
// import CloseIcon from '@material-ui/icons/Close';
//import Grow from '@material-ui/core/Grow';
import { Grow } from '@mui/material';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import isArray from 'lodash/isArray';
import { Checkbox } from '@mui/material';
import Button from '@restart/ui/esm/Button';
import PropTypes from 'prop-types';
import { AiOutlineClose } from 'react-icons/ai';
/* eslint eqeqeq: "off"*/



const NUMBER_OF_VISIBLE_FILTERS = 4;

const setStyles = () => ({

  list: {
    width: '100%'
  },
  listItem: {
    cursor: 'pointer',
    padding: '4px 0',
  },
  listItemIcon: {
    minWidth: '35px !important',
  },
  listItemCheckbox: {
    color: '#d6d5d5 !important',
    padding: '0px !important'
  },
  listItemText: {
    marginTop: '0px !important',
    marginBottom: '0px !important',
  },
  listItemTextPrimary: {
    fontSize: '13px !important',
    fontWeight: '500 !important',
    lineHeight: '1.3 !important',
    wordBreak: 'break-word !important',
    hyphens: 'auto !important',
  },
  listRightText: {
    flex: '1 0 auto !important',
    textAlign: 'right !important',
    margin: '0px 0px 0px 8px !important',
  },
  listRightTextPrimary: {
    fontSize: '13px !important',
    fontWeight: '500 !important',
  },
  button: {
    height: '35px !important',
    width: '100% !important',
    maxWidth: '100% !important',
    borderRadius: '0 !important',
    '&:hover': {
      backgroundColor: '#616161!important'
    },
    '&:active': {
      boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%) !important',
      textDecoration: 'none'
    }
  },
  divider: {
    margin: '0 14px !important',
  },
  filterBoxAll: {
    zIndex: '9999',
    position: 'fixed !important',
    padding: '0 14px !important',
    top: '70px !important',
    left: '45px !important',
    width: 'calc(100vw - 400px) !important',
    marginLeft: '297px !important',
    background: 'none!important !important',
  },
  filterBoxAllInner: {
    background: '#f5f5f5!important',
    boxShadow: '4px 2px 10px -2px rgba(0,0,0,0.75) !important',
  },
  filterBoxAllTitle: {
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'space-between !important',
    padding: '4px 14px !important',
  },
  filterBoxAllTitleText: {
    color: '#de003d !important',
    fontWeight: '500 !important',
    fontSize: '16px !important',
    letterSpacing: '0.3px !important',
    margin: 0
  },
  closeIconButton: {
    color: 'black !important',
    padding: '4px !important',
    marginRight: '-4px !important',
  },
  filterBoxAllWrapper: {
    padding: '16px 12px !important',
    maxHeight: 'calc(100vh - 184px) !important',
    overflow: 'auto !important',
  },
  filterBoxAllList: {
    display: 'block !important',
    flexWrap: 'wrap !important',
    justifyContent: 'space-between !important',
    columnCount: '3 !important',
    columnGap: '28px !important',
    padding: '0px !important',
    '-webkit-column-rule': '1px outset lightgrey !important',
    '-moz-column-rule': '1px outset lightgrey !important',
    'column-rule': '1px outset lightgrey !important',
    '@media (max-width: 1200px)': {
      columnCount: '2 !important',
    },
    '& $listItem': {
      display: 'inline-flex !important',
      paddingRight: '6px !important',
    },
  }
});

let sortByAZ = function (filters) {
  if (filters && filters.values && isArray(filters.values)) {
    return filters.values.slice().sort((a, b) => {
      if (a.label.toUpperCase() < b.label.toUpperCase()) return -1;
      if (a.label.toUpperCase() > b.label.toUpperCase()) return 1;
      return 0;
    });
  } else {
    return 0;
  }
};


class MultiValueFilter extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      showAll: false,
      filtersAZ: sortByAZ(this.props.filterData),
      values: this.props.options
    };
  }


  static getDerivedStateFromProps(nextProps, state) {
    const result = { ...state };
    if (nextProps.filterData != state.filtersAZ) {
      result.filtersAZ = sortByAZ(nextProps.filterData);
    }

    if (nextProps.options && nextProps.options != state.values) {
      result.values = nextProps.options;
    } else {
      result.values = nextProps.filterData.values;
    }

    if (nextProps.emptyFilter) {
      result.checked = nextProps.emptyFilter;
    }

    return result;
  }

  toggleShowAll = () => {
    this.setState((currentState) => ({
      showAll: !currentState.showAll,
    }));
  };

  handleToggle = (event, value) => {
    const { checked } = this.state;
    const currentIndex = checked && checked.length > 0 ? checked.indexOf(value) : -1;
    const newChecked = [...checked];

    if (currentIndex === -1 || checked.length === 0) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });

    this.props.onSelectOptions(newChecked);
  };



  render() {
    const {
      // t, 
      filterData, classes } = this.props;
    const { filtersAZ, values } = this.state;

    let filteredSortedData = null;
    if (filterData && values && isArray(values)) {
      filteredSortedData = values.slice().sort((el1, el2) => {
        if (el1.count < el2.count) {
          return 1;
        } else if (el1.count === el2.count) {
          return 0;
        } else {
          return -1;
        }
      });
    }

    return (
      <div
        className={classes.list}
      >
        <List>
          {filteredSortedData && isArray(filteredSortedData) && filteredSortedData.filter((item, index) => index < NUMBER_OF_VISIBLE_FILTERS).map((value, index) => (
            <ListItem
              className={classes.listItem}
              key={index}
              dense
              onClick={event => this.handleToggle(event, value.value)}
            >
              <ListItemIcon
                className={classes.listItemIcon}
              >
                <Checkbox
                  className={classes.listItemCheckbox}
                  checked={this.state.checked && this.state.checked.length > 0 && this.state.checked.indexOf(value.value.toString()) !== -1}
                  tabIndex="-1"
                  sx={{
                    '&.Mui-checked': {
                      color: '#e7004e !important',
                    },
                  }}
                />
              </ListItemIcon>
              <ListItemText
                classes={{
                  root: classes.listItemText,
                  primary: classes.listItemTextPrimary,
                }}
                primary={value.label}
                // primaryTypographyProps={{
                //   color: '#4a4a4a !important',
                // }}
                sx={{
                  color: '#4a4a4a !important'
                }}
              />
              <ListItemText
                classes={{
                  root: classes.listRightText,
                  primary: classes.listRightTextPrimary,
                }}
                primary={value.count}
                // primaryTypographyProps={{
                //   color: '#4a4a4a !important',
                // }}
                sx={{
                  color: '#4a4a4a !important'
                }}
              />
            </ListItem>
          ))}
        </List>
        {filterData && values && values.length > NUMBER_OF_VISIBLE_FILTERS && (
          <Button
            className={'rl-pinkred-button ' + classes.button}
            onClick={this.toggleShowAll}
          >
            <span>{!this.state.showAll ? `${this.props.t('sidebarFilters.multivalueFilter.showAll')} (` + values.length + ')' : this.props.t('sidebarFilters.multivalueFilter.hideAll')}</span>
          </Button>
        )}
        <Grow
          in={this.state.showAll}
          style={{ transformOrigin: 'top left' }}
          mountOnEnter
          unmountOnExit
        >
          <div
            className={classes.filterBoxAll}
          >
            <div
              className={classes.filterBoxAllInner}
            >
              <div
                className={classes.filterBoxAllTitle}
              >
                <h3 className={classes.filterBoxAllTitleText}>{filterData.title}</h3>
                <IconButton
                  className={classes.closeIconButton}
                  onClick={this.toggleShowAll}
                >
                  <AiOutlineClose />
                </IconButton>
              </div>
              <Divider
                className={classes.divider}
              />
              <div
                className={classes.filterBoxAllWrapper}
              >
                <List
                  className={classes.filterBoxAllList}
                >
                  {filtersAZ && isArray(filtersAZ) && filtersAZ.map((value, index) =>
                    <ListItem
                      className={classes.listItem}
                      key={index}
                      dense
                      onClick={event => this.handleToggle(event, value.value)}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <Checkbox
                          className={classes.listItemCheckbox}
                          checked={this.state.checked && this.state.checked.length > 0 && this.state.checked.indexOf(value.value) !== -1}
                          tabIndex="-1"
                          sx={{
                            '&.Mui-checked': {
                              color: '#e7004e !important',
                            },
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{
                          root: classes.listItemText,
                          primary: classes.listItemTextPrimary,
                        }}
                        primary={value.label}
                        // primaryTypographyProps={{
                        //   color: '#4a4a4a !important'
                        // }}
                        sx={{ color: '#4a4a4a !important' }}
                      />
                      <ListItemText
                        classes={{
                          root: classes.listRightText,
                          primary: classes.listRightTextPrimary,
                        }}
                        primary={value.count}
                        // primaryTypographyProps={{
                        //   color: '#4a4a4a !important',
                        // }}
                        sx={{ color: '#4a4a4a !important' }}
                      />
                    </ListItem>
                  )}
                </List>
              </div>
            </div>
          </div>
        </Grow>
      </div>
    );
  }
}

MultiValueFilter.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(setStyles, { name: 'MultiValueFilter' }),
  withTranslation(),
)(MultiValueFilter);
