export const EMPTY_LICENSE = {
    idLicense: null,
    idTenant: null,
    licenseTitle: '',
    price: null,
    isFree: false,
    version: null,
    idCreatorUser: null,
    userNameAndSurname: '',
    userMail: '',
    creationDate: null,
    idLastModifiedUser: 0,
    userLastModifiedNameAndSurname: '',
    lastModifiedDate: null,
    idUsage: null,
    idMedium: null,
    idTypology: null,
    idDistributionFormat: null,
    idPlacement: null,
    idSize: null,
    idQuantity: null,
    idDuration: null,
    idRegion: null,
    idLanguage: null,
}

export const DEFAULTS_VALUES = {
    usage: null,
    medium: null,
    typology: null,
    distributionFormat: null,
    placement: null,
    size: null,
    quantity: null,
    duration: null,
    region: null,
    language: null,
}


export const LICENSE_FIELD = {
    USAGE: 'usage',
    MEDIUM: 'medium',
    TYPOLOGY: 'typology',
    DISTRIBUTION_FORMAT: 'distributionFormat',
    PLACEMENT: 'placement',
    SIZE: 'size',
    QUANTITY: 'quantity',
    DURATION: 'duration',
    REGION: 'region',
    LANGUAGE: 'language',
}


export const CONCESSION_OPTIONAL_FIELDS = {
    //dipendenti
    PLACEMENT: 'placement',
    SIZE: 'size',
    QUANTITY: 'quantity',
    //indipendenti
    DURATION: 'duration',
    REGION: 'region',
    LANGUAGE: 'language',
}

