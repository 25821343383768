import React from 'react'
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

const setStyles = makeStyles(() => ({
    root: {
        width: '100%',
        height: '70px',
        display: 'flex',
        alignItems: 'center'
    },
    titleContainer: {
        paddingRight: 0
    },
    iconButton: {
        height: '1.5em',
        width: '1.5em'
    },
    actionsContainer: {
        display: 'flex',
        marginRight: '16px'
    }
}))


function SectionHeader({
    title,
    renderActions
}) {

    const classes = setStyles();


    return (
        <>
            <div id='sectionHeader' className={classes.root}>
                <div className={classNames('admin-heade-titleContainer', classes.titleContainer)} style={{ width: '100%' }}>
                    <h1 className="admin-heade-title"> {title} </h1>
                </div>
                <div className={classes.actionsContainer}>
                    {renderActions && renderActions()}

                </div>

            </div>
        </>
    )
}

export default SectionHeader;
