import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { useTheme } from "@mui/material";
import { constants } from '../../../config/config';
import GlobalDataStore from '../../../GlobalDataStore';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const setStyles = (theme) => {
    return makeStyles({
        flexCenter: {
            display: "flex",
            justifyContent: 'center'
            // alignContent: 'center',
        },
        logo: {
            position: 'fixed',
            alignItems: 'center',
            // padding: '23px 0',
            height: '110px',
            zIndex: '100',
            top: '45px',
            cursor: 'pointer',
            width: ' 100%',
            background: theme.background ? theme.background : '#FFFFFF',
            '& img': {
                height: '80px'
            }
        },
    })()
};

function LogoContainer() {

    const theme = useTheme();
    const classes = setStyles(theme);
    const history = useHistory();
    const [t] = useTranslation();

    // const [headingText, setHeadingText] = useState();
    const [logo, setLogo] = useState({});

    useEffect(() => {
        setLogo(constants.paths.logo(GlobalDataStore.getTenantId()))
    }, [])

    const goToHome = () => {
        history.replace('/App')
    }


    return (
        <>
            <div id='header-logo' className={classNames(classes.flexCenter, classes.logo)}>
                <img alt={t('logoContainer.logo')} src={logo} onClick={() => goToHome()}></img>
            </div>
        </>
    )
}

export default LogoContainer;

