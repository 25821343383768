import { combineReducers } from 'redux';

import userReducer from './slices/userSlice';
import loaderReducer from './slices/loaderSlice';
import visualizationConfReducer from './slices/visualizationConfSlice';
import licensesReducer from './slices/licensesSlice';
import licensesVocabularyReducer from './slices/vocabularyLicensesSlice';
import ordersReducer from './slices/ordersSlice';
import translationsReducer from './slices/translationSlice'
import customRequestReducer from './slices/customRequestSlice';
import orderFilterSlice from './slices/orderFilterSlice';
import linkToBack from './slices/linkToBack';

const rootReducer = combineReducers({
    user: userReducer,
    loader: loaderReducer,
    visualizationConf: visualizationConfReducer,
    licenses: licensesReducer,
    licensesVocabulary: licensesVocabularyReducer,
    orders: ordersReducer,
    translations: translationsReducer,
    customRequests: customRequestReducer,
    orderFilterSlice: orderFilterSlice,
    linkToBack: linkToBack,
});

export default rootReducer;
