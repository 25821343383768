import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import ClientMessage from './Messages/ClientMessage';
import Button from '@mui/material/Button';
import AdminMessageContainer from './Messages/AdminMessage/AdminMessageContainer';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import ShowHideLoader from '../../../../hooks/showHideLoader';
import CustomRequestService from '../../../../service/adminServices/customRequestService';
import config from '../../../../config/config';
import { useTranslation } from 'react-i18next';


const setStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 173px)',
    overflowY: 'auto',
  },
  newReplyButton: {
    width: '15em',
    alignSelf: 'end',
    marginRight: '40px!important',
    marginBottom: '36px!important',
  }
}));

const ChatSectionBody = ({ chatInfo }) => {
  const classes = setStyles();
  const history = useHistory();
  const [isNewReply, setIsNewReply] = useState(false);
  const [showLoader, hideLoader] = ShowHideLoader();
  const [t] = useTranslation();


  const goBack = () => history.push(config.routes.customRequestsAdmin);

  // Id state 3 & 4 => Waiting for client payment or museum publication
  const isClosed = () => ((chatInfo.state.stateLabel.toUpperCase().startsWith('CLOSED_') || chatInfo.state.idState === 3 || chatInfo.state.idState === 4) && chatInfo.state.idState !== 7);
  const isUser = msg => msg.idUserFrom === chatInfo.messages[0].idUserFrom;
  const sendMessage = async messageData => {
    setIsNewReply(false);
    try {
      showLoader();
      const state = chatInfo.allStates.find(x =>
        x.stateLabel.toUpperCase() === messageData.stateLabel);
      if (!state) {
        throw new Error(`cannot find state "${messageData.stateLabel}"`);
      }
      await CustomRequestService.sendNewMessageUser(chatInfo.idCustomRequest, {
        idDigitalResource: chatInfo.idDigitalResource || null,
        messageText: messageData.messageText,
        imageUrl: messageData.imageUrl,
        idCustomRequestState: state.idState,
        toBePaidBefore: messageData.toBePaidBefore,
        productionCost: messageData.productionCost,
        idMuseumDefinedLicense: messageData.idMuseumDefinedLicense,
        digitalResourceTitle: messageData.digitalResourceTitle,
        digitalResourceDescr: messageData.digitalResourceDescr,
        digitalResourceInventoryNumber: messageData.digitalResourceInventoryNumber
      });
      goBack();
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (isNewReply) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [isNewReply]);

  return (
    <div id='chatSectionBody' className={classes.root}>
      {chatInfo.messages.map((msg, idx) => (
        <React.Fragment key={idx}>
          {isUser(msg) && (
            <ClientMessage
              organization={chatInfo.organization || null}
              message={msg}>
            </ClientMessage>
          )}
          {!isUser(msg) && (
            <AdminMessageContainer
              isNewReply={false}
              onSendMessage={sendMessage}
              message={msg}
              idDigitalResource={chatInfo.idDigitalResource}
              toBePaidBefore={chatInfo.toBePaidBefore}
              renewConcession={chatInfo.renewConcession}>
            </AdminMessageContainer>
          )}
        </React.Fragment>
      ))}
      {!isClosed() && (
        <>
          <AdminMessageContainer
            isNewReply={isNewReply}
            onSendMessage={sendMessage}
            message={null}
            idDigitalResource={chatInfo.idDigitalResource}
            toBePaidBefore={chatInfo.toBePaidBefore}
            organization={chatInfo.organization || null}
            renewConcession={chatInfo.renewConcession}>
          </AdminMessageContainer>
          {!isNewReply && (
            <Button
              className={classNames('message-button', 'message-full-color-button', classes.newReplyButton)}
              onClick={() => setIsNewReply(true)}>
              {t('customRequest.newMsg')}
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default ChatSectionBody;
