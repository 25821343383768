import React from 'react';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { formatDate } from '../../../../../commons/formatData';
import { useTranslation } from 'react-i18next';

const setStyles = makeStyles(() => ({
  root: {
    justifyContent: 'start'
  },
  header: {
    justifyContent: 'space-between',
    backgroundColor: '#ececec'
  },
  fontWeightNormal: {
    fontWeight: '400'
  }
}));

const ClientMessage = ({ message, organization }) => {

  const classes = setStyles();
  const [t] = useTranslation();

  return (
    <div className={classNames('message-container', classes.root)}>
      <div className={classNames('message-header', classes.header)}>
        <p >
          <span >{message.nameUserFrom} </span>
          <span className={classes.fontWeightNormal}>{organization ? ' | ' + organization : null}</span>
        </p>
        <p>{t('clientMessage.messageDate')}: {formatDate(message.createdAt)}</p>
      </div>
      <div className='message-body'>
        <p>{message.messageText}</p>
      </div>
    </div>
  );
};

export default ClientMessage;
