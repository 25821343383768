import { createSlice } from '@reduxjs/toolkit';
import { DEFAULTS_VALUES, EMPTY_LICENSE } from '../../enums/licenses';

const emptyLicense = EMPTY_LICENSE;
const defaultsValues = DEFAULTS_VALUES;

export const licensesSlice = createSlice({
    name: 'licenses', // Reducer Name
    initialState: {
        list: [],
        itemDetails: emptyLicense,
        defaultsValues: defaultsValues,
    },

    reducers: {

        //save list of licenses
        saveDataList: (state, action) => {
            if (action.payload && action.payload.data) {
                let details = action.payload.data;
                let tempArray = []
                details.forEach(item => {

                    let tempObj = {
                        idMuseumDefinedLicense: item.idMuseumDefinedLicense,
                        idTenant: item.idTenant,
                        licenseTitle: item.licenseTitle,
                        price: item.price,
                        isFree: item.isFree,
                        version: item.version,
                        idCreatorUser: item.idCreatorUser,
                        userNameAndSurname: item.userNameAndSurname,
                        userMail: item.userMail,
                        creationDate: item.creationDate,
                        idLastModifiedUser: item.idLastModifiedUser,
                        userLastModifiedNameAndSurname: item.userLastModifiedNameAndSurname,
                        lastModifiedDate: item.lastModifiedDate,
                        usage: item.usage,
                        medium: item.medium,
                        typology: item.typology,
                        distributionFormat: item.distributionFormat,
                        placement: item.placement,
                        size: item.size,
                        quantity: item.quantity,
                        duration: item.duration,
                        region: item.region,
                        language: item.language,
                    }
                    tempArray.push(tempObj)
                });
                state.list = tempArray

            }
        },

        //Save single license;
        saveDataDetails: (state, action) => {
            if (action.payload && action.payload.data) {
                let details = action.payload.data;
                let tempObj = {
                    idMuseumDefinedLicense: details.idMuseumDefinedLicense,
                    idLicense: details.idLicense,
                    idTenant: details.idTenant,
                    licenseTitle: details.licenseTitle,
                    price: details.price,
                    isFree: details.isFree,
                    version: details.version,
                    idCreatorUser: details.idCreatorUser,
                    userNameAndSurname: details.userNameAndSurname,
                    userMail: details.userMail,
                    creationDate: details.creationDate,
                    idLastModifiedUser: details.idLastModifiedUser,
                    userLastModifiedNameAndSurname: details.userLastModifiedNameAndSurname,
                    lastModifiedDate: details.lastModifiedDate,
                    idUsage: details.usage && details.usage.idTerm ? details.usage.idTerm : details.idUsage,
                    idMedium: details.medium && details.medium.idTerm ? details.medium.idTerm : details.idMedium,
                    idTypology: details.typology && details.typology.idTerm ? details.typology.idTerm : details.idTypology,
                    idDistributionFormat: details.distributionFormat && details.distributionFormat.idTerm ? details.distributionFormat.idTerm : details.idDistributionFormat,
                    idPlacement: details.placement && details.placement.idTerm ? details.placement.idTerm : details.idPlacement,
                    idSize: details.size && details.size.idTerm ? details.size.idTerm : details.idSize,
                    idQuantity: details.quantity && details.quantity.idTerm ? details.quantity.idTerm : details.idQuantity,
                    idDuration: details.duration && details.duration.idTerm ? details.duration.idTerm : details.idDuration,
                    idRegion: details.region && details.region.idTerm ? details.region.idTerm : details.idRegion,
                    idLanguage: details.language && details.language.idTerm ? details.language.idTerm : details.idLanguage,
                }
                state.itemDetails = tempObj
            }
        },

        //save single default value
        saveDefaultsValues: (state, action) => {
            if (action.payload && action.payload.data) {
                let internalObj = action.payload.object;
                let details = action.payload.data;
                state.defaultsValues[internalObj] = details;
            }

        },

        //Sets value to optionals elements with default value
        setValueToDefault: (state) => {
            state.itemDetails.idQuantity = state.defaultsValues.quantity
            state.itemDetails.idPlacement = state.defaultsValues.placement
            state.itemDetails.idSize = state.defaultsValues.size
            state.itemDetails.idDuration = state.defaultsValues.duration
            state.itemDetails.idRegion = state.defaultsValues.region
            state.itemDetails.idLanguage = state.defaultsValues.language
        },

        // set value to idUsage and sets others id values to null
        updateIdUsage: (state, action) => {
            //console.log(action.payload);
            if (action.payload || action.payload === 0) {
                state.itemDetails.idUsage = action.payload;
                state.itemDetails.idMedium = null
                state.itemDetails.idTypology = null
                state.itemDetails.idDistributionFormat = null
                state.itemDetails.idPlacement = null
                state.itemDetails.idSize = null
                state.itemDetails.idDuration = null
                state.itemDetails.idRegion = null
                state.itemDetails.idLanguage = null
                state.itemDetails.idQuantity = null
            }
        },

        // set value to idMedium and sets others id  values with default value (no idUsage)
        updateIdMedium: (state, action) => {
            if (action.payload || action.payload === 0) {
                state.itemDetails.idMedium = action.payload;
                state.itemDetails.idTypology = state.defaultsValues.typology
                state.itemDetails.idDistributionFormat = state.defaultsValues.distributionFormat
                licensesSlice.caseReducers.setValueToDefault(state)
            }
        },

        // set value to idTypology - idDistribuitionFormat and sets others id  values with default value (no idUsage - inMedium)

        updateIdTypologyDistribution: (state, action) => {
            if (action.payload && (action.payload.data || action.payload.data === 0)) {

                let internalObj = action.payload.object;
                let details = action.payload.data;
                state.itemDetails[internalObj] = details;
                licensesSlice.caseReducers.setValueToDefault(state)
            }
        },

        updateIdOptionaField: (state, action) => {
            if (action.payload) {
                let internalObj = action.payload.object;
                let defaultVal = action.payload.defaultVal;
                let details = (action.payload.data || action.payload.data === 0) ? action.payload.data : state.defaultsValues[defaultVal];
                state.itemDetails[internalObj] = details;
            }
        }

    },
})

export const {
    saveDataList,
    saveDataDetails,
    saveDefaultsValues,
    updateIdUsage,
    updateIdMedium,
    updateIdTypologyDistribution,
    updateIdOptionaField
} = licensesSlice.actions

export default licensesSlice.reducer;