import request from "../base/baseService";
import { constants } from "../../config/config"
import GlobalDataStore from "../../GlobalDataStore";

let url = `${constants.api.url}api/authentication`;

const login = (username, password) => {

    const authenticateRequest = {
        username: username,
        password: password,
        idTenant: GlobalDataStore.getTenantId()
    };
    return request({
        url: `${url}/authenticate`,
        method: 'POST',
        data: authenticateRequest
    })
}

const logout = () => {
    GlobalDataStore.setIsLogin(false);
    return (
        // WTC
        // eslint-disable-next-line no-sequences
        localStorage.removeItem('Token'),
        localStorage.removeItem("RefreshToken"),
        localStorage.removeItem("userFullName")
    );
}

export const AuthenticationService = {
    login,
    logout
}

export default AuthenticationService;