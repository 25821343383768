import request from "../base/baseService";
import { constants } from "../../config/config";

const url = `${constants.api.url}api/digitalresources`;

const getRighlyId = (idDR, idTenant, title, inventoryNumber) => {
    title = encodeURIComponent(title || '');
    inventoryNumber = encodeURIComponent(inventoryNumber || '');
    return request({
        url: `${url}/getRighlyId/${idDR}/${idTenant}/?title=${title}&inventoryNumber=${inventoryNumber}`,
        method: 'GET'
    });
};

export const DigitalResourceService = {
    getRighlyId
};

export default DigitalResourceService;