import React, { Suspense, Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Route } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import MainHeader from '../MainHeader';
import UsersService from '../../service/commons/userService';
import Loader from '../../commons/Loader';

import usersRoutes from '../../config/routes/usersRoutes';

import './index.scss';

const setStyles = (theme) => {

    return makeStyles(() => ({
        mainContainer: {
            background: theme.background,
            height: '100%',
            width: '100vw',
            minHeight: 'calc(100vh - 155px)'
        },
        body: {
            display: 'block',
        }
    }))()
}

const MainContainer = () => {

    const theme = useTheme();
    const classes = setStyles(theme);
    // WTC
    // eslint-disable-next-line no-unused-vars
    const [user, setUsers] = useState();

    const renderRoutes = () => {
        return usersRoutes.map((route, index) => {
            return <Route
                key={index}
                path={route.path}
                component={route.component}
                exact={route.exact}
            ></Route>

        });
    }

    useEffect(() => {
        if (localStorage.getItem('isLogin') === 'true') {
            let x = (UsersService.getUser())
            x.then((resp) => {
                setUsers(resp);
            })
                .catch((err) => {
                    console.error('Errore!', { err })
                })
        }
    }, [])


    // const dispatch = useDispatch()

    return (
        <Fragment>
            <div className={classes.mainContainer} >
                <MainHeader />

                <div className={classes.body} >
                    <Suspense fallback={(<Loader class="loader" />)}>
                        {renderRoutes()}
                    </Suspense>
                </div>
            </div>
        </Fragment>
    )
}

export default MainContainer;
