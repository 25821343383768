import { createSlice } from '@reduxjs/toolkit';
import { DEFAULTS_VALUES, EMPTY_ORDER } from '../../enums/orders';

const emptyOrder = EMPTY_ORDER;
const defaultsValues = DEFAULTS_VALUES;

export const OrdersSlice = createSlice({
    name: 'orders', // Reducer Name
    initialState: {
        list: [],
        itemDetails: emptyOrder,
        defaultsValues: defaultsValues,
    },

    reducers: {

        //save list of Orders
        saveDataList: (state, action) => {
            if (action.payload && action.payload.data) {
                let details = action.payload.data;
                let tempArray = [];
                details.forEach(item => {

                    let tempObj = {
                        idOrder: item.idOrder,
                        idUser: item.idUser,
                        idBasket: item.idBasket,
                        isCustomRequest: item.isCustomRequest,
                        orderDate: item.orderDate,
                        totalPrice: item.totalPrice,
                        orderState: item.orderState,
                        drNumber: item.drNumber,
                        userName: item.userName ? item.userName : null,
                        userMail: item.userMail ? item.userMail : null,
                        organization: item.organization ? item.organization : null,
                        pdfDownloadable: item.pdfDownloadable,
                    }
                    tempArray.push(tempObj)
                });
                state.list = tempArray

            }
        },

        //Save single Order;
        saveDataDetails: (state, action) => {
            if (action.payload && action.payload.data) {
                let details = action.payload.data;
                let tempArray = [];
                details.forEach((detail) => {
                    let tempObj = {
                        idOrderDetail: detail.idOrderDetail,
                        idOrder: detail.idOrder,
                        idDigitalResource: detail.idDigitalResource,
                        idLicense: detail.idLicense,
                        digitalResourceJson: detail.digitalResourceJson ? {
                            idDigitalResource: detail.digitalResourceJson.idDigitalResource,
                            drTitle: detail.digitalResourceJson.drTitle,
                            drAuthor: detail.digitalResourceJson.drAuthor,
                            digitalResourcePrice: detail.digitalResourceJson.digitalResourcePrice,
                            isFree: detail.digitalResourceJson.isFree,
                            downloadedDr: detail.downloadedDr,
                            idLicense: detail.digitalResourceJson.idLicense,
                            licenseVersion: detail.digitalResourceJson.licenseVersion,
                            price: detail.digitalResourceJson.price,
                            extension: detail.digitalResourceJson.extension,
                            inventoryNumber: detail.digitalResourceJson.inventoryNumber,
                            objectTypeAndClassification: detail.digitalResourceJson.objectTypeAndClassification

                        } : null,
                        licenseTitle: detail.licenseTitle,
                        licenseJson: detail.licenseJson,
                        price: detail.price,
                        isFree: detail.isFree,
                        imageDownloadable: detail.imageDownloadable
                    }
                    tempArray.push(tempObj)
                })

                state.itemDetails = tempArray
            }
        },

        //save single default value
        saveDefaultsValues: (state, action) => {
            if (action.payload && action.payload.data) {
                let internalObj = action.payload.object;
                let details = action.payload.data;
                state.defaultsValues[internalObj] = details;
            }

        },




    },
})

export const {
    saveDataList,
    saveDataDetails,
    saveDefaultsValues,
} = OrdersSlice.actions

export default OrdersSlice.reducer;