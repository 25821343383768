import { useState } from 'react';

const persistentState = {};
const temporaryState = {};

export const usePersistentState = (keyPrefix, keySuffix, initialState) => {
    checkKeyValidity(keyPrefix, 'prefix');
    checkKeyValidity(keySuffix, 'suffix');

    const persist = () => keyPrefix && keySuffix;
    const getKey = () => `${keyPrefix}:${keySuffix}`;

    const [state, setState] = useState(() =>
        persist() && persistentState.hasOwnProperty(getKey())
            ? persistentState[getKey()]
            : (
                typeof initialState === 'function'
                    ? initialState()
                    : initialState
            )
    );

    const onChange = nextState => {
        if (persist()) {
            temporaryState[getKey()] = nextState;
        }
        setState(nextState);
    };

    return [state, onChange];
};

export const storePersistentState = (keyPrefix, keySuffix = null) => {
    checkKeyValidity(keyPrefix, 'prefix');
    checkKeyValidity(keySuffix, 'suffix');

    if (keySuffix) {
        const keyName = `${keyPrefix}:${keySuffix}`;
        if (temporaryState.hasOwnProperty(keyName)) {
            persistentState[keyName] = temporaryState[keyName];
        } else {
            delete persistentState[keyName];
        }
    } else {
        for (const keyName in persistentState) {
            if (keyName.startsWith(keyPrefix + ':')) {
                delete persistentState[keyName];
            }
        }
        for (const keyName in temporaryState) {
            if (keyName.startsWith(keyPrefix + ':')) {
                persistentState[keyName] = temporaryState[keyName];
            }
        }
    }
};

const checkKeyValidity = (keyPart, keyType) => {
    if (keyPart && keyPart.indexOf(':') !== -1) {
        throw new Error(`the key ${keyType} cannot contain ":"`);
    }
};
