import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import OutsideClickHandler from 'react-outside-click-handler';
import { useTheme } from '@mui/material';
import { alpha } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const setStyles = makeStyles((theme) => {
    const themeTitle = theme && theme.firstTitle && theme.firstTitle.color ? theme.firstTitle.color : null;
    const themeTitleFont = theme && theme.firstTitle && theme.firstTitle.fontFamily ? theme.firstTitle.fontFamily : null;
    return {
        root: {
            display: 'block',
            maxHeight: '63px !important',
            margin: '10px 0',
            width: '100%',
            '&:first-child': {
                marginTop: '0'
            }
        },
        title: {
            color: '#707070',
            marginBottom: '6px'
        },
        inputName: {
            color: themeTitle,
            marginRight: '18px',
            marginTop: 'auto',
            marginBottom: '0',
            width: '150px',
            fontFamily: themeTitleFont,
            '&:first-letter': {
                textTransform: 'uppercase',
            }
        },
        selectContainer: {
            width: '100%',
        },
        select: {
            width: '100%',
            '& .MuiSelect-select': {
                padding: '10px!important'
            },
            '&.Mui-focused': {
                // boxShadow: `${alpha('#494949', 0.25)} 0 0 0 0.2rem`,
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: `${alpha('#494949', 0.5)} !important`
                }
            },
        },

    }
})


export const TermSelect = ({
    termData,
    termChanged
}) => {

    const theme = useTheme();
    const classes = setStyles(theme);
    const [t] = useTranslation();
    const [openSelect, setOpenSelect] = useState(false);

    const [allTerms, setAllTerms] = useState({});
    const [idTerm, setIdTerm] = useState('');
    const [labelSelected, setLabelSelected] = useState('')

    useEffect(() => {
        setAllTerms(termData.terms || {});
        const id = termData.idSelected;
        if (id !== null && id !== undefined && id !== 0) {
            setIdTerm(id.toString());
            setLabelSelected((termData.terms[id]))

        } else if (id === 0 && Object.keys(termData.terms).length === 1) {
            setIdTerm(id.toString());
            setLabelSelected(termData.terms[Object.keys(termData.terms)[0]])
        } else {
            setIdTerm('');
        }


    }, [termData]);



    const setTerm = id => {
        setIdTerm(id);
        if (typeof termChanged === 'function') {
            termChanged(termData, !id ? null : +id);
        }
    };

    return (
        <div className={classes.root}>

            <div className={classes.title} >{termData.vocabularyTitle}:</div>

            <div className={classes.selectContainer}>
                <OutsideClickHandler onOutsideClick={() => { setOpenSelect(false) }}>

                    <div className='users-selectContainer'>
                        <div className='users-fake-select' onClick={() => setOpenSelect(!openSelect)}>
                            <div className='users-fake-placeholder'> {idTerm && idTerm !== null && idTerm !== undefined && idTerm !== '' ? labelSelected : t('select.selected')}</div>

                            <div className='users-select-imgContainer' >

                                <img alt="" onClick={() => setOpenSelect(!openSelect)} className={openSelect ? 'users-selectArrow users-selectArrow-open' : 'users-selectArrow'} src="/images/arrow-down-filled-triangle.png" />
                            </div>

                        </div>

                        {openSelect && (
                            <>
                                <div className='users-fake-select-optionContainer' >
                                    {allTerms && Object.keys(allTerms).map((term, index) =>
                                        <div
                                            value={term}
                                            key={index}
                                            onClick={() => {
                                                setTerm(term);
                                                setOpenSelect(false)
                                            }}
                                        >
                                            <div>{allTerms[term] || term}</div>
                                        </div>
                                    )}
                                </div>
                            </>

                        )}
                    </div>
                </OutsideClickHandler>
            </div>



        </div>
    );
};
