import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import OutsideClickHandler from 'react-outside-click-handler';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';


const setStyles = makeStyles((theme) => {
    const themeBackground = theme && theme.background ? theme.background : null;
    const themeTitle = theme && theme.firstTitle && theme.firstTitle.color ? theme.firstTitle.color : 'black';
    const themeTitleFont = theme && theme.firstTitle && theme.firstTitle.font ? theme.firstTitle.font : null;
    return {
        modalContainer: {
            width: 500,
        },
        //header
        header: {
            color: themeTitle,
            fontFamily: themeTitleFont,
            background: themeBackground,
        },
        //body
        bodyTitle: {
            marginBottom: '12px',
        },
        listItem: {
            margin: '4px 0',
        },
        //footer:
        btnCancel: {
            cursor: 'pointer',
            fontFamily: themeTitleFont,
            marginLeft: '16px',
        },
        btnClose: {
            cursor: 'pointer',
            color: themeTitle,
            fontFamily: themeTitleFont,
            marginLeft: '16px',
        }
    }
});

export default function YesNoModal({
    showModal,
    title,
    modalText,
    yesPressed,
    noPressed
}) {
    const classes = setStyles();
    const [t] = useTranslation();

    return (
        <>
            <Modal
                open={showModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                centered="true">
                <Box className={`rl-modal-container ${classes.modalContainer} `}  >
                    <OutsideClickHandler onOutsideClick={() => noPressed()}>
                        <div className={`rl-modal-header ${classes.header}`} >
                            <span>{title || ''}</span>
                        </div>
                        <div className='rl-modal-body'>
                            <span>{modalText || ''}</span>
                        </div>
                        <div className={`rl-modal-footer ${classes.footer} `} >
                            <div className={classes.btnClose} onClick={yesPressed}>{t('yesNoModal.yes')}</div>
                            <div className={classes.btnCancel} onClick={noPressed}>{t('yesNoModal.no')}</div>
                        </div>
                    </OutsideClickHandler>
                </Box>
            </Modal>
        </>
    );
};
