import React from 'react';
import { makeStyles } from '@mui/styles';
/* eslint eqeqeq: "off"*/
import { useTranslation } from 'react-i18next';


const setStyles = makeStyles(() => ({
  root: {
    textAlign: "center",
    width: '90%',
    margin: 'auto',
    maxHeight: '100%',
    '& hr': {
      marginTop: '1.5em',
      height: '2px',
      width: '75%',
      backgroundColor: '#fff',
      border: 'none'
    }
  },
  titleSection: {
    marginBottom: '0',
    marginTop: '1.5em',
    fontWeight: '700',
    fontSize: '95%'
  },
  text: {
    margin: '.3em',
    fontSize: '90%'
  }
}))


const SidebarInfoContainer = ({
  data
}) => {
  const classes = setStyles();
  const [t] = useTranslation();
  
  function translationCheck(string){
    if(typeof string == 'string'){
      return t(string);
    }
    return string;
  }

  return <div className={classes.root}>
    {!!data?.length && data.map((info, outerIndex) => (
      <div key={info.titleSection}>
        <div>
          <div className={classes.titleSection}>{ translationCheck(info.titleSection) }</div>
          {info.texts && info.texts.map((text, innerIndex) => (
            <p className={classes.text} key={`${outerIndex}-${innerIndex}`}>
              {text}
            </p>
          ))}
        </div>
        {outerIndex != data.length - 1 && <hr />}
      </div>
    ))}
  </div>
}

export default SidebarInfoContainer;
