import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import OutsideClickHandler from 'react-outside-click-handler';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';


const setStyles = makeStyles(() => ({
    modalContainer: {
        width: '500px'
    },
}));

function DeleteModal({
    showModal,
    title,
    modalText,
    btnConfirm,
    //Output
    closeModal,
    confirmDeletion,
}) {

    const classes = setStyles();
    const [t] = useTranslation();


    return (
        <>
            <Modal
                open={showModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                centered="true">
                <Box className={`admin-modal-container ${classes.modalContainer}`} >
                    <OutsideClickHandler onOutsideClick={() => closeModal()}>
                        <div className='admin-modal-header' >
                            <span>{title ? title : t('modals.deleteModal.title')}</span>
                        </div>

                        <div className='admin-modal-body'>
                            <span>{modalText ? modalText : t('modals.deleteModal.modalText')}</span>
                        </div>

                        <div className='admin-modal-footer'>
                            <div className='admin-modal-btnCancel' onClick={() => closeModal()}>{t('modals.deleteModal.btnCancel')}</div>
                            <div className='admin-modal-btnApply' onClick={() => confirmDeletion()}>{btnConfirm ? btnConfirm : t('modals.deleteModal.btnApply')}</div>
                        </div>
                    </OutsideClickHandler>
                </Box>
            </Modal>
        </>
    )
}

DeleteModal.propTypes = {
    showModal: PropTypes.bool,
    title: PropTypes.string,
    modalText: PropTypes.string,
    btnConfirm: PropTypes.string,
    //Output
    closeModal: PropTypes.func,
    confirmDeletion: PropTypes.func,
}

export default DeleteModal;

