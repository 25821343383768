import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import store from './redux/store';

import './index.css';
import './config/i18n';

import GlobalDataStore from './GlobalDataStore';

//versione dell'applicativo
console.log("AppVersion: 1.0.2.5");

GlobalDataStore.initTenantId().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Suspense fallback={<></>}>
          <App />
        </Suspense>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
});


