import React from "react";
import classNames from 'classnames';
import { Fragment } from "react";

function LicenseDetail({
    classes,
    item,
}) {


    return (
        <>
            <div className={classNames(classes.greyContainer, classes.textBox)}>
                < div className={classes.wrap}>
                    {item && Object.keys(item).map((term, index) =>
                        <Fragment key={index}>
                            <p><b>{term}:</b>&nbsp;{item[term] || term}&nbsp;&nbsp;</p>

                        </Fragment>
                    )}
                </div>
            </div>
        </>
    )
}

export default LicenseDetail