/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import GlobalDataStore from '../../../GlobalDataStore';
import { constants } from '../../../config/config';

const setStyles = makeStyles(() => ({
    root: {
        width: '100%',
        display: 'flex',
        height: '70px',
        marginTop: '6px',
        background: '#E5E5E5',
        fontSize: '14px',
        fontWeight: '500'
    },
    cell: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            margin: '0',
            // padding: '0px 20px',
        }
    },
    cardCell: {
        display: 'flex',
        alignSelf: 'center',
        fontSize: '14px',
        '& p': {
            margin: '0',
            padding: '0px 20px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            '-webkit-line-clamp': '2',
            '-webkit-box-orient': 'vertical',
        }
    },
    imgContainer: {
        alignSelf: 'stretch',
        overflow: 'hidden',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    capitalize: {
        textTransform: 'capitalize'
    },
    capitalizeFirstLetter: {
        '&::first-letter': {
            textTransform: 'capitalize'
        }
    }
}))

function ListCardAdmin({
    classes,
    item,
    showColumn,
    visibleColumns,
}) {
    const [thumbnail, setThumbnail] = useState('')

    const internalClasses = setStyles();

    //calcola dimensione delle colonne: per quelle più larghe number arriva = 200, per quelle più strette number = 100;
    const calculateSize = (number) => {
        let index = 0;
        visibleColumns && visibleColumns.forEach(colmumn => {
            if (colmumn === 'width' || colmumn === 'height' || colmumn === 'resolution') {
                index++
            } else {
                index = index + 2;
            }
        })
        return `calc(${number}% / ${visibleColumns ? (index) : 7} - 1px )`
    }

    useEffect(() => {
        setThumbnail(constants.paths.thumbnail(GlobalDataStore.getTenantId(), item.id))
    }, [])

    return (
        <div className={internalClasses.root}>
            <div className={classNames(internalClasses.cardCell, classes.header_img, internalClasses.imgContainer)}>
                {/*item.img && */(
                    <img alt='' src={thumbnail} className={internalClasses.image} />
                )}
            </div>
            <div className={classes.displayFlex}>
                {showColumn('name') &&
                    <div style={{ width: calculateSize(200) }} className={classNames(internalClasses.cardCell, internalClasses.capitalizeFirstLetter)}>
                        <p>{item.title}</p>
                    </div>
                }
                {showColumn('author') &&
                    <div style={{ width: calculateSize(200) }} className={classNames(internalClasses.cardCell, internalClasses.capitalizeFirstLetter)}>
                        <p>{item.author}</p>
                    </div>
                }
                {showColumn('drInventory') &&
                    <div style={{ width: calculateSize(200) }} className={classNames(internalClasses.cardCell, internalClasses.capitalize)}>
                        <p>{item.inventoryNumber}</p>
                    </div>
                }
                {showColumn('width') &&
                    <div style={{ width: calculateSize(100) }} className={internalClasses.cardCell}>
                        <p>{item.pixelWidht}</p>
                    </div>
                }
                {showColumn('height') &&
                    <div style={{ width: calculateSize(100) }} className={internalClasses.cardCell}>
                        <p>{item.pixelHeight}</p>
                    </div>
                }
                {showColumn('resolution') &&
                    <div style={{ width: calculateSize(100) }} className={internalClasses.cardCell}>
                        <p>{item.resolutionX} {item.resolutionY}</p>
                    </div>
                }
                {showColumn('classification') &&
                    <div style={{ width: calculateSize(200) }} className={classNames(internalClasses.cardCell, internalClasses.capitalizeFirstLetter)}>
                        <p>{item.objectTypeAndClassification}</p>
                    </div>
                }
            </div>
        </div>
    )
}

ListCardAdmin.propTypes = {
    classes: PropTypes.object.isRequired,
    item: PropTypes.object,
    showColumn: PropTypes.func
}

export default ListCardAdmin

