import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import OutsideClickHandler from 'react-outside-click-handler';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';


const setStyles = makeStyles((theme) => {
    const themeBackground = theme && theme.background ? theme.background : null;
    const themeTitle = theme && theme.firstTitle && theme.firstTitle.color ? theme.firstTitle.color : 'black';
    const themeTitleFont = theme && theme.firstTitle && theme.firstTitle.font ? theme.firstTitle.font : null;
    return {
        modalContainer: {
            width: 500,
        },
        //header
        header: {
            color: themeTitle,
            fontFamily: themeTitleFont,
            background: themeBackground,
        },
        //body
        bodyTitle: {
            marginBottom: '12px',
        },
        listItem: {
            margin: '4px 0',
        },
        //footer:
        btnCancel: {
            fontFamily: themeTitleFont,
        },
        btnClose: {
            cursor: 'pointer',
            color: themeTitle,
            fontFamily: themeTitleFont,
            marginLeft: '16px',
        }
    }
})

function MessageModal({
    showModal,
    title,
    modalText,
    btnConfirm,
    //Output
    closeModal,
}) {

    const classes = setStyles();
    const [t] = useTranslation();


    return (
        <>
            <Modal
                open={showModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                centered="true">
                <Box className={`rl-modal-container ${classes.modalContainer} `}  >
                    <OutsideClickHandler onOutsideClick={() => closeModal()}>
                        <div className={`rl-modal-header ${classes.header}`} >
                            <span>{title ? title : ''}</span>
                        </div>

                        <div className='rl-modal-body'>
                            <span>{modalText ? modalText : t('messageModal.confirm')}</span>
                        </div>

                        <div className={`rl-modal-footer ${classes.footer} `} >
                            <div className={classes.btnClose} onClick={() => closeModal()}>{btnConfirm ? btnConfirm : t('modals.errorModal.btnClose')}</div>
                        </div>
                    </OutsideClickHandler>
                </Box>
            </Modal>
        </>
    )
}

MessageModal.propTypes = {
    showModal: PropTypes.bool,
    title: PropTypes.string,
    modalText: PropTypes.string,
    btnConfirm: PropTypes.string,
    //Output
    closeModal: PropTypes.func,
}

export default MessageModal;

