import { createSlice } from '@reduxjs/toolkit';

export const orderFilterSlice = createSlice({
    name: 'orderfilter',
    initialState: {
        states: {
            loadedFor: null,
            allStates: [],
            groupedStates: []
        }
    },
    reducers: {
        saveStates: (state, action) => {
            if (action.payload) {
                state.states.loadedFor = action.payload.loadedFor;
                state.states.allStates = action.payload.states;
                state.states.groupedStates = state.states.allStates.reduce((result, item) => {
                    (result[item.stateName] = result[item.stateName] || []).push(item.idState);
                    return result;
                }, {});
            }
        }
    }
});

export const {
    saveStates,
    saveParameters
} = orderFilterSlice.actions;

export default orderFilterSlice.reducer;
