/**
 * Quando nell'URL non c'è un sottodominio al sistema possono accedere solo i SysAdmin.
 * Quando invece c'è il sottodominio del museo, possono accedere tutti gli utenti tranne i SysAdmin.
 * Esempio:
 *   http://museo-1.rigthly.eu/ -> tutti tranne i SysAdmin
 *   http://rigthly.eu/ -> solo i SysAdmin
 */

import { differenceInSeconds } from "date-fns";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { constants } from "../../config/config";
import GlobalDataStore from "../../GlobalDataStore";
import UsersService from "../../service/commons/userService";
import { isAdminArea, isSubdomain, isUrlRooted } from "../utils";

const RELOAD_EVERY_SECONDS = 5 * 60;

let lastCheckRole = null;
let lastCheckDate = null;

const allowEveryone = () => {
    const path = window.location.pathname.toLowerCase();
    return path.startsWith('/systemadmin/cmwplugin');
};

const allowSysAdmin = () => {
    if (!window?.location?.pathname) {
        return false;
    }

    if (isSubdomain()) {
        return false;
    }

    const path = window.location.pathname.toLowerCase();
    return path.startsWith('/systemadmin')
        || path.startsWith('/adminarea/tenantsmanagement');
};

const checkDomain = async () => {
    // // RESUME AFTER DEVELOP
    // // Use for test Users area
    // return '/App';
    if (allowEveryone()) {
        return null;
    }

    const subDomain = isSubdomain();
    const userArea = !isAdminArea();

    if (!GlobalDataStore.isLoggedIn()) {
        lastCheckRole = null;
        lastCheckDate = null;
        return !subDomain && userArea ? '/SystemAdmin/Login' : null;
    }

    if (!lastCheckDate || differenceInSeconds(new Date(), lastCheckDate) > RELOAD_EVERY_SECONDS) {
        // console.log('refreshing user role...');
        lastCheckRole = (await UsersService.getRole()).roleLabel.toLowerCase();
        lastCheckDate = new Date();
    }

    const sysAdmin = lastCheckRole === 'sysadmin';
    const tenantAdmin = lastCheckRole === 'tenantadmin';
    const allowSys = allowSysAdmin();

    if (sysAdmin && !allowSys) {
        GlobalDataStore.clearAuthentication();
        if (userArea) {
            return '/App/Login';
        } else if (subDomain)
            return constants.paths.comworkLogin();
        else {
            return '/SystemAdmin/Login';
        }
    } else if (!subDomain && userArea) {
        GlobalDataStore.clearAuthentication();
        return '/SystemAdmin/Login';
    } else if (subDomain && tenantAdmin && userArea) {
        GlobalDataStore.clearAuthentication();
        return '/App/Login';
    } else if (!subDomain && !sysAdmin) {
        GlobalDataStore.clearAuthentication();
        return '/SystemAdmin/Login';
    } else if (subDomain && !tenantAdmin && !userArea) {
        GlobalDataStore.clearAuthentication();
        return constants.paths.comworkLogin();
    }

    return null;
};

export const DomainCheck = () => {
    const history = useHistory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        const redirect = await checkDomain();
        if (redirect) {
            if (isUrlRooted(redirect)) {
                window.location.href = redirect;
            } else {
                history.push(redirect);
            }
        }
    });

    return (<></>);
};