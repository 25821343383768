import { createSlice } from '@reduxjs/toolkit'

import { transformUser } from './../../transformations/userTransformation';


export const userSlice = createSlice({
    name: 'user', // Reducer Name
    initialState: {
        idUser: null,
        idRole: null,
        idTenant: null,
        name: "",
        surName: "",
        idCulture: null,
        phoneNumber: "",
        email: "",
        isEnabled: true,
        vatid: "",
        organization: "",
        isCorporate: true,
        country: "",
        state: "",
        physicalAddress: "",
        idApplication: null
    },
    reducers: {
        saveUser: (state, action) => {
            let details = action.payload;
            if (details) {
                details = transformUser(details);
            }
            state.idUser = details.id;
            state.name = details.firstName;
            state.email = details.email;
        },
    },
})

export const { saveUser } = userSlice.actions

export default userSlice.reducer;