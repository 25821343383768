import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { formatDate } from '../../../commons/formatData';
import { getStateColor } from '../../../service/commons/customRequestCommon';

const setStyles = makeStyles(() => ({
    row: {
        width: '100%',
        display: 'flex',
        height: '70px',
        marginTop: '6px',
        background: '#E5E5E5',
        fontSize: '14px',
        fontWeight: '500',
        cursor: 'pointer',
        fontFamily: "Roboto",
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.08)',
        },
        '&:first-child': {
            marginTop: '0px',
        },
    },
    statusCell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cell: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            margin: '0',
            padding: '0px 20px',
        }
    },
    cellPrice: {
        justifyContent: 'center',
    },
    ellipsis: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    ellipsisTwoLines: {
        maxWidth: '200px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        ' -webkit-box-orient': 'vertical',
    },

    statusText: {
        textAlign: 'center',
        textTransform: 'lowercase',
    },

}));

function CustomRequestItemAdmin({
    classes,
    item,
    visibleColumns,
}) {
    const internalClasses = setStyles();
    const [cr, setCR] = useState(null);
    const history = useHistory();

    useEffect(() => setCR(item), [item]);

    const goToDetail = idCR => {
        history.push('/AdminArea/customRequests/Details/' + idCR);
    };


    const calculateSize = (number) => {
        let index = 0;
        visibleColumns && visibleColumns.forEach(colmumn => {
            if (colmumn === 'customRequests.price') {
                index = index + 2
            } else {
                index = index + 3;
            }
        })
        return `calc(${number + 100}% / ${visibleColumns ? (index) : 23} )`
    }

    const showColumn = column => visibleColumns.indexOf(column) !== -1;

    return (
        <>
            {!!cr && (

                <div className={internalClasses.row} onClick={() => goToDetail(cr.idCustomRequest)}>
                    <div className={classNames(internalClasses.statusCell, classes.header_status)}>
                        <div className={'admin-status admin-status-' + getStateColor(cr.stateLabel)}>
                            <div className={'admin-status-dot admin-status-dot-' + getStateColor(cr.stateLabel)}></div>
                            <p className={internalClasses.statusText}>{cr.stateName}</p>
                        </div>
                    </div>

                    <div className={classes.flex}>
                        {showColumn('customRequests.date') && (
                            <div className={internalClasses.cell} style={{ width: calculateSize(200) }}>
                                <p className={internalClasses.ellipsisTwoLines}>{formatDate(cr.requestDate)}</p>
                            </div>
                        )}

                        {showColumn('customRequests.user') && (
                            <div className={internalClasses.cell} style={{ width: calculateSize(200) }}>
                                <p className={internalClasses.ellipsisTwoLines}>{cr.userFirstName} {cr.userLastName}</p>
                            </div>
                        )}

                        {showColumn('customRequests.mail') && (
                            <div className={internalClasses.cell} style={{ width: calculateSize(200) }}>
                                <p className={internalClasses.ellipsis}>{cr.userEmail}</p>
                            </div>
                        )}

                        {showColumn('customRequests.entity') && (
                            <div className={internalClasses.cell} style={{ width: calculateSize(200) }}>
                                <p className={internalClasses.ellipsis}>{cr.organization}</p>
                            </div>
                        )}

                        {showColumn('customRequests.inventory') && (
                            <div className={internalClasses.cell} style={{ width: calculateSize(200) }}>
                                <p className={internalClasses.ellipsis}>{cr.inventoryNumber}</p>
                            </div>
                        )}

                        {showColumn('customRequests.description') && (
                            <div className={internalClasses.cell} style={{ width: calculateSize(200) }}>
                                <p className={internalClasses.ellipsisTwoLines}>{cr.description}</p>
                            </div>
                        )}

                        {showColumn('customRequests.usage') && (
                            <div className={internalClasses.cell} style={{ width: calculateSize(200) }}>
                                <p className={internalClasses.ellipsis}>{cr.usage}</p>
                            </div>
                        )}

                        {showColumn('customRequests.price') && (
                            <div className={classNames(internalClasses.cell, internalClasses.cellPrice)} style={{ width: calculateSize(100) }}>
                                <p className={internalClasses.ellipsis}>{cr.price || '--'} €</p>
                            </div>
                        )}

                    </div>
                </div>
            )}
        </>
    )
};

CustomRequestItemAdmin.propTypes = {
    classes: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    visibleColumns: PropTypes.array,
};

export default CustomRequestItemAdmin;
