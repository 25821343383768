export const transformUser = ((user) => {
    return {
        id: user.id,
        name: user.name,
        email: user.email,

    };
});


export const transformToDetails = ((users) => {
    return users.map(item => {
        return {
            id: item.id,
            name: item.name,
            email: item.email,
        };
    });
});