/* eslint react-hooks/exhaustive-deps: "off" */
import React from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

const setStyles = makeStyles((theme) => {
    const themeTitleColor = theme && theme.secondTitle && theme.secondTitle.color ? theme.secondTitle.color : null;
    const themeTitleFont = theme && theme.secondTitle && theme.secondTitle.font ? theme.secondTitle.font : null;

    return {
        root: {
            wordBreak: 'break-word'
        },
        labelStyle: {
            fontFamily: themeTitleFont,
            fontSize: '16px',
            color: themeTitleColor,
            fontWeight: '600',
            margin: '1em 0'
        },
        text: {
            lineHeight: '1.75em',
            fontSize: '14px',
            color: 'rgba(0, 0, 0, 0.6)',
        }
    }
})

function DrDetailsCard({
    title,
    data,
}) {
    const classes = setStyles();
    const [t] = useTranslation();

    return (
        <div className={classes.root}>
            <div className={classes.labelStyle}>{title}</div>
            {!isEmpty(data)
                && data.map(item =>
                    <div key={item.idMetadata} className={classes.text}>
                        {t(`configuration.metadataSection.${item.metadata.toLowerCase()}`)}: {item.value}
                    </div>
                )}
        </div>
    )
}

DrDetailsCard.propTypes = {
    label: PropTypes.string

}


export default DrDetailsCard;