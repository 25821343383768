import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { AiOutlineClose } from 'react-icons/ai';
import LicensesService from '../../../../../../service/licensesService';
import { formatDate } from '../../../../../../commons/formatData';
import ShowHideLoader from '../../../../../../hooks/showHideLoader';
import { useTranslation } from 'react-i18next';


const setStyles = makeStyles(() => ({
  modalContainer: {
    width: '650px',
    maxHeight: '700px',
  },
  modalBody: {
    'padding-left': '1.5em !important',
    'padding-right': '1.5em !important'
  },
  modalFooter: {
    paddingBottom: '1.5em !important'
  },
  quotationContainer: {
    margin: '1.5em 0',
    '& p': {
      margin: 0,
      marginBottom: '.5em'
    }
  },
  inputName: {
    fontWeight: '500',
    minWidth: '160px',
    marginRight: '1em',
    marginBottom: '0',
  },
  row: {
    margin: '8px 0',
    display: 'flex'
  }
}));

const ConcessionPreviewModal = ({
  showModal,
  onCloseModal,
  idConcession
}) => {
  const classes = setStyles();
  const [concession, setConcession] = useState();
  const [showLoader, hideLoader] = ShowHideLoader();
  const [t] = useTranslation();


  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    try {
      showLoader();
      const concessions = await LicensesService.getLicensesList(
        undefined, undefined, { filters: { idLicenses: [idConcession] } }
      );
      setConcession(concessions.items[0]);
    } finally {
      hideLoader();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Modal
      open={showModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      id='concessionPreviewModalModal'
    >
      <Box className={`admin-modal-container ${classes.modalContainer}`}>
        <div className='admin-modal-header' >
          <span>{t('customRequest.concessionDetail')}</span>
          <AiOutlineClose className='admin-modal-headerClose' onClick={onCloseModal} />
        </div>
        <div className={classes.modalBody}>
          <div className={classes.quotationContainer}>
            {!concession && (
              <div>
                <span>{t('customRequest.loadingDetail')}...</span>
              </div>
            )}
            {concession && (
              <>
                <div className={classes.row}>
                  <span className={classes.inputName}>{t('customRequest.concessionId')}:</span>
                  <span>{concession.idMuseumDefinedLicense}</span>
                </div>
                <div className={classes.row}>
                  <span className={classes.inputName}>{t('customRequest.concessionTitle')}:</span>
                  <span>{concession.licenseTitle}</span>
                </div>
                <div className={classes.row}>
                  <span className={classes.inputName}>{t('customRequest.price')}:</span>
                  <span>
                    {concession.isFree ? 'Gratis' : parseFloat(concession.price).toFixed(2) + '€'}
                  </span>
                </div>
                <div className={classes.row}>
                  <span className={classes.inputName}>{t('customRequest.createdBy')}:</span>
                  <span>{concession.creationUserNameAndSurname}</span>
                </div>
                <div className={classes.row}>
                  <span className={classes.inputName}>{t('customRequest.createdOn')}:</span>
                  <span>{formatDate(concession.creationDate)}</span>
                </div>
                <div className={classes.row}>
                  <span className={classes.inputName}>{t('customRequest.editedBy')}:</span>
                  <span>{concession.userLastModifiedNameAndSurname || '--'}</span>
                </div>
                <div className={classes.row}>
                  <span className={classes.inputName}>{t('customRequest.editedOn')}:</span>
                  <span>{formatDate(concession.lastModifiedDate, '--')}</span>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={`admin-modal-footer ${classes.modalFooter}`}>
          <div className='admin-modal-btnCancel'
            onClick={onCloseModal}>
            {t('customRequest.close')}
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ConcessionPreviewModal;
