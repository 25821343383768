import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import ListCardAdmin from './ListCardAdmin';
import { useTranslation } from 'react-i18next';


const setStyles = makeStyles((theme) => ({
    root: {
        display: 'block',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        maxWidth: 'calc(100vw - 262px)',
        marginRight: '6px'
    },
    headerTable: {
        height: '40px',
        margin: '15px auto',
        marginTop: '0',
        boxShadow: '3px 3px 6px rgba(0 0 0 / 0.16)',
        position: 'absolute',
        zIndex: '100',
        background: theme.background ? theme.background : '#FFFFFF',
        color: theme.firstTitle && theme.firstTitle.color ? theme.firstTitle.color : 'black',
        display: 'flex'
    },
    tableHeader: {
        height: '24px',
        border: '1px solid',
        background: '#F5F5F5',
        display: 'flex',
        width: 'calc(100% - 6px)'
    },
    headerCell: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '10px',
        textTransform: 'uppercase',
        borderRight: '1px solid',
        '& p': {
            padding: '0px 20px',
            // margin: '0',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '&:last-child': {
            borderRight: 'none'
        }

    },
    displayFlex: {
        width: 'calc(100% - 70px)',
        display: 'flex',

    },
    header_img: {
        // flex: '0 0 70px',
        width: '70px',
        justifyContent: 'center',
    },
    cardContainer: {
        maxHeight: 'calc(100vh - 170px)',
        overflowY: 'scroll',
        // overflowX: 'scroll'
        // '&::-webkit-scrollbar': {
        //     display: 'none'
        // },
    },
}))


function ListViewAdmin({
    imagesList,
    //OUTPUT
    goToDetail,
    visibleColumns
}) {

    const classes = setStyles();
    const [t] = useTranslation();


    const showColumn = column => visibleColumns.indexOf(column) !== -1;


    //calcola dimensione delle colonne: per quelle più larghe number arriva = 200, per quelle più strette number = 100;
    const calculateSize = (number) => {
        let index = 0;
        visibleColumns && visibleColumns.forEach(colmumn => {
            if (colmumn === 'width' || colmumn === 'height' || colmumn === 'resolution') {
                index++
            } else {
                index = index + 2;
            }
        })
        return `calc(${number}% / ${visibleColumns ? (index) : 7} - 1px )`
    }

    return (
        <>
            <div id='ListView' className={classes.root}>
                <div className={classes.tableHeader}>
                    <div className={classNames(classes.headerCell, classes.header_img)}>
                        <p> </p>
                    </div>
                    <div className={classes.displayFlex}>
                        {showColumn('name') &&
                            <div style={{ width: calculateSize(200) }} className={classes.headerCell}>
                                <p>{t('drList.name')}</p>
                            </div>
                        }
                        {showColumn('author') &&
                            <div style={{ width: calculateSize(200) }} className={classes.headerCell}>
                                <p>{t('drList.author')}</p>
                            </div>
                        }
                        {showColumn('drInventory') &&
                            <div style={{ width: calculateSize(200) }} className={classes.headerCell}>
                                <p> {t('drList.drInventory')} </p>
                            </div>
                        }
                        {showColumn('width') &&
                            <div style={{ width: calculateSize(100) }} className={classes.headerCell}>
                                <p>{t('drList.width')}</p>
                            </div>
                        }
                        {showColumn('height') &&
                            <div style={{ width: calculateSize(100) }} className={classes.headerCell}>
                                <p>{t('drList.height')}</p>
                            </div>
                        }
                        {showColumn('resolution') &&
                            <div style={{ maxWidth: calculateSize(100), minWidth: calculateSize(100) }} className={classes.headerCell}>
                                <p>{t('drList.resolution')}</p>
                            </div>
                        }
                        {showColumn('classification') &&
                            <div style={{ width: calculateSize(200) }} className={classes.headerCell}>
                                <p>{t('drList.classification')}</p>
                            </div>
                        }
                    </div>
                </div>

                <div className={classes.cardContainer}>
                    {imagesList && imagesList.items && imagesList.items.map(item =>
                        <ListCardAdmin
                            key={item.id}
                            classes={classes}
                            item={item}
                            visibleColumns={visibleColumns}
                            onClickInside={(evt) => goToDetail(evt)}
                            showColumn={showColumn}
                        />
                    )}
                </div>
            </div>
        </>
    )
}

ListViewAdmin.propTypes = {
    // imagesList: PropTypes.array,
    imagesList: PropTypes.object,
    //OUTPUT
    goToDetail: PropTypes.func,
    visibleColumns: PropTypes.array
}

export default ListViewAdmin

