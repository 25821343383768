import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import OutsideClickHandler from 'react-outside-click-handler';
import { AiOutlineClose, AiOutlineMinusCircle } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

const setStyles = makeStyles(() => ({
    vocabularySelectContainer: {
        display: 'flex',
        alignItems: 'start',
        margin: '12px 0',
        height: '34px',
    },
    inputName: {
        fontWeight: ' 500',
        marginRight: '18px',
        marginTop: 'auto',
        marginBottom: '0',
        width: '160px',
        '&:first-letter': {
            textTransform: 'uppercase',
        }
    },
    selectContainer: {
        width: '510px',
    },
    deleteElement: {
        position: 'relative',
        top: '16px',
        right: '710px',
        color: '#E7004E',
        cursor: 'pointer'
    },
    optionContainer: {
        // position: 'absolute',
        width: '510px',
    },
}))


export const EditTerm = ({
    termData,
    isLastSelect,
    //output
    termChanged,
    deleteTerm,
}) => {

    const classes = setStyles();
    const [t] = useTranslation();

    const [openSelect, setOpenSelect] = useState(false);

    const [allTerms, setAllTerms] = useState({});
    const [idTerm, setIdTerm] = useState('');
    const [labelSelected, setLabelSelected] = useState('')

    useEffect(() => {
        setAllTerms(termData.terms || {});
        const id = termData.idSelected;
        if (id !== null && id !== undefined) {

            setIdTerm(id.toString());
            setLabelSelected((termData.terms[id]));

        } else {
            setIdTerm('');
            setLabelSelected('');
        }
    }, [termData]);




    const setTerm = (id) => {
        setIdTerm(id);
        if (typeof termChanged === 'function') {
            termChanged(termData, !id ? null : +id);
        }
    };


    const toEmptySelectValue = () => {
        setTerm(null);
    }


    const showDeleteElement = () => {
        if (termData && termData.itemOrder && termData.itemOrder > 4) {
            return true;
        }
    }


    const changeSelectedTerm = (term) => {
        setTerm(term);
        setOpenSelect(false);
        setLabelSelected(allTerms[term]);
    };


    const calcMarginBottom = () => {
        if (termData.itemOrder < 4 && openSelect && isLastSelect) {
            return '80px'
        } else {
            return '12px'
        }
    }

    return (
        <>
            <div className={classes.vocabularySelectContainer} style={{ marginBottom: calcMarginBottom() }}>


                <div className={classes.inputName} >{termData.vocabularyTitle}:</div>

                <div id={`selectContainer-${termData.itemOrder}`} className={classes.selectContainer} >
                    <OutsideClickHandler onOutsideClick={() => { setOpenSelect(false) }}>

                        <div className='admin-selectContainer'>
                            <div className='admin-fake-select' onClick={() => setOpenSelect(!openSelect)}>
                                {idTerm ? labelSelected : t('editTerm.select.placeholder')}
                                <div className='admin-select-imgContainer' >

                                    {termData && termData.itemOrder > 4 && (
                                        <AiOutlineClose className='admin-select-deleteSelection' onClick={() => toEmptySelectValue()} />
                                    )}

                                    <img alt="" onClick={() => setOpenSelect(!openSelect)} className={openSelect ? 'admin-selectArrow admin-selectArrow-open' : 'admin-selectArrow'} src="/images/arrow-down-filled-triangle.png" />
                                </div>

                            </div>

                            {openSelect && (
                                <>
                                    <div className={`admin-fake-select-optionContainer ${classes.optionContainer}`}  >
                                        {allTerms && Object.keys(allTerms).map((term, index) =>
                                            <div
                                                value={term}
                                                key={index}
                                                onClick={() => changeSelectedTerm(term)}
                                            >
                                                {allTerms[term] || term}
                                            </div>
                                        )}
                                    </div>
                                </>

                            )}
                        </div>
                    </OutsideClickHandler>
                </div>
                {showDeleteElement() && (
                    <AiOutlineMinusCircle
                        className={classes.deleteElement}
                        onClick={() => deleteTerm(termData.itemOrder)}
                    />
                )}
            </ div>

        </>
    );
};


EditTerm.propTypes = {
    termData: PropTypes.object,
    isLastSelect: PropTypes.bool,
    //output
    termChanged: PropTypes.func,
    deleteTerm: PropTypes.func,
}

export default EditTerm;