import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { Link } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import config from '../../config/config'
import { constants } from "../../config/config";
import { IoCloudDownloadOutline } from 'react-icons/io5';
import { BsGear, BsEnvelope, BsGrid} from 'react-icons/bs';
import { FaClipboardList, FaUserCircle} from 'react-icons/fa';
import { AiOutlineCopyright, AiOutlinePoweroff, AiOutlineDashboard} from 'react-icons/ai';
import { FiShoppingBag} from 'react-icons/fi';
import { RiBankLine} from 'react-icons/ri';
import UsersService from '../../service/commons/userService';
import OutsideClickHandler from 'react-outside-click-handler';
import AuthenticationService from '../../service/usersService/authenticationService';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

const setStyles = makeStyles(() => ({
    root: {
        width: '70px',
        height: '100vh',
        display: 'inline-block',
    },
    logo: {
        height: '39px',
        width: '39px',
        borderRadius: '50%',
        border: '4px solid #E7004E',
        margin: '10px auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '& p': {
            fontFamily: 'calibri',
            fontSize: '41px',
            color: '#E7004E',
            marginTop: '0',
            marginBottom: '6px',
            lineHeight: '1',
            fontWeight: '500',
            '-webkit-touch-callout': 'none', /* iOS Safari */
            '-webkit-user-select': ' none', /* Safari */
            '-khtml-user-select': 'none', /* Konqueror HTML */
            '-moz-user-select': 'none', /* Old versions of Firefox */
            '-ms-user-select': 'none', /* Internet Explorer/Edge */
            'user-select': 'none',
        }
    },
    btnLinkContainer: {
        margin: '20px 0',
        minHeight: 'calc(100vh - 107px)',
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
    },
    link: {
        width: '100%',
        cursor: 'pointer',
        display: 'block',
        padding: '10px 0',
        textAlign: 'center',
    },
    btnLink: {
        height: '25px',
        width: '25px',
        color: '#d6d5d5'
    },
    btnActive: {
        color: 'black'
    },
    logoutContainer: {
        width: '150px',
        height: '64px',
        background: 'white',
        position: 'absolute',
        bottom: '18px',
        left: '60px',
        zIndex: '100',
        display: 'flex',
        alignItems: 'center',
        padding: '0 30px',
        boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
        cursor: 'pointer',
        '& span': {
            textTransform: 'uppercase',
            fontWeight: '500',
            fontFamily: 'Roboto',
            fontSize: '12px'
        }
    },
    logoutIcon: {
        height: '15px',
        width: '15px',
        marginLeft: '5px'
    },
    tooltip: {
        width: '30px',
        margin: '0px 10px !important',
    },
    flexWrap: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    }
}))

const SideBar = () => {

    const [t] = useTranslation();
    const classes = setStyles();
    const history = useHistory();
    const location = useLocation();
    const [isSysAdmin, setIsSysAdmin] = useState(false);
    const [activeIcon, setActiveIcon] = useState();
    const [showLogoutMenu, setShowLogoutMenu] = useState(false)

    const goToSection = (link) => {
        if (location.pathname !== link) {
            history.push(link);
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        try {
            setIsSysAdmin((await UsersService.getRole())
                .roleLabel.toLowerCase() === 'sysadmin');
        } catch (ex) {
            console.error(ex);
            setIsSysAdmin(false);
        }
    }, []);

    useEffect(() => {
        setActiveIcon(location.pathname?.toLowerCase());
    }, [location.pathname])


    const toLogout = () => {
        setShowLogoutMenu(false);
        AuthenticationService.logout();
        if (isSysAdmin) {
            history.replace(config.routes.systemAdminLogin);
        } else {
            window.location.replace(constants.paths.comworkLogin());
        }
    }


    return (
        <Fragment>
            <div className={classes.root}>

                <div className={classes.logo} onClick={() => goToSection(config.routes.digitalResourcesListAdmin)}>
                    <p>r</p>
                </div>

                <div className={classes.btnLinkContainer}>
                    <div className={classes.flexWrap}>
                        {!isSysAdmin ? (
                            <>
                                <Tooltip className={classes.tooltip} title={t('sidebar.digitalResource')} arrow placement='right'>
                                    < Link className={classes.link} onClick={() => goToSection(config.routes.digitalResourcesListAdmin)} >
                                        <FaClipboardList className={
                                            activeIcon === config.routes.digitalResourcesListAdmin.toLowerCase()
                                                ? classNames(classes.btnLink, classes.btnActive) : classes.btnLink
                                        } />
                                    </Link>
                                </Tooltip>

                                <Tooltip className={classes.tooltip} title={t('sidebar.orders')} arrow placement='right'>
                                    <Link className={classes.link} onClick={() => goToSection(config.routes.ordersListAdmin)}>
                                        <FiShoppingBag className={
                                            activeIcon?.startsWith(config.routes.ordersListAdmin.toLowerCase())
                                                ? classNames(classes.btnLink, classes.btnActive) : classes.btnLink
                                        } />
                                    </Link>
                                </Tooltip>

                                <Tooltip className={classes.tooltip} title={t('sidebar.customRequest')} arrow placement='right'>
                                    <Link className={classes.link} onClick={() => goToSection(config.routes.customRequestsAdmin)}>
                                        <BsEnvelope className={
                                            (
                                                activeIcon?.startsWith(config.routes.customRequestsAdmin.toLowerCase()) ||
                                                activeIcon?.startsWith(config.routes.customRequestsDetailsAdmin.toLowerCase())
                                            )
                                                ? classNames(classes.btnLink, classes.btnActive) : classes.btnLink
                                        } />
                                    </Link>
                                </Tooltip>

                                <Tooltip className={classes.tooltip} title={t('sidebar.licenses')} arrow placement='right'>
                                    <Link className={classes.link} onClick={() => goToSection(config.routes.licenses)}>
                                        <AiOutlineCopyright className={
                                            activeIcon?.startsWith(config.routes.licenses.toLowerCase())
                                                ? classNames(classes.btnLink, classes.btnActive) : classes.btnLink
                                        } />
                                    </Link>
                                </Tooltip>

                                <Tooltip className={classes.tooltip} title={t('sidebar.export')} arrow placement='right'>
                                    <Link className={classes.link} onClick={() => goToSection(config.routes.exportCsv)}>
                                        <IoCloudDownloadOutline className={
                                            activeIcon?.startsWith(config.routes.exportCsv.toLowerCase())
                                                ? classNames(classes.btnLink, classes.btnActive) : classes.btnLink
                                        } />
                                    </Link>
                                </Tooltip>

                                <Tooltip className={classes.tooltip} title={t('sidebar.dashboard')} arrow placement='right'>
                                    <Link className={classes.link} onClick={() => goToSection(config.routes.dashboard)}>
                                        <AiOutlineDashboard className={
                                            activeIcon?.startsWith(config.routes.dashboard.toLowerCase())
                                                ? classNames(classes.btnLink, classes.btnActive) : classes.btnLink
                                        } />
                                    </Link>
                                </Tooltip>

                                <Tooltip className={classes.tooltip} title={t('sidebar.configuration')} arrow placement='right'>
                                    <Link className={classes.link} onClick={() => goToSection(config.routes.configuration)}>
                                        <BsGear className={
                                            activeIcon?.startsWith(config.routes.configuration.toLowerCase())
                                                ? classNames(classes.btnLink, classes.btnActive) : classes.btnLink
                                        } />
                                    </Link>
                                </Tooltip>
                            </>
                        ) : (

                            <Link className={classes.link} onClick={() => goToSection(config.routes.tenantsManagement)} >
                                <RiBankLine className={
                                    activeIcon?.startsWith(config.routes.tenantsManagement.toLowerCase())
                                        ? classNames(classes.btnLink, classes.btnActive) : classes.btnLink
                                } />
                            </Link>
                        )}
                    </div>
                    <div className={classes.flexWrap}>
                        {!isSysAdmin && (
                            < Tooltip className={classes.tooltip} title={t('sidebar.backToCmw')} arrow placement='right'>
                                <Link className={classes.link} onClick={() => window.location.replace(constants.paths.comworkLogin())}>
                                    <BsGrid className={classes.btnLink} />
                                </Link>
                            </ Tooltip>
                        )}
                        {/* <Link className={classes.link} onClick={() => goToSection(config.routes.adminLogin)}> */}
                        <Link className={classes.link} onClick={() => setShowLogoutMenu(true)}>
                            <FaUserCircle className={
                                showLogoutMenu
                                    ? classNames(classes.btnLink, classes.btnActive) : classes.btnLink
                            } />
                        </Link>
                    </div>
                </div>



                {showLogoutMenu && (
                    < OutsideClickHandler onOutsideClick={() => { setShowLogoutMenu(false) }} >
                        <div className={classes.logoutContainer} onClick={() => toLogout()}>
                            <span >{t('sidebar.logout')}</span>
                            <AiOutlinePoweroff className={classes.logoutIcon} />
                        </div>
                    </OutsideClickHandler>
                )}

            </div>
        </Fragment >

    )
}

export default SideBar;
