import { createSlice } from '@reduxjs/toolkit';

export const customRequestSlice = createSlice({
    name: 'customRequest',
    initialState: {
        states: {
            loadedFor: null,
            allStates: [],
            groupedStates: []
        },
        parameters: {
            loaded: false,
            usage: {},
            medium: {},
            typology: {},
            distributionFormat: {},
            placement: {},
            size: {},
            quantity: {},
            duration: {},
            region: {},
            language: {}
        },
        //all vocabularies with all terms for custom request
        licenseVocabularies: [],
    },
    reducers: {
        saveStates: (state, action) => {
            if (action.payload) {
                state.states.loadedFor = action.payload.loadedFor;
                state.states.allStates = action.payload.states;
                state.states.groupedStates = state.states.allStates.reduce((result, item) => {
                    (result[item.stateName] = result[item.stateName] || []).push(item.idState);
                    return result;
                }, {});
            }
        },
        saveParameters: (state, action) => {
            if (action.payload) {
                for (const key in state.parameters) {
                    state.parameters[key] = key === 'loaded' ? false : {};
                }
                for (const key in action.payload) {
                    if (state.parameters.hasOwnProperty(key)) {
                        state.parameters[key] = action.payload[key] || {};
                    }
                }
                state.parameters.loaded = true;
            }
        },
        saveLicenseVocabularies: (state, action) => {
            if (action.payload) {
                state.licenseVocabularies = action.payload;
                state.parameters.loaded = true
            }
        }
    }
});

export const {
    saveStates,
    saveParameters,
    saveLicenseVocabularies,
} = customRequestSlice.actions;

export default customRequestSlice.reducer;
