export const USE_DUMMY_DATA = false;


export const constants = {
    api: {
        url:
            //'http://torino.rightly.comwork.eu/',
            process.env.REACT_APP_BASE_PATH,
    },
    paths: {
        user: 'https://jsonplaceholder.typicode.com/users/1',
        logo: (idTenant) => `${process.env.REACT_APP_BASE_PATH}api/visualsettings/${idTenant}/logo`,
        homeImages: (idTenant, page, pageSize) => `${process.env.REACT_APP_BASE_PATH}api/digitalresources/getlistofdigitalresources/${idTenant}/?page=${page}&pagesize=${pageSize}`,
        thumbnail: (idTenant, idDigitalResource) => `${process.env.REACT_APP_BASE_PATH}api/digitalresources/${idTenant}/${idDigitalResource}/thumbnail`,
        image: (idTenant, idDigitalResource) => `${process.env.REACT_APP_BASE_PATH}api/digitalresources/${idTenant}/${idDigitalResource}/image`,


        //AdminArea
        licensesList: (page, pageSize) => {
            let licensesListUrl = `${constants.api.url}api/licenseconfiguration/getLiceses/?page=${page}&pagesize=${pageSize}`;
            return licensesListUrl
        },

        ordersListForTenant: (page, pageSize) => {
            return `${constants.api.url}api/orders/getfortenant/?page=${page}&pagesize=${pageSize}`;
        },
        licenseDetails: () => {
            return `${constants.api.url}api/licenseconfiguration`;
        },
        deleteLicense: (id) => {
            return `${constants.api.url}api/licenseconfiguration/deletelicense/${id}`;
        },
        licenselabel: (id) => {
            return `${constants.api.url}api/licenseconfiguration/getlicenselabel/${id}`;
        },
        getMedia: () => {
            return `${constants.api.url}api/licenseconfiguration/getmedia`;
        },
        getSubmenu: () => {
            return `${constants.api.url}api/licenseconfiguration/getsubmenu`;
        },
        getTerms: (id) => {
            return `${constants.api.url}api/licenseconfiguration/gettermbytenant/${id}`;
        },
        addOrUpdateTerm: () => {
            return `${constants.api.url}api/licenseconfiguration/addorupdateterm`;
        },
        ordersList: (page, pageSize) => {
            return `${constants.api.url}api/orders/?page=${page}&pagesize=${pageSize}`;
        },
        getCustomRequestOrder: (idOrder) => {
            return `${constants.api.url}api/orders/getcustomrequestid/${idOrder}`;
        },
        orderDetails: (id) => {
            return `${constants.api.url}api/orders/getdetails/${id}`;
        },
        orderStatesList: () => {
            return `${constants.api.url}api/orders/states`;
        },
        downloadDrFile: (idOrderDetail) => {
            return `${constants.api.url}api/download/getdrfile/${idOrderDetail}`;
        },

        downloadProtocolPdf: (idOrder) => {
            return `${constants.api.url}api/download/getpdfprotocol/${idOrder}`;
        },

        downloadExportRequest: (idRequest) => {
            return `${constants.api.url}api/download/getrequest/${idRequest}`;
        },

        deleteRequest: (idRequest) => {
            return `${constants.api.url}api/export/delete/${idRequest}`;
        },
        createRequest: (exportType) => {
            return `${constants.api.url}api/export/create/${exportType}`;
        },

        crsList: (page, pageSize) => {
            return `${constants.api.url}api/customrequest/search/?pageNumber=${page}&itemsPerPage=${pageSize}`;
        },
        crsUser: (page, pageSize) => {
            return `${constants.api.url}api/customrequest/list/?pageNumber=${page}&itemsPerPage=${pageSize}`;
        },
        crDetails: (idCustomRequest) => {
            return `${constants.api.url}api/customrequest/${idCustomRequest}`;
        },
        crConcessions: () => {
            return `${constants.api.url}api/customrequest/concessions`;
        },
        crStatesList: () => {
            return `${constants.api.url}api/customrequest/states`;
        },
        crSendUserMessage: (idCustomRequest) => {
            return `${constants.api.url}api/customrequest/${idCustomRequest}`;
        },
        //Protocol Settings
        getProtocolConfig: () => {
            return `${constants.api.url}api/protocolsettings/getprotocolsettings`;
        },
        updateProtocolSettings: () => {
            return `${constants.api.url}api/protocolsettings/updateProtocolSettings`;
        },
        acceptCustomRequest: (idCustomRequest) => {
            return `${constants.api.url}api/customrequest/confirmcustomrequest/${idCustomRequest}`;
        },
        getCustomRequestParameters: () => {
            return `${constants.api.url}api/customrequest/parameters`;
        },
        getCreateNewRequest: () => {
            return `${constants.api.url}api/customrequest`;
        },
        getTermsSelecedByUser: (idCustomRequest) => {
            return `${constants.api.url}api/customrequest/gettermsseleceduser/${idCustomRequest}`;
        },
        payCustomRequest: (idOrder) => {
            return `${constants.api.url}api/customrequest/paycustomrequest/${idOrder}`;
        },

        getExportCsv: (page, pageSize) => {
            return `${constants.api.url}api/export/list/?page=${page}&pagesize=${pageSize}`;
        },

        loadCultureSettings: () => {
            return `${constants.api.url}api/culturesettings/load`;
        },

        saveCultureSettings: () => {
            return `${constants.api.url}api/culturesettings/save`;
        },

        // user: '/posts/{id}/comments',
        // downloadsList: '/downloads',
        // downloadDetails: '/downloads/{id}',
        licenses: '/getLiceses/{idUser}/{idTenant}',

        tenantsList: (page, pageSize) => {
            let tenantsListUrl = `${constants.api.url}api/tenant?pageNumber=${page}&pageSize=${pageSize}`;
            return tenantsListUrl
        },
        tenantDetail: (idTenant) => {
            return `${constants.api.url}api/tenant/${idTenant}`
        },
        tenant: () => {
            return `${constants.api.url}api/tenant`
        },
        getPaymentMethod: () => {
            return `${constants.api.url}api/tenant/payment-method`
        },
        tenantSetStatus: (idTenant, isEnabled) => {
            return `${constants.api.url}api/tenant/status/${idTenant}/${isEnabled}`
        },

        comworkLogin: () => process.env.REACT_APP_COMWORK_URL
    },
    defaultLanguages: ['en', 'it'],

    // ad intervalli di 3 secondi e per 20 volte (quindi circa un minuto più i tempi di attesa)
    // verifica se il pagamento è andato a buon fine, usato per ora solo per PagoPA in quanto
    // NEXI invia una notifica immediata
    payments: {
        maxRetryCount: () => 20,
        retryInterval: () => 3 * 1000
    },

    // elementi per pagina nelle liste
    pageSize: () => 10
}


const config = {

    routes: {
        // adminArea
        adminLogin: '/AdminArea/Login',
        configuration: '/AdminArea/Configuration',
        licenses: '/AdminArea/Licenses',
        tenantsManagement: '/AdminArea/TenantsManagement',
        customRequestsAdmin: '/AdminArea/CustomRequests/List',
        customRequestsDetailsAdmin: '/AdminArea/CustomRequests/Details',
        ordersListAdmin: '/AdminArea/OrdersListAdmin',
        digitalResourcesListAdmin: '/AdminArea/DigitalResources',
        exportCsv: '/AdminArea/ExportCsv',
        dashboard: '/AdminArea/Dashboard',
        ordersAdmin: '/AdminArea/OrdersListAdmin/OrdersListAdmin',

        // systemAdmin
        systemAdminLogin: '/SystemAdmin/Login',

        // usersArea
        usersPage: '/App',
        digitalResourceDetails: '/App/DigitalResourceDetails/:id',
        login: '/App/Login',
        userCreation: '/App/UserRegistration',
        orders: '/App/Orders',
        ordersDetails: '/App/Orders/Details/:idOrder',
        basket: '/App/Basket',
        orderSummary: '/App/OrderSummary',
        customRequestDetails: '/App/CustomRequest/Details/:id',
        customRequestsUser: '/App/CRsList',
        support: '/App/Support',
    }

}

export default config;