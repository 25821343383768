import { getLanguage, isSubdomain } from "./commons/utils";
import CommonConfService from "./service/commons/commonConfService";
import jwtDecode from "jwt-decode"

//Gets tenant id from subdomain. In case of localhost it uses localhost
// const getTenantIdFromServer = async () => {
//     let burl = document.getElementsByTagName('base')[0].href;
//     let trimmed = burl.replace('http://', '').replace('https://', '');
//     let endIndex = trimmed.indexOf('.');
//     if (endIndex === -1) {
//         endIndex = trimmed.indexOf(':');
//     }
//     let subDomain = trimmed.substring(0, endIndex);
//     try {
//         let idTenant = await CommonConfService.getIdTenantFromSubdomain(subDomain);
//         console.log(idTenant);
//         return idTenant;
//     }
//     catch (err) {
//         console.error("error in getting data: ", { err });
//         return parseInt(process.env.REACT_APP_SYSADMIN_TENANT);
//     }
// };

//global variables
let privateTenantIdGlobal = parseInt(process.env.REACT_APP_SYSADMIN_TENANT);
let languageChangedCallback = null;

//init of tenant id from server
const initTenantId = async () => {
    // // RESUME AFTER DEVELOP
    // // use for develop in local, set the tenant that you want test
    // // in the BE sometimes it take the tenant from the user, so check also it
    // privateTenantIdGlobal = 4;
    // return;
    if (isSubdomain()) {
        const host = window.location.hostname;
        const domain = host.substring(0, host.indexOf('.'));
        privateTenantIdGlobal = await CommonConfService.getIdTenantFromSubdomain(domain);
    } else {
        privateTenantIdGlobal = parseInt(process.env.REACT_APP_SYSADMIN_TENANT);
    }
    //privateTenantIdGlobal = await getTenantIdFromServer();
};

const initIsLogin = () => {
    const isLogin = localStorage.getItem("isLogin");
    if (!isLogin) {
        localStorage.setItem("isLogin", false);
    }
}

const setIsLogin = (value) => {
    localStorage.setItem("isLogin", value);
}

const getIsLogin = () => {
    return localStorage.getItem("isLogin");
}

const clearAuthentication = () => {
    localStorage.setItem('isLogin', false);
    localStorage.removeItem('Token');
    localStorage.removeItem('RefreshToken');
    localStorage.removeItem('idUser');
    localStorage.removeItem('userFullName');
};

const extractInfoFromToken = () => {
    const extractInfo = decoded => {
        localStorage.setItem('idUser', decoded.idUser);
        localStorage.setItem(
            'userFullName',
            `${decoded.firstname} ${decoded.lastname}`.trim()
        );
    };
    const extractCulture = decoded => {
        const oldLang = localStorage.getItem('i18nextLng');
        localStorage.setItem(
            'i18nextLng',
            decoded?.culture || getLanguage()
        );
        const newLang = localStorage.getItem('i18nextLng');
        if (oldLang !== newLang && languageChangedCallback) {
            languageChangedCallback(newLang);
        }
    };
    const token = localStorage.getItem('Token');
    const decoded = token ? jwtDecode(token) : null;
    extractInfo(decoded);
    extractCulture(decoded);
};

const GlobalDataStore = {
    //init
    initTenantId: () => initTenantId(),
    //getter
    getTenantId: () => privateTenantIdGlobal,

    initIsLogin: () => initIsLogin(),
    setIsLogin: (value) => setIsLogin(value),
    getIsLogin: () => getIsLogin(),
    isLoggedIn: () => getIsLogin()?.toLowerCase() === 'true',
    clearAuthentication,
    extractInfoFromToken,

    registerLanguageChangedCallback: val => languageChangedCallback = val
};

// Object.freeze(GlobalDataStore);
export default GlobalDataStore;