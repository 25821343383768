/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { formatDate } from '../../../../../../commons/formatData';
import { FaEye } from 'react-icons/fa';
import LicenseDetail from './LicenseDetail/LicenseDetail';
import { HiOutlineLink } from 'react-icons/hi';
import { Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';

// import { slideInDown } from 'react-animations'
// import styled, { keyframes } from 'styled-components';

const setStyles = makeStyles(() => ({
  root: {
    alignSelf: 'end',
    marginRight: '5.5em',
    fontFamily: 'Roboto',
  },
  header: {
    justifyContent: 'space-between',
    backgroundColor: '#e5e5e5'
  },
  messageContainer: {
    width: 'calc(100% - 16px)',
    padding: '8px',
    border: '1px solid #707070',
    borderRadius: '5px',
  },
  greyContainer: {
    marginTop: '16px',
    boxSizing: 'border-box !important',
    width: '100%',
    padding: '24px 12px',
    backgroundColor: '#f5f5f5',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& p': {
      margin: 0,
      fontWeight: 600,
      fontSize: '12px'
    },
  },
  quotationName: {
    color: '#e7004e'
  },
  eyeIcon: {
    width: '24px',
    height: '24px',
    marginRight: '8px',
    cursor: 'pointer',
  },
  eyeIconBlue: {
    color: '#4A90E2',
  },
  textBox: {
    marginTop: '0!important',
    paddingTop: '0!important',
    wordBreak: 'break-word',
    '& p': {
      fontWeight: 400,
      margin: '6px 0',
      verticalAlign: 'justify',
      fontSize: '12px'
    },
  },
  footerInfo: {
    width: '100%',
    background: 'white',
    marginBottom: '4px',
    border: 'none',
    borderBottom: '1px solid grey',
    wordBreak: 'break-word',

    '& p': {
      marginLeft: '2%',
      marginTop: '2%',
      verticalAlign: 'justify'
    },

  },
  linkContainer: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    wordBreak: 'normal',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    '& a': {
      marginTop: '16px',
      // borderBottom: '1px solid grey',
      textDecoration: 'none',
    }
  },
  imgInfo: {
    fontSize: '0.7em',
    fontStyle: 'Roboto',
    color: '#707070',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  messageFooter: {
    fontSize: '14px',
    marginTop: '16px',
  },
  inputLabel: {
    marginBottom: '4px',
    display: 'inline-block'
  },
  wrap: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 11px',
    padding: '12px 0',
    borderRadius: '5px',
    background: '#e5e5e5',
    '& p': {
      width: 'calc(50% - 50px)',
      padding: '6px 25px',
      margin: '0'
    }
  }

}));

const AdminMessage = ({
  message,
  toBePaidBefore
}) => {

  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const classes = setStyles();
  const [t] = useTranslation();


  const onClickDetail = () => {
    setIsOpenDetail(!isOpenDetail);
  }

  return (
    <div className={classNames('message-container', classes.root)}>
      <div className={classNames('message-header', classes.header)}>
        <p>{t('adminMessage.responseDate')}: {formatDate(message.createdAt)}</p>
        <p>{message.nameUserFrom}</p>
      </div>
      <div className='message-body'>
        <div className={classes.messageContainer}>

          <p>{message.messageText}</p>
        </div>

        {message && message.imageUrl && (
          <div id='messageFooter' className={classes.messageFooter}>
            <div id='footerInfo-description' className={classes.footerInfo}>
              <div className={classes.linkContainer}>
                <a href={message.imageUrl} target="_blank">{message.imageUrl}</a>
                <HiOutlineLink />
              </div>
            </div>
          </div>
        )}


        {message && message.licensePrice >= 0 && message.productionCost !== null && (toBePaidBefore || message.proposedDeadline) ? (
          <div className={classes.greyContainer}>
            <p className={classes.quotationName}>{t('customRequest.quotationTitle')}</p>
            <p>{t('customRequest.toBePaidBefore')} {toBePaidBefore ? formatDate(toBePaidBefore, '', false) : formatDate(message.proposedDeadline, '', false)}</p>
            <p>{t('customRequest.acquisitionPrice')}: {message.productionCost || 0}€</p>
            {!isOpenDetail && (
              <p>{t('customRequest.licensePrice')}: {message.licensePrice || 0}€</p>
            )}
            <p>{t('customRequest.totalPrice')}: {parseFloat(message.licensePrice + message.productionCost) || 0}€</p>
            <div className={classes.iconsContainer} onClick={() => onClickDetail()}>
              <FaEye className={!isOpenDetail ? classes.eyeIcon : classNames(classes.eyeIcon, classes.eyeIconBlue)} />
            </div>
          </div>
        ) : null}

        <Collapse in={isOpenDetail}>
          <LicenseDetail
            classes={classes}
            item={message.licenseLabel ? message.licenseLabel : null}
          />
        </Collapse>
      </div>
    </div>
  );
};

export default AdminMessage;
