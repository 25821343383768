import React, { useEffect } from "react";
import { constants } from '../../../config/config';
import GlobalDataStore from '../../../GlobalDataStore';
import { makeStyles } from '@mui/styles';
import { find } from "lodash";
import { useTranslation } from 'react-i18next';

const setStyles = makeStyles((theme) => {
    const themeTitleColor = theme && theme.secondTitle && theme.secondTitle.color ? theme.secondTitle.color : null;
    const themeTitleFont = theme && theme.secondTitle && theme.secondTitle.font ? theme.secondTitle.font : null;
    return {
        sidebarContainer: {
            // width: '250px',
            //position: 'fixed'
        },
        emptyImgContainer: {
            width: '200px',
            height: '200px',
            display: 'flex',
            justifyContent: 'center',
            background: '#cccccc',
            boxShadow: '-1px 13px 9px -4px rgba(153,148,148,0.66)',
            '-webkit-box-shadow': '-1px 13px 9px -4px rgba(153,148,148,0.66)',
            '-moz-box-shadow': '-1px 13px 9px -4px rgba(153,148,148,0.66)',
        },
        image: {
            boxShadow: '-1px 13px 9px -4px rgba(153,148,148,0.66)',
            '-webkit-box-shadow': '-1px 13px 9px -4px rgba(153,148,148,0.66)',
            '-moz-box-shadow': '-1px 13px 9px -4px rgba(153,148,148,0.66)',
            borderRadius: '5px 5px 5px 5px',
            width: '200px',
            height: '200px'
        },
        dRDetails: {
            fontSize: '14px',
            marginTop: '10%',
            marginBottom: '25%',
            border: '1px solid #707070',
            paddingLeft: '1em',
            borderTop: '0px',
            borderBottom: '0px',
            borderRight: '0px',
            wordBreak: 'break-word',
            textAlign: 'left'
        },
        dRTitle: {
            marginTop: '4%'
        },
        labelStyle: {
            fontFamily: themeTitleFont,
            fontSize: '16px',
            color: themeTitleColor,
            fontWeight: '600',
            margin: '1em 0'
        },
        text: {
            lineHeight: '1.75em',
            color: 'rgba(0, 0, 0, 0.6)',
        }
    }
});

function SidebarInfoContainer({
    // classes,
    item,
    drInventoryNumber,
    idDigitalResource,
    isExistentDR
}) {
    const _ = require("lodash");
    const classes = setStyles();
    const [t] = useTranslation();



    const getDrImage = () => idDigitalResource
        ? constants.paths.thumbnail(GlobalDataStore.getTenantId(), idDigitalResource)
        : '';

    return (
        <div id='sidebarInfoContainer' className={classes.sidebarContainer}>
            <div className={getDrImage() ? '' : classes.emptyImgContainer}>{/** IMG Center W20% H20% */}
                {getDrImage() && (
                    <img alt='img' className={classes.image} src={getDrImage()} />
                )}
            </div>

            <div className={classes.dRDetails}>{/** Dati Opera W20% H20% */}
                {isExistentDR ? (
                    <>

                        <div className={classes.labelStyle}>{t('dr.imagedetails')}:</div>
                        {item && item.map(obj => {
                            if (obj.idMetadataCategory === 2) {
                                return <div className={classes.text}>{t(`configuration.metadataSection.${obj.metadata.toLowerCase()}`)}: {obj.value ? obj.value : '- - -'}</div>
                            }
                        })}


                        <div className={classes.labelStyle}>{t('dr.imagequality')}:</div>
                        {item && item.map(obj => {
                            if (obj.idMetadataCategory === 1) {
                                return <div className={classes.text}>{t(`configuration.metadataSection.${obj.metadata.toLowerCase()}`)}: {obj.value ? obj.value : '- - -'}</div>
                            }
                        })}

                    </>
                ) : (
                    <>
                        <div className={classes.labelStyle}>{t('sidebarinfocontainer.imagerequestedbyuser')}:</div>
                        <div className={classes.text}>{t('sidebarinfocontainer.workarttitle')}: {item && item.title ? item.title : '- - -'}</div>
                        <div className={classes.text}>{t('sidebarinfocontainer.author')}: {item && item.author ? item.author : '- - -'} </div>
                        <div className={classes.text}>{t('sidebarinfocontainer.dating')}: {item && item.dating ? item.dating : '- - -'} </div>
                        <div className={classes.text}>{t('sidebarinfocontainer.objectandclassification')}: {item && item.objectTypeAndClassification ? item.objectTypeAndClassification : '- - -'}</div>
                        <div className={classes.text}>{t('sidebarinfocontainer.inventorynumber')}: {drInventoryNumber ? drInventoryNumber : '- - -'}</div>
                    </>
                )}

            </div>
        </div>
    )
}

export default SidebarInfoContainer;