import { constants } from "../config/config";
import request from "./base/baseService";
import GlobalDataStore from "../GlobalDataStore";


const getVisualizationConfig = () => {

    let url = `${constants.api.url}api/visualsettings/${GlobalDataStore.getTenantId()}`;
    return request({
        url: url,
        method: 'GET',
    })

}

const getVisualizationContacts = () => {
    let url = `${constants.api.url}api/visualsettings/gettenatcontacts/${GlobalDataStore.getTenantId()}`;
    return request({
        url: url,
        method: 'GET',
    })
}

const updateVisualizationConfig = (config) => {
    let url = `${constants.api.url}api/visualsettings`;
    return request({
        url: url,
        method: 'PUT',
        data: config
    })
}

const uploadLogo = (file) => {
    let url = `${constants.api.url}api/visualsettings/${GlobalDataStore.getTenantId()}/logo`;
    let formData = new FormData();
    formData.append("file", file);

    return request({
        url: url,
        method: 'POST',
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })
}

const uploadWatermark = (file) => {
    let url = `${constants.api.url}api/visualsettings/${GlobalDataStore.getTenantId()}/watermark`;
    let formData = new FormData();
    formData.append("file", file);

    return request({
        url: url,
        method: 'POST',
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })
}

//termsAndConditions

const getTermsAndConditionsUrl = () => {
    let url = `${constants.api.url}api/visualsettings/${GlobalDataStore.getTenantId()}/termsandconditionsurl`;
    return request({
        url: url,
        method: 'GET',
    })
}

const updateTermsAndConditionsUrl = (link) => {
    let url = `${constants.api.url}api/visualsettings/termsandconditionsurl`;
    return request({
        url: url,
        method: 'put',
        data: link,
    })
}



export const VisualizationConfService = {
    getVisualizationConfig,
    getVisualizationContacts,
    updateVisualizationConfig,
    uploadLogo,
    uploadWatermark,
    getTermsAndConditionsUrl,
    updateTermsAndConditionsUrl,
}


export default VisualizationConfService;