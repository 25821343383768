import React from 'react';
import AdminReplyMessage from './AdminReplyMessage';
import AdminMessage from './AdminMessage';
import { makeStyles } from '@mui/styles';

const setStyles = makeStyles(() => ({
  root: {
    alignSelf: 'end',
    marginRight: '5.5em'
  },
  header: {
    justifyContent: 'space-between',
    backgroundColor: '#e5e5e5'
  }
}));

const AdminMessageContainer = ({
  isNewReply,
  onSendMessage,
  message,
  idDigitalResource,
  toBePaidBefore,
  renewConcession
}) => {
  const classes = setStyles();
  return (
    <>
      {isNewReply && (
        <AdminReplyMessage
          classes={classes}
          onSendMessage={onSendMessage}
          idDigitalResource={idDigitalResource}
          renewConcession={renewConcession}
        />
      )}
      {message && (
        <AdminMessage
          classes={classes}
          message={message}
          toBePaidBefore={toBePaidBefore}
        />
      )}
    </>
  );
};

export default AdminMessageContainer;
