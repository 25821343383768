export const getStateColor = stateLabel => {
    switch ((stateLabel || '').toUpperCase()) {
        case 'CREATED_BY_CUSTOMER':
            return 'blue';
        case 'WAITING_FOR_CUSTOMER_RESPONSE':
            return 'yellow';
        case 'WAITING_FOR_CUSTOMER_PAYMENT':
            return 'green';
        case 'CLOSED_FOR_REFUSAL':
            return 'red';
        case 'WAITING_FOR_MUSEUM_RESPONSE':
            return 'orange';
        case 'WAITING_FOR_MUSEUM_PUBLICATION':
            return 'green';
        case 'CLOSED_SUCCESSFULLY':
            return 'green';
        case 'CLOSED_FOR_EXPIRATION':
            return 'violet';
        case 'CLOSED_BY_MUSEUM':
            return 'dark';
        default:
            console.error(`unexpected CR state label: '${stateLabel}'`);
            return 'gray';
    }
};