import { constants } from "../config/config";
import request from "./base/baseService";

const url = `${constants.api.url}api/translations`;
const cultures = {
    loading: false,
    items: null
};

/**
 * Used to get translations filters for the translation edit page. Also is used to get user cultures
 * @returns 
 */
const getTranslationsFilters = () => {
    return request({
        url: url + '/filters',
        method: 'GET',
    });
};

const getTranslationsForCurrentLanguage = (lang) => {
    return request({
        url: `${url}/${lang}`,
        method: 'GET',
    });
};

const getCachedCultures = async () => {
    while (cultures.loading) {
        await new Promise(
            resolve => setTimeout(resolve, 500)
        );
    }

    if (!cultures.items) {
        try {
            cultures.loading = true;
            cultures.items = (
                await getTranslationsFilters()
            ).cultures;
        } finally {
            cultures.loading = false;
        }
    }

    return cultures.items;
};

export const TranlsationsService = {
    getTranslationsFilters,
    getTranslationsForCurrentLanguage,
    getCachedCultures
};

export default TranlsationsService;