import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { FormControl } from '@mui/material';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
/* eslint react-hooks/exhaustive-deps: "off" */

const setStyles = makeStyles((theme) => {
    //const background = theme && theme.background ? theme.background : null;
    return {
        root: {
            width: 'calc(100% - 36px)',
            maxWidth: '1500px',
            margin: 'auto',
            // width: 'calc(100% - 75px)',
            // maxWidth: 'calc(100% - 75px)',
            padding: '0 1em',
            paddingTop: '1%',
            marginBottom: '20px',
            boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
            fontFamily: 'Roboto'
        },
        titleContainer: {
            marginTop: '0!important',
            color: '#707070'
        },
        flexContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'flex-end'

        },
        label: {
            fontSize: '14px',
            color: '#707070',
            marginBottom: '4px',
        },
        input: {
            padding: '4px',
            color: '#707070',
            height: '22px',
            borderRadius: '4px',
            border: '1px solid #707070',
            '&:focus': {
                outline: 'none',
            }
        },
        column: {
            width: '30%',
            margin: '8px 0 !important',
            marginLeft: '0!important',
        },
        buttonsContainer: {
            display: 'inline-flex',
            justifyContent: 'space-between',
            width: '30%',
            '& Button': {
                '&:first-child': {
                    marginLeft: '0px !important',
                }
            },
        },
    }
})




/**
 * Componente per l'inserimento dei dati di una DR mancante
 */
export default function RequestMissingDR({
    onParametersChanged,
    onParametersConfirmed,
    cmwParameters,
}) {
    const classes = setStyles();
    const [confirmed, setConfirmed] = useState(false);
    const [title, setTitle] = useState('');
    const [author, setAuthor] = useState('');
    const [inventoryNumber, setInventoryNumber] = useState('');
    const [dating, setDating] = useState('');
    const [classification, setClassification] = useState('');
    const [t] = useTranslation();
    const clear = () => {
        setTitle('');
        setAuthor('');
        setInventoryNumber('');
        setDating('');
        setClassification('');
        setConfirmed(false);
    };

    const canClear = () =>
        title || author || inventoryNumber || dating || classification;

    const canConfirm = () =>
        title?.trim() && author?.trim() && inventoryNumber?.trim() &&
        dating?.trim() && classification?.trim() && !confirmed;

    useEffect(() => {
        if (cmwParameters !== null && title === "" && inventoryNumber === "") {
            setTitle(cmwParameters.title);
            setInventoryNumber(cmwParameters.inventoryNumber);
        }

    }, [])

    useEffect(() => {
        if (typeof onParametersChanged === 'function') {
            onParametersChanged({
                title,
                author,
                inventoryNumber,
                dating,
                classification
            });
        }
        if (typeof onParametersConfirmed === 'function') {
            onParametersConfirmed(confirmed);
        }
    }, [confirmed]);


    const setClassCancelBtn = () => {
        if (canClear()) {
            return 'rl-button'
        } else {
            return 'rl-button rl-button-disabled'
        }
    }

    const setClassConfirmBtn = () => {
        if (canConfirm()) {
            return 'rl-button'
        } else {
            return 'rl-button rl-button-disabled'
        }
    }

    return (
        <div className={classes.root}>
            <p className={classes.titleContainer}>Compila i dati riferiti all'opera di cui sei a conoscenza:</p>

            <div className={classes.flexContainer}>

                <FormControl sx={{
                    verticalAlign: 'baseline!important',
                    '& .MuiInputLabel-root.Mui-focused': {
                        color: '#494949!important'
                    }
                }} className={classes.column} variant="standard"
                >
                    <label className={classes.label}>
                        {t('dr.title')}:
                    </label>
                    <input
                        id="title-input"
                        className={classes.input}
                        value={title}
                        disabled={confirmed}
                        autocomplete="off"
                        onChange={evt => setTitle(evt.target.value)}
                    />
                </FormControl>


                <FormControl sx={{
                    verticalAlign: 'baseline!important',
                    '& .MuiInputLabel-root.Mui-focused': {
                        color: '#494949!important'
                    }
                }} className={classes.column} variant="standard">
                    <label className={classes.label}>
                        {t('dr.author')}:
                    </label>
                    <input
                        id="author-input"
                        className={classes.input}
                        value={author}
                        disabled={confirmed}
                        autocomplete="off"
                        onChange={evt => setAuthor(evt.target.value)}
                    />
                </FormControl>

                <FormControl sx={{
                    verticalAlign: 'baseline!important',
                    marginRight: '0!important',
                    '& .MuiInputLabel-root.Mui-focused': {
                        color: '#494949!important'
                    }
                }} className={classes.column} variant="standard">
                    <label className={classes.label}>
                        {t('dr.inventorynumber')}:
                    </label>
                    <input
                        id="number-input"
                        className={classes.input}
                        value={inventoryNumber}
                        disabled={confirmed}
                        autocomplete="off"
                        onChange={evt => setInventoryNumber(evt.target.value)}
                    />
                </FormControl>

                <FormControl sx={{
                    verticalAlign: 'baseline!important',
                    '& .MuiInputLabel-root.Mui-focused': {
                        color: '#494949!important'
                    }
                }} className={classes.column} variant="standard">
                    <label className={classes.label}>
                        {t('dr.date')}:
                    </label>
                    <input
                        id="dating-input"
                        value={dating}
                        className={classes.input}
                        disabled={confirmed}
                        autocomplete="off"
                        onChange={evt => setDating(evt.target.value)}
                    />
                </FormControl>

                <FormControl sx={{
                    verticalAlign: 'baseline!important',
                    '& .MuiInputLabel-root.Mui-focused': {
                        color: '#000!important'
                    }
                }} className={classes.column} variant="standard">
                    <label className={classes.label}>
                        {t('dr.classification')}:
                    </label>
                    <input
                        id="classification-input"
                        className={classes.input}
                        value={classification}
                        disabled={confirmed}
                        autocomplete="off"
                        onChange={evt => setClassification(evt.target.value)}
                    />
                </FormControl>

                <div className={classNames(classes.column, classes.buttonsContainer)}>
                    <Button
                        className={setClassCancelBtn()}
                        onClick={clear}>
                        {t('filter.cancelshort')}
                    </Button>

                    <Button
                        className={setClassConfirmBtn()}
                        onClick={() => setConfirmed(true)}
                    >
                        {t('filter.applyshort')}
                    </Button>
                </div>
            </div>
        </div >
    );
};