import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import { FiPlusCircle } from 'react-icons/fi';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

const setStyles = makeStyles(() => ({
    btnNewCondition: {
        height: '25px',
        background: '#FFFFFF',
        borderRadius: '17.5px',
        padding: '5px 7px',
        margin: '30px 0 20px 0',
        color: '#E7004E',
        fontSize: '16px',
        fontWeight: '500',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        '& span': {
            margin: '0 25px'
        }
    },
    iconPlusCircle: {
        height: '24px',
        width: '24px',
    },
    optionContainer: {
        display: 'block',
        color: '#E7004E',
        borderRadius: '0 0 17.5px 17.5px',
        padding: '24.5px 8px 15px 8px',
        marginTop: '-37.5px',
        marginBottom: '20px',
        background: '#FFFFFF',
    },
    option: {
        cursor: 'pointer',
        marginLeft: '49px'
    }
}))


export const AddVocabulary = ({
    optionalItems,
    optionalAdded
}) => {

    const classes = setStyles();
    const [t] = useTranslation();
    const [openMenu, setOpenMenu] = useState(false);


    const onAdd = id => {
        if (typeof optionalAdded === 'function') {
            optionalAdded(id);
        }
    };


    return (
        <div id='addVocabulary'>
            <OutsideClickHandler onOutsideClick={() => setOpenMenu(false)}>
                <div className={classes.btnNewCondition} onClick={() => setOpenMenu(!openMenu)}>
                    <FiPlusCircle className={classes.iconPlusCircle} />
                    <span>{t('license.concessionsModal.btnNewCondition')}</span>
                </div>


                {openMenu && (

                    <div className={classes.optionContainer}>

                        {openMenu && optionalItems && !isEmpty(optionalItems) && optionalItems.map(item =>
                            <div
                                className={classes.option}
                                value={item}
                                key={item.itemOrder}
                                onClick={() => { onAdd(item.itemOrder); setOpenMenu(!openMenu) }}
                            >
                                {item.vocabularyTitle}
                            </div>
                        )}
                    </div>
                )}
            </OutsideClickHandler>
        </div>
    );
};


AddVocabulary.propTypes = {
    optionalItems: PropTypes.array,
    //output 
    optionalAdded: PropTypes.func
}

export default AddVocabulary;
