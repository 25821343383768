export const transformToList = ((digitalResources) => {
    const pagination = digitalResources.pagination;
    const newList = { 
        items: digitalResources.items, 
        pagination: {
            FirstItemOnPage: pagination.firstItemOnPage,
            HasNextPage: pagination.hasNextPage,
            HasPreviousPage: pagination.hasPreviousPage,
            IsFirstPage: pagination.isFirstPage,
            IsLastPage: pagination.isLastPage,
            LastItemOnPage: pagination.lastItemOnPage,
            NextPageNumber: pagination.nextPageNumber,
            PageCount: pagination.pageCount,
            PageNumber: pagination.pageNumber,
            PageSize: pagination.pageSize,
            TotalItemCount: pagination.totalItemCount,
            PreviousPageNumber: pagination.previousPageNumber,
        }
    };
    return newList;
});

