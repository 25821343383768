import { constants } from "../../config/config";
import request from "../base/baseService";

const baseUrl = `${constants.api.url}api/licenseconfiguration`;

const loadSelectableTerms = (
    itemOrder,
    selectionData
) => request({
    url: `${baseUrl}/getselectableterms/${itemOrder}`,
    method: 'POST',
    data: selectionData || {}
});

const saveConcession = concessionData => request({
    url: `${baseUrl}/createorupdatelicense`,
    method: 'PUT',
    data: concessionData
});

const loadConcession = idConcession => request({
    url: `${baseUrl}/getlicensedetailsforupdate/${idConcession}`,
    method: 'GET'
});

export const ConcessionsService = {
    loadSelectableTerms,
    saveConcession,
    loadConcession
};