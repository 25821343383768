/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect, useState } from 'react';
import { makeStyles } from "@mui/styles";
import { transformToList } from '../../transformations/digitalResourcesTransformation';
import { HomePageService } from '../../service/usersService/homePageService';
import ListViewAdmin from './ListViewAdmin/ListViewAdmin';
import { Checkbox, FormControlLabel, IconButton, Pagination, useTheme } from '@mui/material';
import GlobalDataStore from '../../GlobalDataStore';
import classNames from 'classnames';
// import GridViewAdmin from './GridViewAdmin';
import SectionHeader from '../common/SectionHeader/SectionHeader';
import SidebarFilters from '../common/SidebarFilters/SidebarFilters';
import _, { isEmpty } from 'lodash';
import isString from 'lodash/isString';
import { HiViewBoards } from 'react-icons/hi';
import { usePersistentState, storePersistentState } from '../../commons/persistentState';
import ShowHideLoader from '../../hooks/showHideLoader';
import OutsideClickHandler from 'react-outside-click-handler';
import { useTranslation } from 'react-i18next';



const setStyle = () => {
    return makeStyles({
        root: {
            width: 'calc(100% - 70px)',
            display: 'inline-block',
            fontFamily: "Roboto",
            overflowX: 'hidden',
        },
        pageContainer: {
            display: 'flex',
            height: 'calc(100vh - 70px)'
        },
        sectionHeader: {
            alignItems: 'center'
        },
        headerActionsContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            color: '#d4d4d4',
            cursor: 'pointer'
        },
        iconButton: {
            height: '1.5em',
            width: '1.5em'
        },
        columnsSelectContainer: {
            position: 'absolute',
            top: '3em',
            right: '.5em',
            background: 'white',
            width: '10em',
            padding: '.5em',
            boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',

        },
        columnSelectTitle: {
            margin: '0',
            fontSize: '.9em',
            fontWeight: '600',
            color: '#5e5e5e'
        },
        checkboxesContainer: {
            marginTop: '.5em',
            maxHeight: '10em',
            overflowY: 'scroll',
            overflowX: 'hidden'
        },
        bodyHomePage: {
            width: '100vw'
        },
        imagesContainer: {
            height: 'calc(100vh - 125px)'
        },
        flexCenter: {
            display: "flex",
            justifyContent: 'center'
        },
        paginationContainer: {
            // Used this style to prevent the table from going over the pagination
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '12px',
        },
        headingText: {
            width: 'calc(100% - 120px)',
            margin: '0 auto',
            fontSize: '16px',
            textAlign: 'center',
            marginBottom: '46px'
        }
    })()
}

const FILTERS_PERSIST_KEY = 'ListOfDigitalResources';

function ListOfDigitalResources() {

    const theme = useTheme();
    const classes = setStyle(theme);
    const filtersStructure = {
        author: [],
        title: [],
        classification: [],
        inventory: []
    }
    const [filters, setFilters] = useState({ ...filtersStructure });
    const [showLoader, hideLoader] = ShowHideLoader();
    const [t] = useTranslation();


    const columnsToShow = [
        'name',
        'author',
        'drInventory',
        'width',
        'height',
        'resolution',
        'classification'
    ];

    const [showSelectColumns, setShowSelectColumns] = useState(false);

    const [visibleColumns, setVisibleColumns] = usePersistentState(
        FILTERS_PERSIST_KEY, 'VisibleColumns', [...columnsToShow]);

    const showHideColumn = (column, visible) => {
        if (visible) {
            setVisibleColumns([...visibleColumns, column]);
        } else {
            setVisibleColumns(visibleColumns.filter(x => x !== column));
        }
        storePersistentState(FILTERS_PERSIST_KEY);
    };

    /**
     * 
     * @returns elenco classificazione da passare alla checkSelect del filtro;
     */
    const getClassificationList = () => {
        let tempArray = HomePageService.getClassificationList();
        if (tempArray instanceof Promise) {
            tempArray.then((resp) => {
                return (resp)
            })
                .catch((err) => {
                    console.error('Errore!', { err })
                })
        } else {
            return tempArray ? tempArray : [];
        }
    }


    const filtersComponentsList = [
        {
            title: t('drList.title'),
            property: 'title',
            type: 'text'
        },
        {
            title: t('drList.author'),
            property: 'author',
            type: 'text'
        },
        {
            title: t('drList.classification'),
            property: 'classification',
            type: 'multivalue',
            values: getClassificationList()
        },
        {
            title: t('drList.inventory'),
            property: 'inventory',
            type: 'text'
        }
    ]

    const [imagesList, setImagesList] = useState({
        items: [],
        pagination: {
            PageCount: 0,
            TotalItemCount: 0,
            PageNumber: 1,
            PageSize: 10,
            HasPreviousPage: false,
            HasNextPage: true,
            IsFirstPage: true,
            IsLastPage: false,
            FirstItemOnPage: 1,
            LastItemOnPage: 10,
            NextPageNumber: 2,
            PreviousPageNumber: null
        }
    });


    /**
     * OnInit
     */
    useEffect(() => {
        getItemsList(filters);
    }, []);



    /**
     * Chiamata per recuperare l'elenco di oggetti da mostrare
     * @returns 
     */
    const getItemsList = (filt) => {
        showLoader();
        let tempArray = HomePageService.getImagesList(GlobalDataStore.getTenantId(), imagesList.pagination.PageNumber, imagesList.pagination.PageSize, filt);
        if (tempArray instanceof Promise) {
            tempArray.then((resp) => {
                const imagesList = transformToList(resp);
                setImagesList(imagesList)
                return (imagesList)
            }).catch((err) => {
                console.error('Errore!', { err })
            }).finally(hideLoader);
        } else {
            hideLoader();
            return tempArray;
        }
    }

    const renderHeaderActions = () => {
        return <div className={classes.headerActionsContainer}>
            <IconButton className={classes.iconButton} onClick={() => setShowSelectColumns(!showSelectColumns)}>
                <HiViewBoards />
            </IconButton>
            {showSelectColumns && (
                <div className={classes.columnsSelectContainer}>
                    <OutsideClickHandler onOutsideClick={() => setShowSelectColumns(false)}>
                        <p className={classes.columnSelectTitle}>{t('customRequest.columnTitle')}: </p>
                        <div className={classes.checkboxesContainer}>
                            {columnsToShow.map((col, index) =>
                                <FormControlLabel
                                    key={index}
                                    label={t(`drList.${col}`)}
                                    checked
                                    control={
                                        <Checkbox
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: '#E7004E !important',
                                                },
                                                '& .MuiSvgIcon-root': { fontSize: '1.2em' },
                                                paddingTop: '.3em !important',
                                                paddingBottom: '.3em !important',
                                            }}
                                            checked={visibleColumns.indexOf(col) !== -1}
                                            onChange={evt => showHideColumn(col, evt.target.checked)}
                                        ></Checkbox>
                                    }
                                    sx={{
                                        color: 'black!important',
                                        width: '100%',
                                        '& .MuiFormControlLabel-label': {
                                            fontSize: '.9em!important'
                                        }
                                    }}
                                >
                                </FormControlLabel>
                            )}
                        </div>
                    </OutsideClickHandler>

                </div>
            )}
        </div>
    }

    const applyFilters = (tempFilters) => {

        // Change value of the object that are strings in arrays
        const convertedTempFilters = _.mapValues(tempFilters, (x) => {
            if (isString(x)) {
                return [x];
            }
            return x;
        });

        setFilters(convertedTempFilters);
        backToFirstPage();
        getItemsList(convertedTempFilters);
    }

    const backToFirstPage = () => {
        const tmpImagesList = { ...imagesList };
        tmpImagesList.pagination.PageNumber = 1;
        setImagesList(tmpImagesList);
    }

    const changePage = (event, page) => {
        const tmpImagesList = { ...imagesList };
        if (page > tmpImagesList.pagination.PageNumber && tmpImagesList.pagination.HasNextPage) {
            tmpImagesList.pagination.PageNumber = page;
        }
        if (page < tmpImagesList.pagination.PageNumber && tmpImagesList.pagination.HasPreviousPage) {
            tmpImagesList.pagination.PageNumber = page;
        }

        setImagesList(tmpImagesList);
        getItemsList();
    }

    return (
        <>
            <div id='listOfDigitalResources' className={classes.root}>
                <SectionHeader
                    title={t('sidebar.digitalResource')}
                    renderActions={renderHeaderActions}
                    classNames={classes.sectionHeader}
                />

                <div className={classes.pageContainer}>

                    <SidebarFilters
                        filtersType={filtersComponentsList}
                        filtersValue={filters}
                        filtersStructure={filtersStructure}
                        applyFilters={applyFilters}
                    />

                    <div className={classes.bodyHomePage}>
                        <div className={classes.imagesContainer}>

                            <ListViewAdmin
                                imagesList={imagesList}
                                visibleColumns={visibleColumns}
                            />

                        </div>

                        {imagesList && !isEmpty(imagesList.items) && imagesList.pagination && (
                            <div className={classNames(classes.flexCenter, classes.paginationContainer)}>
                                <Pagination
                                    count={imagesList.pagination.PageCount}
                                    page={imagesList.pagination.PageNumber}
                                    onChange={changePage}
                                />
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </>
    )
}

export default ListOfDigitalResources;

