import React, { Fragment } from 'react'
import { SpinnerCircularFixed } from 'spinners-react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';

const setStyles = (theme) => {
    const pathname = window.location?.pathname?.toLowerCase().indexOf('/adminarea' || '/systemadmin');
    const isUserArea = (pathname >= 0 ? false : true);
    return makeStyles(() => ({

        root: {
            display: "flex !important",
            justifyContent: "center",
            alignItems: "center",
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            top: '0',
            zIndex: '10000',
            '& svg': {
                color: `${isUserArea && theme && theme.firstTitle && theme.firstTitle.color ? theme.firstTitle.color : (!isUserArea ? '#E7004E' : null)} !important`,
                width: '50px !important',
            }
        },
    }))()
};

const Loader = () => {
    const theme = useTheme();
    const classes = setStyles(theme);


    return (
        <Fragment>
            <div className={classes.root}>
                <SpinnerCircularFixed />
            </div>
        </Fragment>
    )


}

export default Loader
