import request from "../base/baseService";
import { constants } from "../../config/config";

const getCreationParameters = () => request({
    //commentare
    url: constants.paths.getCustomRequestParameters(),
    method: 'GET',
});

const createNewRequest = data => request({
    url: constants.paths.getCreateNewRequest(),
    method: 'POST',
    data
});

const payCustomRequest = (data, idOrder) => {
    return request({
        url: constants.paths.payCustomRequest(idOrder),
        method: 'POST',
        data: data
    });
}

export const CustomRequestUserService = {
    getCreationParameters,
    createNewRequest,
    payCustomRequest
};

export default CustomRequestUserService;