import request from "../base/baseService";
import { constants } from "../../config/config";

const sendImage = (file, imageInfo, idOrderDetails) => {

    let url = `${constants.api.url}api/customrequest/sendcrimage/${idOrderDetails}`;

    let formData = new FormData();
    formData.append("imageInfo", JSON.stringify(imageInfo));

    formData.append("file", file);

    return request({
        url: url,
        method: 'POST',
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })
};

const getCRsList = (page, pageSize, filters) =>
    request({
        url: constants.paths.crsList(page, pageSize),
        method: 'POST',
        data: filters || {}
    });

const getCRsUser = (page, pageSize) =>
    request({
        url: constants.paths.crsUser(page, pageSize),
        method: 'POST'
    });

const getCRDetails = (idCustomRequest) =>
    request({
        url: constants.paths.crDetails(idCustomRequest),
        method: 'GET',
    })

const getStatesList = () => request({
    url: constants.paths.crStatesList(),
    method: 'GET'
});

const sendNewMessageUser = (idCustomRequest, data) => request({
    url: constants.paths.crSendUserMessage(idCustomRequest),
    method: 'POST',
    data: data
});

const acceptCustomRequest = (idCustomRequest) =>
    request({
        url: constants.paths.acceptCustomRequest(idCustomRequest),
        method: 'PUT',
    });

const getConcessions = idConcessions => request({
    url: constants.paths.crConcessions(),
    method: 'POST',
    data: idConcessions || []
});

const getTermsSelecedByUser = idCustomRequest => request({
    url: constants.paths.getTermsSelecedByUser(idCustomRequest),
    method: 'GET'
});

export const CustomRequestService = {
    sendImage,
    getCRsList,
    getCRsUser,
    getCRDetails,
    sendNewMessageUser,
    getStatesList,
    acceptCustomRequest,
    getConcessions,
    getTermsSelecedByUser
};

export default CustomRequestService;