import { constants } from "../../config/config";

import request from "../base/baseService";



// Elenco vocabolario classificazione per filtro - select classification
const getClassificationList = () => {
    // return request({
    //     url: '',
    //     method: '',
    // })

}


const getImagesList = (tenantId, page = 1, pageSize = 10, filters = {}) => {
    return request({
        url: constants.paths.homeImages(tenantId, page, pageSize),
        method: 'POST',
        data: { filters }
    })
}


export const HomePageService = {
    getClassificationList,
    getImagesList,
}

