import request from "./base/baseService";
import { constants } from "../config/config";
import GlobalDataStore from "../GlobalDataStore";

let url = `${constants.api.url}api/digitalresources`;

const getDetails = (idDigitalResource) => {
    return request({
        url: `${url}/home/${GlobalDataStore.getTenantId()}/${idDigitalResource}`,
        method: 'GET'
    })
}

export const DigitalResourceService = {
    getDetails
}

export default DigitalResourceService;