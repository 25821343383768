import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import AuthenticationService from '../../../service/usersService/authenticationService';
import { AiOutlineLogout } from 'react-icons/ai';
import GlobalDataStore from '../../../GlobalDataStore';
import classNames from 'classnames';
import config from '../../../config/config';
import { saveLink } from '../../../redux/slices/linkToBack';
import { useDispatch } from 'react-redux';
import VisualizationConfService from '../../../service/visualizationConfService';
import { UsersService } from "../../../service/commons/userService";
import ShowHideLoader from "../../../hooks/showHideLoader";
import MessageModal from '../../commons/Modals/MessageModal';
import { useTranslation } from 'react-i18next';

const setStyles = makeStyles((theme) => {
    const themeBackground = theme && theme.background ? theme.background : null;
    const themeTitle = theme && theme.firstTitle && theme.firstTitle.color ? theme.firstTitle.color : null;
    return {
        menuContainer: {
            right: '60px',
            zIndex: '101',
            position: 'absolute',
            top: '40px',
            background: themeBackground,
            // bottom: '55px',
            // height: '100px',
            width: '220px',
        },
        menu: {
            position: 'relative',
            // left: '100%',
            width: '220px',
            zIndex: '101',
            background: 'rgba(255, 255, 255, .3)',
            padding: '8px 0',
            fontSize: '12px',
            color: 'rgba(75 75 75 1)',
            fontWeight: '500',
            lineHeight: '1.75',
            letterSpacing: '0.03125em',
            textTransform: 'uppercase',
            boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
            borderRadius: '5px',
        },
        btnLogin: {
            margin: '10px',
            padding: '5px 8px',
            border: '1px solid #707070',
            borderRadius: '5px',
            width: 'calc(100% - 20px)',
            height: '28px',
            textTransform: 'uppercase',
            fontSize: '14px',
            color: '#707070',
            display: 'block',
            background: 'transparent',
            cursor: 'pointer',
            '&:hover': {
                background: 'rgba(255, 255, 255, .2)',
            }
        },
        goRegister: {
            display: 'flex',
            margin: '12px 11px 0 11px',
            padding: '0 8px',
            fontSize: '10px',
            color: '#707070',
            borderBottom: '1px solid',
            height: '25px'
        },
        linkToRegister: {
            marginLeft: '4px',
            textTransform: 'uppercase',
            fontWeight: '600',
            color: themeTitle,
            cursor: 'pointer',

        },
        support: {
            margin: '8px 6px 4px 6px',
            paddingBottom: '6px',
            textTransform: 'none',
            color: '#707070',
            fontWeight: '400',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            fontSize: '12px',
            textDecoration: 'underline',
        },
        supportIcon: {
            display: 'inline-block',
            marginRight: '8px',
            width: '34px',
            // WTC
            // eslint-disable-next-line no-dupe-keys
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'default',
            '& img': {
                height: '16px',
                width: '16px',
            }
        },

        profileContainer: {
            margin: '0 11px',
            fontSize: '10px',
            color: '#707070',
        },
        btnProfileContainer: {
            display: 'flex',
            alignItems: 'center',
            width: 'calc(100% - 12px)',
            color: themeTitle,
            fontSize: '12px',
            padding: '0 6px',
            marginBottom: '6px'
        },
        btnProfileLogout: {
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            width: 'calc(100% - 12px)',
            color: themeTitle,
            fontSize: '12px',
            padding: '0 6px',
        },
        //Profile
        linkToProfile: {
            cursor: 'default',
            width: '100%',
            height: '36px',
            display: 'flex',
            alignItems: 'center',
            // '&:hover': {
            //     background: 'rgba(255, 255, 255, .3);'
            // }
        },
        iconProfile: {
            background: 'rgba(255, 255, 255, .2)',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '34px',
            width: '34px',
            marginRight: '6px',
            '& img': {
                height: '16px'
            }
        },
        name: {
            lineHeight: '1.3'
        },
        btnGoToProfile: {
            minWidth: 'calc(100% - 24px) !important',
            marginBottom: '12px',
        },
        //Logout
        btnLogout: {
            borderTop: '1px solid #707070',
        },
        linkToLogout: {
            width: '100%',
            height: '36px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': {
                background: 'rgba(255, 255, 255, .3);'
            }
        },
        logoutIcons: {
            width: '17px',
            height: '17px',
            cursor: 'pointer',
            margin: ' 0 12px',
        },

    }
})
function IconsContainer({ classes }) {

    const [t] = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const internalClasses = setStyles();
    const [openMenu, setOpenMenu] = useState(false);
    const [linkTermsConditions, setlinkTermsConditions] = useState('')
    const [showLoader, hideLoader] = ShowHideLoader();
    const [openModalChangePwd, setOpenModalChangePwd] = useState(false);

    useEffect(() => {
        toGetTermsAndConditionsUrl();
    }, [])


    const openDropDown = () => {
        if (!openMenu) {
            setOpenMenu(!openMenu);
        }
    }

    //get for terms and conditions page link
    const toGetTermsAndConditionsUrl = async () => {
        try {
            let url = await VisualizationConfService.getTermsAndConditionsUrl();
            setlinkTermsConditions(url);
        } catch (err) {
            console.error(err);
        } finally {
        }
    }


    /**
     * Navigazione a Login / UserRegistration 
     * @param {string} page 
     */
    const navigationToPage = (page) => {
        if (page === config.routes.login) {
            const payload = {
                data: history.location.pathname,
            }
            dispatch(saveLink(payload));
        }
        history.push(page);
    }


    const openNewTab = () => {
        window.open(`${linkTermsConditions}`, '_blank')
    }


    const logout = () => {
        AuthenticationService.logout();
        history.push('/App');
    }

    const changePassword = async () => {
        try {
            showLoader();
            await UsersService.changePassword();
            setOpenModalChangePwd(true);
        } finally {
            hideLoader();
        }
    };

    return (
        <>
            <div id='iconsContainer' className={classes.flexEnd}>
                <img alt={t('iconsContainer.orders')}
                    className={`${classes.marginLeft16px} rl-headerIcon`}
                    src="/images/navBar/shopping-bag.svg"
                    onClick={() => navigationToPage(config.routes.orders)} />

                <img alt={t('iconsContainer.cr')}
                    className={`${classes.marginLeft16px} rl-headerIcon`}
                    src="/images/navBar/mail-svgrepo-com.svg"
                    onClick={() => navigationToPage(config.routes.customRequestsUser)} />

                <img alt={t('iconsContainer.basket')}
                    className={`${classes.marginLeft16px} rl-headerIcon`}
                    src="/images/navBar/shopping-cart.svg"
                    onClick={() => navigationToPage(config.routes.basket)} />

                <img alt=""
                    className={`${classes.marginLeft16px} rl-headerIcon`}
                    src="/images/navBar/user-profile.svg"
                    onClick={() => openDropDown()} />
            </div>


            {/* Menù container Profile - logout / login - register */}
            {openMenu && (
                <div className={internalClasses.menuContainer} onClick={() => setOpenMenu(false)}>
                    <OutsideClickHandler onOutsideClick={() => { setOpenMenu(false) }} >

                        {GlobalDataStore.isLoggedIn() ? (
                            <div className={internalClasses.menu}>
                                {/* <div>
                                    <button className={internalClasses.btnLogin} type="submit">Profile</button>
                                </div> */}

                                <div className={internalClasses.profileContainer}>
                                    <div className={internalClasses.btnProfileContainer}>
                                        <div className={internalClasses.linkToProfile}>
                                            <div className={internalClasses.iconProfile}>
                                                <img alt=""
                                                    src="/images/navBar/user-profile.png"
                                                    onClick={() => openDropDown()} />
                                            </div>

                                            <div className={classes.name}>{localStorage.getItem('userFullName') || ''}</div>

                                        </div>
                                    </div>

                                    <div className={internalClasses.support}>
                                        <div className={internalClasses.supportIcon}>
                                            <img alt="" src="/images/navBar/change-pwd.png" />
                                        </div>
                                        <span onClick={changePassword}>{t('iconscontainer.changepassword')}</span>
                                    </div>

                                    <div className={internalClasses.support}>
                                        <div className={internalClasses.supportIcon}>
                                            <img alt="" src="/images/navBar/support.png" />
                                        </div>

                                        <span onClick={() => navigationToPage(config.routes.support)}>{t('iconscontainer.supportpage')}</span>

                                    </div>

                                    {linkTermsConditions && (
                                        <div className={internalClasses.support}>
                                            <div className={internalClasses.supportIcon}>
                                                <img alt="" src="/images/navBar/contract.png" />
                                            </div>

                                            <span onClick={() => openNewTab()}>{t('iconscontainer.termsandconditions')}</span>
                                        </div>
                                    )}


                                    {/* <button className={` rl-button ${internalClasses.btnGoToProfile} `}>
                                        Profile
                                    </button> */}

                                    <div className={classNames(internalClasses.btnProfileLogout, internalClasses.btnLogout)}>
                                        <div className={internalClasses.linkToLogout} onClick={() => logout()}>
                                            <AiOutlineLogout className={internalClasses.logoutIcons} />
                                            {t('iconscontainer.logout')}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        ) : (
                            <div className={internalClasses.menu}>
                                <div>
                                    <button onClick={() => navigationToPage(config.routes.login)} className={internalClasses.btnLogin} type="submit">Login</button>
                                </div>

                                <div className={internalClasses.goRegister}>
                                    <span>{t('iconscontainer.newclient')}</span>
                                    <span className={internalClasses.linkToRegister} onClick={() => navigationToPage(config.routes.userCreation)}>{t('iconscontainer.signuphere')} </span>
                                </div>

                                <div className={internalClasses.support}>
                                    <div className={internalClasses.supportIcon}>
                                        <img alt="" src="/images/navBar/support.png" />
                                    </div>
                                    <span onClick={() => navigationToPage(config.routes.support)}>{t('iconscontainer.supportpage')} </span>
                                </div>

                                {linkTermsConditions && (
                                    <div className={internalClasses.support}>
                                        <div className={internalClasses.supportIcon}>
                                            <img alt="" src="/images/navBar/contract.png" />
                                        </div>
                                        <span onClick={() => openNewTab()}>{t('iconscontainer.termsandconditions')}</span>
                                    </div>
                                )}
                            </div>
                        )}


                    </OutsideClickHandler>
                </div>
            )}

            {openModalChangePwd && (
                <MessageModal
                    showModal={openModalChangePwd}
                    title={t('iconscontainer.changepassword')}
                    modalText={t('iconscontainer.changepasswordmessage')}
                    closeModal={() => setOpenModalChangePwd(false)}
                />
            )}
        </>
    )
}

IconsContainer.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default IconsContainer;

