import { constants } from "../config/config";
import request from "./base/baseService";


//lista di licenze con paginazione;
const getLicensesList = (page = 1, pageSize = 10, filters = null) => {
    return request({
        url: constants.paths.licensesList(page, pageSize),
        method: 'POST',
        data: filters || {}
    })
}

const saveUpdateLicense = (data) => {
    if (data.idMuseumDefinedLicense) {
        return request({
            url: `${constants.paths.licenseDetails()}/updateLicense`,
            method: 'PUT',
            data: data
        })
    } else {
        return request({
            url: `${constants.paths.licenseDetails()}/createLicense`,
            method: 'POST',
            data: data
        })
    }
}

const deleteLicense = (id) => {
    return request({
        url: constants.paths.deleteLicense(id),
        method: 'DELETE',
    })
}


const getLicenseLabel = (id) => {
    return request({
        url: `${constants.paths.licenselabel(id)}`,
        method: 'GET',
    })
}

const getMedia = () => {
    return request({
        url: `${constants.paths.getMedia()}`,
        method: 'GET',
    })
}

const getTenantTerms = (id) => {
    return request({
        url: `${constants.paths.getTerms(id)}`,
        method: 'GET',
    })
}

const addOrUpdateTerm = (term) => {
    return request({
        url: `${constants.paths.addOrUpdateTerm()}`,
        method: 'POST',
        data: term
    })
}

export const LicensesService = {
    getLicensesList,
    saveUpdateLicense,
    deleteLicense,
    getLicenseLabel,
    getMedia,
    addOrUpdateTerm,
    getTenantTerms
}


export default LicensesService;