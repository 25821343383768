/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LicenseUserService from '../../../service/usersService/licenseUserService';
import { saveLicenseVocabularies } from '../../../redux/slices/customRequestSlice';
import { alpha } from '@material-ui/core';
import ShowHideLoader from '../../../hooks/showHideLoader';
import { useTranslation } from 'react-i18next';
import { cloneDeep, isEmpty } from 'lodash';
import { TermSelect } from '../../DigitalResourceDetails/LicenseSelect/TermSelect';
import { useHistory } from 'react-router-dom';

const setStyles = makeStyles((theme) => {
    const themeTitleColor = theme && theme.secondTitle && theme.secondTitle.color ? theme.secondTitle.color : null;
    const themeTitleFont = theme && theme.secondTitle && theme.secondTitle.font ? theme.secondTitle.font : null;
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            padding: '1em',
            paddingTop: '0',
            margin: '20px',
            boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
            width: '35%',
            background: 'rgba(255, 255, 255, .3)',
            fontFamily: 'Roboto',
        },
        subtitle: {
            fontSize: '14px',
            fontWeight: 500,
            color: themeTitleColor,
            fontFamily: themeTitleFont,
        },
        'params-select-container': {
            // margin: '.5em 0',
            marginTop: '4px',
            width: '100%'
        },
        labelTitle: {
            color: '#707070',
            fontSize: '14px',
            marginBottom: '4px',
        },
        select: {
            width: '100%',
            marginTop: '4px',
            '& .MuiSelect-select': {
                padding: '10px!important'
            },
            '&.Mui-focused': {
                boxShadow: `${alpha('#494949', 0.25)} 0 0 0 0.2rem`,
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: `${alpha('#494949', 0.5)} !important`
                }
            }
        }
    }
})

/**
 * Componente di sx con le select Utilizzo, Territorio, ecc
 */
export default function RequestParameters({
    onParametersChanged
}) {
    const classes = setStyles();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const parameters = useSelector(state => state.customRequests.parameters);
    const licenseParam = useSelector(state => state.customRequests.licenseVocabularies);
    const [showLoader, hideLoader] = ShowHideLoader();
    const [termSelected, setTermSelected] = useState({});
    const history = useHistory();




    useEffect(async () => {
        if (!parameters?.loaded) {
            try {
                showLoader();
                const items = await LicenseUserService.getTermsCustomRequest();
                dispatch(saveLicenseVocabularies(items));
            } finally {
                hideLoader();
            }
        }
    }, []);


    useEffect(async () => {
        try {
            showLoader();
            const items = await LicenseUserService.getTermsCustomRequest();
            dispatch(saveLicenseVocabularies(items));
        } finally {
            hideLoader();
        }
    }, [localStorage.getItem('i18nextLng')])


    useEffect(() => {
        if (!isEmpty(licenseParam)) {
            const searchPath = location.search;
            const splitString = searchPath.split('&');
            let temp = termSelected ? cloneDeep(termSelected) : [];

            for (let x = 1; x < splitString.length; x++) {
                let idArray = splitString[x].split('=');
                if (idArray[1] > 0) { temp[idArray[0]] = idArray[1] }
            }
            setTermSelected(temp)
            chanchedParameters(temp)
            const path = location.pathname.concat(splitString[0])
            history.replace(path);
        }

    }, [licenseParam])




    const chanchedParameters = (definedTerms) => {
        onParametersChanged({
            definedTerms,
        });
    }


    const handleChange = (termData, termId) => {

        const definedTerms = { [termData.itemOrder]: termId }

        if (isEmpty(termSelected)) {
            setTermSelected(definedTerms)
            chanchedParameters(definedTerms)
        } else {
            let temp = cloneDeep(termSelected);
            temp[termData.itemOrder] = termId;

            setTermSelected(temp)
            chanchedParameters(temp)

        }

    }



    return (
        <div className={classes.root}>
            <p className={classes.subtitle}>{t('requestparameters.selectparameters')}:</p>

            {licenseParam && !isEmpty(licenseParam) && licenseParam.map(param => {
                let paramWithIdSelected = {
                    ...param,
                    idSelected: termSelected[param.itemOrder] ? termSelected[param.itemOrder] : 0
                }

                return (
                    <div className={classes['params-select-container']}>

                        <TermSelect
                            key={paramWithIdSelected.itemOrder}
                            termData={paramWithIdSelected}
                            termChanged={(termData, termId) => handleChange(termData, termId)}
                        />

                    </div>
                )

            })}

        </div>
    );
};