/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import ShowHideLoader from '../../../hooks/showHideLoader';
import LicenseUserService from '../../../service/usersService/licenseUserService';
import { TermSelect } from './TermSelect';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import classNames from 'classnames';



const setStyles = makeStyles((theme) => {
    const themeTitleColor = theme && theme.secondTitle && theme.secondTitle.color ? theme.secondTitle.color : null;
    const themeTitleFont = theme && theme.secondTitle && theme.secondTitle.font ? theme.secondTitle.font : null;

    return {
        box: {
            borderRadius: '5px',
            width: '400px',
            padding: '1em',
            boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
            background: 'rgba(255, 255, 255, .3)',
        },
        title: {
            fontFamily: themeTitleFont,
            color: themeTitleColor,
            fontWeight: '600',

        },
        buttonsContainer: {
            marginTop: '1em!important',
            display: 'flex',
            flexDirection: 'column'
        },
        button: {
            width: '15em',
            borderColor: '#7c7b7b!important',
            color: '#7c7b7b!important',
            alignSelf: 'end',
            '&:hover': {
                backgroundColor: '#6c6c6c0a!important',
                borderColor: '#7c7b7b!important'
            }
        },
        addToBasketContainer: {
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        selectedLicenseText: {
            color: themeTitleColor,
            fontFamily: themeTitleFont,
            fontWeight: 600,
            fontSize: '1.2em',
            textTransform: 'uppercase'
        },
        addToBasketButton: {
            display: 'flex!important',
            justifyContent: 'space-between!important'
        },
        cartImage: {
            width: '24px'
        },
        clickHereContainer: {
            fontSize: '13px',
            marginTop: '.5em'
        },
        spanAsAnchor: {
            cursor: 'pointer!important',
            color: 'blue!important',
            textDecoration: 'underline',
            textDecorationColor: 'blue'
        }
    }
})

export const ConcessionSelect = ({
    addDr,
    outputLicense,
    selectedLicense,
    idDigitalResource
}) => {
    const [t] = useTranslation();
    const theme = useTheme();
    const classes = setStyles(theme);
    const history = useHistory();

    const [itemList, setItemList] = useState([]);
    const [selection, setSelection] = useState({});

    const [showLoader, hideLoader] = ShowHideLoader();

    useEffect(async () => {
        if (isEmpty(itemList)) {
            const items = await
                LicenseUserService.getLicenseTerms(1, {}) || [];
            sortItems(items);
            setItemList(items);
            initSelection(items);
        }
    }, [])


    useEffect(async () => {
        if (!isEmpty(itemList)) {
            showLoader();
            const {
                mustReload,
                lastOrder,
                ...definedTerms
            } = selection;

            let tempItemList = [...itemList]

            for (let a = 0; a < itemList.length; a++) {
                const items = await LicenseUserService.getLicenseTerms(a + 1, definedTerms) || [];
                tempItemList[a] = items[a];
            }
            setItemList(tempItemList);
            hideLoader();

        }
    }, [localStorage.getItem('i18nextLng')])



    useEffect(async () => {
        const {
            mustReload,
            lastOrder,
            ...definedTerms
        } = selection;
        if (isEmpty(definedTerms)) {
            return;
        }
        if (mustReload === false) {
            return;
        }
        showLoader();
        for (const key in definedTerms) {
            if (!isTermSet(definedTerms[key])) {
                delete definedTerms[key];
            }
        }
        let items = null;
        try {
            items = await LicenseUserService.getLicenseTerms((lastOrder || 0) + 1, definedTerms) || [];
            setItemList(items);
            initSelection(items);
            outputLicense(null);
        } catch (err) {
            if (err.data?.data?.noMoreVoc) {
                const selectedLicense = await LicenseUserService.getFee(definedTerms);
                outputLicense(selectedLicense);
            } else {
                console.error(err);
                hideLoader();
                return;
            }
        }
        if (items) {
            const lastItem = items.filter(x => {
                if (lastOrder + 1 === x.itemOrder) {
                    return true;
                } else { return false; }
            })[0];
            if (lastItem) {
                const lastTerms = lastItem.terms || {};
                if (Object.keys(lastTerms).length === 1 && lastItem) {
                    changeTerm(lastItem, Object.keys(lastTerms)[0])
                }
            }
        }
        hideLoader();
    }, [selection]);

    const isOptional = item => !item.hasDependentItems;
    const isTermSet = id => id !== null && id !== undefined;

    const createCR = () => {
        const {
            mustReload,
            lastOrder,
            ...definedTerms
        } = selection;
        const query = [];
        if (idDigitalResource) {
            query.push('idDigitalResource=' + idDigitalResource);
        }
        for (const key in definedTerms) {
            query.push(`${key}=${definedTerms[key]}`)
        }
        let path = '/App/NewCR';
        if (query.length) {
            path += '/?' + query.join('&');
        }
        window.scrollTo(0, 0);
        history.push(path);
    }


    const sortItems = items => {
        items.sort((x, y) => {
            const xOrder = x.itemOrder || 0;
            const yOrder = y.itemOrder || 0;
            if (xOrder < yOrder) {
                return -1;
            }
            if (xOrder > yOrder) {
                return 1;
            }
            return 0;
        });
    };

    const initSelection = items => {
        const selection = {
            mustReload: false
        };
        for (const item of items) {
            const id = item.idSelected;
            if (isTermSet(id)) {
                selection[item.itemOrder] = id;
            }
        }
        setSelection(selection);
    };

    const getSelecteds = () => {
        const optionals = getOptionals();
        return itemList
            .filter(x => optionals.indexOf(x) === -1);
    };

    const getOptionals = () => {
        return itemList
            .filter(x => isOptional(x))
            .filter(x => !(x.itemOrder in selection));
    };

    const changeTerm = (itemData, idTerm) => {
        const data = {
            ...selection,
            mustReload: !!itemData.hasDependentItems ? true : true,
            lastOrder: itemData.itemOrder
        };
        data[itemData.itemOrder] = idTerm;
        setSelection(data);
    };

    return (
        <div className={classes.root}>
            <div className={classes.box}>
                {getSelecteds().map(x => (
                    <>
                        <TermSelect
                            key={x.itemOrder}
                            termData={x}
                            termChanged={changeTerm}
                        />
                    </>
                ))}
                {selectedLicense && selectedLicense.price !== null && (
                    <div className={classes.addToBasketContainer}>
                        <div className={classes.selectedLicenseText}>{t('customRequests.price')}: {selectedLicense.price}€</div>
                        <Button
                            className={classNames(classes.button, classes.addToBasketButton)}
                            variant="outlined"
                            onClick={() => addDr()}
                            startIcon={<img alt="Add to basket icon"
                                className={classes.cartImage}
                                src="/images/navBar/shopping-cart.png" />}
                        >
                            {t('basket.addtobasket')}
                        </Button>
                    </div>
                )}
            </div>
            <div className={classes.clickHereContainer}>
                {t('dr.gotocustomrequest')}?&nbsp;
                {/* WTC href -> Script URL is a form of eval */}
                <span className={classes.spanAsAnchor} onClick={() => createCR()}>{t('dr.clickhere')}</span> {/** TODO CREATE CR */}
                &nbsp;{t('dr.gotocustomrequestsecond')}
            </div>
        </div>
    );
};
