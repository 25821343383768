export const EMPTY_ORDER = {
  idOrder: null,
  idUser : null,
  idBasket: null,
  orderDate: null,
  totalPrice: null,
  orderState: null, 
}

export const DEFAULTS_VALUES = {

}






