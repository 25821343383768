import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { FormControlLabel, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';

const setStyles = makeStyles((theme) => {
    const themeTitleColor = theme && theme.secondTitle && theme.secondTitle.color ? theme.secondTitle.color : null;
    const themeTitleFont = theme && theme.secondTitle && theme.secondTitle.font ? theme.secondTitle.font : null;
    return {
        root: {
            width: 'calc(100% - 40px)',
            margin: '0 20px',
            fontFamily: 'Roboto',
        },
        subHeader: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        subtitle: {
            fontSize: '14px!important',
            fontWeight: 500,
            color: themeTitleColor,
            fontFamily: themeTitleFont,
        },
        textarea: {
            width: 'calc(100% - 2.1em)',
            height: '28em',
            resize: 'none!Important',
            padding: '1em',
            borderRadius: '5px',
            '&:focus': {
                outline: 'none',
            }
        },
        imgInfo: {
            fontSize: '12px',
            fontStyle: 'italc',
            color: '#707070',
            display: 'flex',
            justifyContent: 'flex-end',
        }
    }
})

/**
 * Componente di dx con la textarea della descrizione e il check "rinnovo concessione"
 */
export default function RequestDescription({
    showRenewConcession,
    onParametersChanged
}) {
    const classes = setStyles();
    const [description, setDescription] = useState('');
    const [renewConcession, setRenewConcession] = useState(false);
    const [t] = useTranslation();

    useEffect(() => {
        if (typeof onParametersChanged === 'function') {
            onParametersChanged({ description, renewConcession });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [description, renewConcession]);

    return (
        <div className={classes.root}>
            <div className={classes.subHeader}>
                <p className={classes.subtitle}>{t('requestdescription.messagefornewcustomrequest')}:</p>
                {showRenewConcession && (
                    <FormControlLabel
                        value="start"
                        control={<Switch color="primary" checked={renewConcession} onChange={evt => setRenewConcession(evt.target.checked)} />}
                        label="Rinnovo concessione:"
                        labelPlacement="start"
                        sx={{
                            '& .MuiTypography-root': {
                                fontSize: '13px',
                                color: '#707070'
                            }
                        }}
                    />
                )}
            </div>
            <textarea
                className={classes.textarea}
                value={description}
                maxLength={3000}
                onChange={evt => setDescription(evt.target.value)}
            />
            <div className={classes.imgInfo}>3000 {t('message.charactersavailable')}</div>
        </div>
    );
};