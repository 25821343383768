import request from "../base/baseService";
import { constants } from "../../config/config";
import GlobalDataStore from "../../GlobalDataStore";

let url = `${constants.api.url}api/licenseuser`;

const getUsage = () => {
    return request({
        url: `${url}/getusage/${GlobalDataStore.getTenantId()}`,
        method: 'POST'
    })
}

const getMedium = (license) => {
    return request({
        url: `${url}/getmedium/${GlobalDataStore.getTenantId()}`,
        method: 'POST',
        data: license
    })
}

const getTypology = (license) => {
    return request({
        url: `${url}/gettypology/${GlobalDataStore.getTenantId()}`,
        method: 'POST',
        data: license
    })
}

const getDistributionFormat = (license) => {
    return request({
        url: `${url}/getdistributionformat/${GlobalDataStore.getTenantId()}`,
        method: 'POST',
        data: license
    })
}

const getPlacement = (license) => {
    return request({
        url: `${url}/getplacement/${GlobalDataStore.getTenantId()}`,
        method: 'POST',
        data: license
    })
}

const getSize = (license) => {
    return request({
        url: `${url}/getsize/${GlobalDataStore.getTenantId()}`,
        method: 'POST',
        data: license
    })
}

const getQuantity = (license) => {
    return request({
        url: `${url}/getquantity/${GlobalDataStore.getTenantId()}`,
        method: 'POST',
        data: license
    })
}

const getDuration = (license) => {
    return request({
        url: `${url}/getduration/${GlobalDataStore.getTenantId()}`,
        method: 'POST',
        data: license
    })
}
const getRegion = (license) => {
    return request({
        url: `${url}/getregion/${GlobalDataStore.getTenantId()}`,
        method: 'POST',
        data: license
    })
}

const getLanguage = (license) => {
    return request({
        url: `${url}/getlanguage/${GlobalDataStore.getTenantId()}`,
        method: 'POST',
        data: license
    })
}

const getFee = (license) => {
    return request({
        url: `${url}/getfee/${GlobalDataStore.getTenantId()}`,
        method: 'POST',
        data: license
    })
}
const getLicenseTerms = (idOrder, requestBody) => {
    return request({
        url: `${url}/getselectableterm/${GlobalDataStore.getTenantId()}/${idOrder}`,
        method: 'POST',
        data: requestBody
    })
}



const getTermsCustomRequest = (selectedTerms) => {
    return request({
        url: `${url}/getselectabletermforcustomreuquest`,
        method: 'POST',
        data: selectedTerms || {}
    })
}

export const LicenseUserService = {
    getTermsCustomRequest,
    getUsage,
    getMedium,
    getTypology,
    getDistributionFormat,
    getPlacement,
    getSize,
    getQuantity,
    getDuration,
    getLanguage,
    getRegion,
    getFee,
    getLicenseTerms
}

export default LicenseUserService;