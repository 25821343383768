import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { AiOutlineClose } from 'react-icons/ai';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { FaEye } from 'react-icons/fa';
import addDays from 'date-fns/addDays';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import CustomRequestService from '../../../../../../service/adminServices/customRequestService';
import { formatDate } from '../../../../../../commons/formatData';
import ConcessionPreviewModal from './ConcessionPreviewModal';
import ShowHideLoader from '../../../../../../hooks/showHideLoader';
import CurrencyInput from 'react-currency-input-field';
import { useTranslation } from 'react-i18next';


const setStyles = makeStyles(() => ({
  modalContainer: {
    width: '850px',
    maxHeight: '700px',
  },
  modalBody: {
    padding: '1.5em !important'
  },
  dateSelectionContainer: {
    display: 'flex',
    alignItems: 'baseline',
    fontWeight: 600,

  },
  inputName: {
    marginRight: '18px',
    marginBottom: '0',
    fontWeight: '500',
    // eslint-disable-next-line no-dupe-keys
    marginBottom: '0',
  },
  width100px: {
    width: '100px'
  },
  datePickerInput: {
    border: 'none',
    width: '5em',
    '&:focus': {
      outline: 'none!important'
    }
  },
  greyContainer: {
    marginTop: '1.5em',
    width: '770px',
    padding: '1.5em 1em',
    backgroundColor: '#f5f5f5',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: '2.27em',
    borderRadius: '5px',
    '& p': {
      margin: 0,
      fontWeight: 600
    }
  },
  itemContainer: {
    width: '100%',
    display: 'flex',
    fontFamily: 'Roboto !important',
    '& #title': {
      maxWidth: '400px',
      margin: 0,
      fontWeight: 600,
      left: '0.7%',
      width: '50%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      wordBreak: 'break-word',

    },
    '& #title:hover': {
      minWidth: '50%',
      paddingRight: '10px',
      width: 'auto',
      overflow: 'visible',
      zIndex: '100',
      background: '#f5f5f5',
      borderRadius: '5px',
      boxShadow: '0px 5px 4px -3px rgb(0 0 0 / 20%), 0px 6px 8px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
      paddingLeft: '1%',
      display: '-webkit-box',
      '-webkit-line-clamp': '3',
      '-webkit-box-orient': 'vertical',
      whiteSpace: 'break-spaces'
    },
    '& #date': {
      margin: 0,
      fontWeight: 600,
      position: 'absolute',
      left: '55%'
    },
    '& #price_lable': {
      margin: 0,
      fontWeight: 600,
      position: 'absolute',
      left: '75%'
    },
    '& #price': {
      margin: 0,
      fontWeight: 600,
      position: 'absolute',
      right: '2%'
    }

  },
  selectContainer: {
    marginTop: '1.5em',
    width: '100%',
    padding: '1.5em 0em',
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'Roboto',
  },
  select: {
    fontFamily: 'Roboto !important',
    '& div': {
      border: 'none',
      padding: '12px !important',
      fontFamily: 'Roboto !important',
    }
  },
  notchedOutline: {
    borderColor: 'pink'
  },
  eyeIcon: {
    width: '24px',
    height: '24px',
    cursor: 'pointer'
  },
  // USE THIS CLASS WHEN THE EYE ICON IS ACTIVE
  eyeIconBlue: {
    color: '#4A90E2',
  },
  inputText: {
    background: 'transparent',
    border: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    width: '500px',
    fontSize: '14px',
    '&:focus': {
      outline: 'none',
    }
  },
  inputPrice: {
    width: '180px',
    textAlign: 'right',
    paddingRight: '16px',
    '-webkit-appearance': ' none',
  },
  eur: {
    position: 'relative',
    right: '12px'
  },
  checkbox: {
    height: '16px',
    width: '16px',
    margin: '0 20px 0 90px',
    position: 'relative',
    top: '4px'
  },
  quotationContainer: {
    width: ' calc(100% - 70px)',
    padding: '10px 35px',
    flexWrap: 'wrap',
    background: '#F5F5F5',
    marginTop: '18px',
    borderRadius: '8px',
    '& p': {
      margin: 0,
      marginBottom: '.5em'
    }
  },
  modalFooter: {
    paddingBottom: '1.5em !important'
  },
  divDRField: {
    // marginLeft: '20px',
    height: '30px',
    margin: '8px 0',
    display: 'flex',
    alignItems: 'flex-end'
  },
  rateContainer: {
    height: '30px',
    margin: '8px 0',
    display: 'flex',
    alignItems: 'flex-end',
  }
}));

const CreateNewQuotationModal = ({
  showModal,
  onCloseModal,
  onApplyQuotation,
  customizedQuotationData,
  idDigitalResource,
  renewConcession
}) => {
  const classes = setStyles();
  const [quotation, setQuotation] = useState({ ...customizedQuotationData });
  const [concessions, setConcessions] = useState([]);
  const [viewConcession, setViewConcession] = useState(false);
  const [showLoader, hideLoader] = ShowHideLoader();
  const [t] = useTranslation();


  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    try {
      showLoader();
      setConcessions(await CustomRequestService.getConcessions(
        customizedQuotationData.idConcession
          ? [customizedQuotationData.idConcession]
          : null
      ));
      if (renewConcession) {
        forceFree();
      }
    } finally {
      hideLoader();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const forceFree = () => {
    quotation.isFree = true;
    quotation.economicValue = 0;
    setQuotation({ ...quotation });
  };

  const toggleFree = () => {
    // eslint-disable-next-line no-cond-assign
    if (quotation.isFree = !quotation.isFree) {
      quotation.economicValue = 0;
    }
    setQuotation({ ...quotation });
  };

  const setPrice = price => {
    setQuotation({
      ...quotation,
      economicValue: parseFloat(price || 0).toFixed(2)
    });
  };

  const setExpiration = date => {
    setQuotation({
      ...quotation,
      expiresAt: date
    });
  };

  const setConcession = id => {
    id = parseInt(id);
    setQuotation({
      ...quotation,
      idConcession: !id ? null : id,
      licensePrice: concessions
        .find(x => x.idConcession === id)
        ?.price || 0
    });
  };

  const setDRTitle = title => {
    setQuotation({
      ...quotation,
      drTitle: title
    });
  };

  const setDRDescr = descr => {
    setQuotation({
      ...quotation,
      drDescr: descr
    });
  };

  const setDRInvNbr = invNbr => {
    setQuotation({
      ...quotation,
      drInvNbr: invNbr
    });
  };

  const canApply = () => {
    if (!quotation.expiresAt) {
      return false;
    }
    if (!quotation.idConcession) {
      return false;
    }
    if (!quotation.isFree && !quotation.economicValue) {
      return false;
    }
    if (!idDigitalResource) {
      if (!quotation.drTitle?.trim()) {
        return false;
      }
      if (!quotation.drDescr?.trim()) {
        return false;
      }
    }
    return true;
  };

  const formatConcession = id => {
    id = id || quotation.idConcession;
    const concession = concessions.find(x => x.idConcession === id);
    return (
      <>
        {concession && (
          <>
            <p id='title'>{concession.licenseTitle}</p>
            <p id='date'>{formatDate(concession.creationDate, '', false)}</p>
            <p id='price_lable'>{t('customRequest.price')}: </p>
            <p id='price'>{parseFloat(concession.price || 0).toFixed(2)}€</p>
          </>
        )}
      </>
    );
  };

  return (
    <Modal
      open={showModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      id='newQuotationModal'
    >

      <Box className={`admin-modal-container ${classes.modalContainer}`} >
        {/* TODO close modal clicking out of modal */}
        {/* DON'T USE OutsideClickHandler TO AVOID CONFLICTS WITH DATEPICKER */}
        {/* <OutsideClickHandler onOutsideClick={() => onCloseModal()}> */}
        <div className='admin-modal-header' >
          <span>{t('customRequest.newQuotation')}</span>
          <AiOutlineClose className='admin-modal-headerClose' onClick={onCloseModal} />
        </div>
        <div className={classes.modalBody}>
          <div className={classes.dateSelectionContainer}>
            <label className={classes.inputName}>{t('customRequest.expirationDate')}*:</label>
            <DesktopDatePicker
              inputFormat="dd/MM/yyyy"
              value={quotation.expiresAt}
              minDate={addDays(new Date(), 1)}
              onChange={setExpiration}
              renderInput={({ inputRef, inputProps, InputProps }) =>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <input ref={inputRef} {...inputProps}
                    className={classes.datePickerInput}
                    style={{ width: '80px' }} />
                  {InputProps?.endAdornment}
                </Box>}
            />
          </div>

          {!idDigitalResource && (
            <div className={classes.quotationContainer}>
              <p className={classes.inputName}>{t('createNewQuotationModal.containerDRTitle')}:</p>
              <div className={classes.divDRField}>
                <span className={classNames(classes.inputName, classes.width100px)}>{t('customrequest.default.title')}*:</span>
                <input type='text'
                  className={classes.inputText}
                  maxLength='500'
                  value={quotation && quotation.drTitle ? quotation.drTitle : ''}
                  onChange={evt => setDRTitle(evt.target.value)}
                />
              </div>
              <div className={classes.divDRField}>
                <span className={classNames(classes.inputName, classes.width100px)}>{t('customRequest.drDescription')}*:</span>
                <input type='text'
                  className={classes.inputText}
                  maxLength='500'
                  value={quotation && quotation.drDescr ? quotation.drDescr : ''}
                  onChange={evt => setDRDescr(evt.target.value)}
                />
              </div>
              <div className={classes.divDRField}>
                <span className={classNames(classes.inputName, classes.width100px)}>{t('customRequest.inventory')}:</span>
                <input type='text'
                  className={classes.inputText}
                  maxLength='50'
                  value={quotation && quotation.drInvNbr ? quotation.drInvNbr : ''}
                  onChange={evt => setDRInvNbr(evt.target.value)}
                />
                {/* <span className={classes.inputName}>&nbsp;(opzionale)</span> */}
              </div>
            </div>
          )}

          {quotation.idConcession && (
            <div className={classes.greyContainer}>
              {formatConcession()}
              <FaEye
                className={classes.eyeIcon}
                onClick={() => setViewConcession(true)}>
              </FaEye>
              {viewConcession && (
                <ConcessionPreviewModal
                  showModal={viewConcession}
                  onCloseModal={() => setViewConcession(false)}
                  idConcession={quotation.idConcession}
                  classes={classes}>
                </ConcessionPreviewModal>
              )}
            </div>
          )}
          {!quotation.idConcession && (
            <div className={classes.selectContainer}>
              <Select
                className={classes.select}
                style={{ width: '100%', padding: '0px' }}
                value={quotation.idConcession || 0}
                onChange={evt => setConcession(evt.target.value)}
              >
                <MenuItem className={classes.select} value='0'>
                  <em>{t('customRequest.noConcession')}</em>
                </MenuItem>
                {concessions.map(x => (
                  <MenuItem key={x.idConcession} value={x.idConcession}>
                    <div className={classes.itemContainer}>
                      {formatConcession(x.idConcession)}
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
          <div className={classes.quotationContainer}>
            <p className={classes.inputName}>{t('customRequest.acquisitionCost')}*:</p>
            <div className={classes.rateContainer}>
              <span className={classes.inputName}>{t('customRequest.inputPrice')}:</span>

              <span>
                <CurrencyInput
                  id="input-price"
                  className={classNames(classes.inputText, classes.inputPrice)}
                  placeholder="0.00"
                  decimalSeparator="."
                  groupSeparator=" "
                  allowNegativeValue={false}
                  disabled={quotation.isFree}
                  defaultValue={parseFloat(quotation.economicValue || 0).toFixed(2)}
                  value={quotation.isFree ? parseFloat(0).toFixed(2) : undefined}
                  decimalsLimit={2}
                  onValueChange={value => setPrice(value)}
                />
                <span className={classes.eur}>€</span>
              </span>

              <span>
                <input type='checkbox'
                  className={classes.checkbox}
                  checked={quotation.isFree}
                  onChange={toggleFree}
                  disabled={renewConcession}
                />
                <span>
                  {t('customRequest.free') + (renewConcession ? ` (${t('customRequest.renewal')})` : '')}
                </span>
              </span>
            </div>
          </div>
        </div>

        <div className={`admin-modal-footer ${classes.modalFooter}`}>
          <div className='admin-modal-btnCancel'
            onClick={onCloseModal}>
            {t('customRequest.btnCancel')}
          </div>
          <div className='admin-modal-btnApply'
            style={canApply() ? {} : { pointerEvents: "none", opacity: "0.4" }}
            onClick={() => { onApplyQuotation(quotation); onCloseModal(); }}>
            {t('customRequest.btnApply')}
          </div>
        </div>
        {/* </OutsideClickHandler> */}
      </Box>
    </Modal>
  );
};

export default CreateNewQuotationModal;
