import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import OutsideClickHandler from 'react-outside-click-handler';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const setStyles = makeStyles(() => ({
    modalContainer: {
        width: '500px'
    },
}));

function MessageModal({
    showModal,
    title,
    modalText,
    btnClose,
    btnConfirm,
    //Output
    closeModal,
    confirmMessage,
}) {

    const [t] = useTranslation();
    const classes = setStyles();

    return (
        <>
            <Modal
                open={showModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                centered="true">
                <Box className={`admin-modal-container ${classes.modalContainer}`} >
                    <OutsideClickHandler onOutsideClick={() => closeModal()}>
                        <div className='admin-modal-header' >
                            <span>{title ? title : ''}</span>
                        </div>

                        <div className='admin-modal-body'>
                            <span>{modalText ? modalText : t('messagemodal.succesfully')}</span>
                        </div>

                        <div className='admin-modal-footer'>
                            <div className={btnConfirm ? 'admin-modal-btnCancel' : 'admin-modal-btnApply'} onClick={() => closeModal()}>{btnClose ? btnClose : t('messagemodal.close')}</div>
                            {btnConfirm && (
                                <div className='admin-modal-btnApply' onClick={() => confirmMessage()}>{btnConfirm}</div>
                            )}
                        </div>
                    </OutsideClickHandler>
                </Box>
            </Modal>
        </>
    )
}

MessageModal.propTypes = {
    showModal: PropTypes.bool,
    title: PropTypes.string,
    modalText: PropTypes.string,
    btnClose: PropTypes.string,
    btnConfirm: PropTypes.string,
    //Output
    closeModal: PropTypes.func,
    confirmMessage: PropTypes.func,
}

export default MessageModal;

