import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import OutsideClickHandler from 'react-outside-click-handler';
import { isArray } from 'lodash';
import { useTranslation } from 'react-i18next';

const setStyles = makeStyles(() => ({
    modalContainer: {
        width: '500px'
    },
}));

function ErrorModal({
    title,
    errorFields,
    //Output
    closeModal,
}) {

    const [t] = useTranslation();
    const classes = setStyles();

    return (
        <>
            <OutsideClickHandler onOutsideClick={() => closeModal()}>
                <Modal
                    open={true}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    centered="true">
                    <Box className={`admin-modal-container ${classes.modalContainer}`} >
                        <div className='admin-modal-header' >
                            <span>{title ? title : t('modals.errorModal.title')}</span>
                        </div>

                        <div className='admin-modal-body'>
                            <div className={classes.bodyTitle}>{t('modals.errorModal.bodyTitle')}:</div>
                            {isArray(errorFields) && errorFields?.length ? (
                                <ul>
                                    {errorFields.map((item, idx) =>
                                        <li className={classes.listItem} key={idx}>{item}</li>
                                    )}
                                </ul>
                            ) : (
                                <ul>
                                    <li className={classes.listItem}>{errorFields}</li>
                                </ul>

                            )}

                        </div>

                        <div className='admin-modal-footer'>
                            <div className='admin-modal-btnApply' onClick={() => closeModal()}>{t('modals.errorModal.btnClose')}</div>
                        </div>
                    </Box>
                </Modal>
            </OutsideClickHandler>
        </>
    )
}

ErrorModal.propTypes = {
    showModal: PropTypes.bool,
    title: PropTypes.string,
    errorFields: PropTypes.array,
    //Output
    closeModal: PropTypes.func,
}

export default ErrorModal;

