import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function BtnBackHome({
    classes,
}) {
    const [t] = useTranslation();
    const history = useHistory();

    const returnToHome = () => {
        history.push('/App')
    }

    return (
        <>
            <div className={classes.backToHome}>
                <span onClick={() => returnToHome()}>
                    {t('home.returnhome')}
                </span>
            </div>
        </>
    )
}


BtnBackHome.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default BtnBackHome;
