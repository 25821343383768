/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { PaymentsService } from '../../service/paymentsService';
import { useHistory } from 'react-router-dom';
import ShowHideLoader from '../../hooks/showHideLoader';
import { GrClose } from 'react-icons/gr';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { GiSandsOfTime } from 'react-icons/gi';
import { AiOutlineCheck } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

const setStyles = makeStyles((theme) => {
    const themeBackground = theme && theme.background ? theme.background : null;
    const themeTitle = theme && theme.firstTitle && theme.firstTitle.color ? theme.firstTitle.color : null;
    const themeTitleFont = theme && theme.firstTitle && theme.firstTitle.font ? theme.firstTitle.font : null;
    return {
        root: {
            width: '100vw',
            height: '100vh',
            background: themeBackground,
        },
        displayFlex: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
        },
        messageContainer: {
            width: '100%',
            paddingTop: '80px',
            maxHeight: '180px',
        },
        iconContainer: {
            width: '90px',
            height: '90px',
            border: '2px solid',
            borderColor: themeTitle,
            borderRadius: '50%',
        },
        icon: {
            width: '50px',
            height: '50px',
        },
        message: {
            width: '100%',
            marginTop: '24px',
            marginBottom: '12px',
            fontFamily: themeTitleFont,
            fontSize: '24px',
            color: themeTitle,
        },
        btnContainer: {
            marginTop: '50px'
        }
    }
});

function NexiFeedback(props) {
    const [t] = useTranslation();
    const msgChecking = t('nexiFeedback.checkingPayment');
    const msgAborted = t('nexiFeedback.paymentAborted');
    const msgSuccess = t('nexiFeedback.paymentSucceded');
    const msgRefused = t('nexiFeedback.paymentRefused');
    const msgError = t('nexiFeedback.cannotCheckPayment');
    const classes = setStyles();
    const history = useHistory();
    const [showLoader, hideLoader] = ShowHideLoader();
    const [message, setMessage] = useState(msgChecking);
    const [finished, setFinished] = useState(false);

    const backOrders = () => history.push('/App/Orders');


    useEffect(async () => {
        const query = props.location.search;
        try {
            showLoader();
            await PaymentsService.nexiCheckPaymentResult(query);
            setMessage(msgSuccess);
        } catch (ex) {
            if (ex.status === 400) {
                console.warn(ex);
                const esito = new URLSearchParams(query).get('esito');
                if (esito === 'ANNULLO') {
                    setMessage(msgAborted);
                } else {
                    setMessage(msgRefused);
                }
            } else {
                console.error(ex);
                setMessage(msgError);
            }
        } finally {
            setFinished(true);
            hideLoader();
        }
    }, []);

    return (
        <>
            <div id='nexiFeedback' className={classes.root}>

                <div className={classNames(classes.displayFlex, classes.messageContainer)}>
                    <div className={classNames(classes.displayFlex, classes.iconContainer)}>
                        {message === msgChecking && (
                            <GiSandsOfTime className={classes.icon} />
                        )}

                        {message === msgSuccess && (
                            <AiOutlineCheck className={classes.icon} />
                        )}

                        {(message === msgRefused || message === msgError || message === msgAborted) && (
                            <GrClose className={classes.icon} />
                        )}

                    </div>

                    <div className={classNames(classes.displayFlex, classes.message)}>
                        {message}
                    </div>


                </div>
                {finished &&
                    <div className={classes.btnContainer}>
                        <center>
                            <input
                                type="button"
                                onClick={backOrders}
                                className={'rl-button'}
                                value={t('nexiFeedback.backToOrders')} />
                        </center>
                    </div>
                }
            </div>
        </>
    );
}

export default NexiFeedback;
