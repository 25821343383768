/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import ShowHideLoader from '../../hooks/showHideLoader';
import { PaymentsService } from '../../service/paymentsService';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { GrClose } from 'react-icons/gr';
import { useTranslation } from 'react-i18next';

const setStyles = makeStyles((theme) => {
    const themeBackground = theme && theme.background ? theme.background : null;
    const themeTitle = theme && theme.firstTitle && theme.firstTitle.color ? theme.firstTitle.color : null;
    const themeTitleFont = theme && theme.firstTitle && theme.firstTitle.font ? theme.firstTitle.font : null;
    return {
        root: {
            width: '100vw',
            height: '100vh',
            background: themeBackground,
        },
        displayFlex: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
        },
        messageContainer: {
            width: '100%',
            paddingTop: '80px',
            maxHeight: '180px',
        },
        iconContainer: {
            width: '90px',
            height: '90px',
            border: '2px solid',
            borderColor: themeTitle,
            borderRadius: '50%',
        },
        icon: {
            width: '50px',
            height: '50px',
        },
        message: {
            width: '100%',
            marginTop: '24px',
            marginBottom: '12px',
            fontFamily: themeTitleFont,
            fontSize: '24px',
            color: themeTitle,
        },
        btnContainer: {
            marginTop: '50px'
        }
    }
});

function PagoPaPay() {
    const [t] = useTranslation();
    const msgRedirect = t('pagoPaPay.redirectingToPagoPa');
    const msgPaid = t('pagoPaPay.orderAlreadyPaid');
    const msgPaying = t('pagoPaPay.payingOrderNow');
    const msgError = t('pagoPaPay.cannotStartPayment');
    const msgUnavailable = t('pagoPaPay.serviceUnavailable');
    const classes = setStyles();
    const { idOrder } = useParams();
    const history = useHistory();
    const [showLoader, hideLoader] = ShowHideLoader();
    const [message, setMessage] = useState(msgRedirect);
    const [back, setBack] = useState(false);

    const backOrders = () => history.push('/App/Orders');


    useEffect(async () => {
        try {
            showLoader();
            const status = await PaymentsService.hasOrderBeenPaidByIdOrder(idOrder);
            if (status === PaymentsService.ORDER_PAID_STATUS.PAID_OK) {
                setMessage(msgPaid);
                setBack(true);
            } else if (status === PaymentsService.ORDER_PAID_STATUS.PAYING) {
                setMessage(msgPaying);
                setBack(true);
            } else {
                const url = await PaymentsService.pagoPaStartPayment(idOrder);
                window.location.href = url;
            }
        } catch (ex) {
            console.error(ex);
            if (ex.data?.data?.serviceUnavailable) {
                setMessage(msgUnavailable);
            } else {
                setMessage(msgError);
            }
            setBack(true);
        } finally {
            hideLoader();
        }
    }, []);

    return (
        <>
            <div id='pagoPaPay' className={classes.root}>

                <div className={classNames(classes.displayFlex, classes.messageContainer)}>
                    <div className={classNames(classes.displayFlex, classes.iconContainer)}>
                        {message === msgRedirect ? (
                            <img alt='' src='/images/wallet.png' className={classes.icon} />
                        ) : (
                            <GrClose className={classes.icon} />

                        )}
                    </div>

                    <div className={classNames(classes.displayFlex, classes.message)}>
                        {message}
                    </div>


                </div>


                {back &&
                    <div className={classes.btnContainer}>
                        <center>
                            <input
                                type="button"
                                onClick={backOrders}
                                className={'rl-button'}
                                value={t('pagoPaPay.backToOrders')} />
                        </center>
                    </div>
                }
            </div>
        </>
    );
}

export default PagoPaPay;
