import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import CustomRequestItemAdmin from './CustomRequestItemAdmin';
import { useTranslation } from 'react-i18next';

const setStyles = makeStyles(() => ({
    root: {
        width: 'calc(100% - 6px)',
        display: 'inline-block',
        fontFamily: "Roboto",
    },
    tableHeader: {
        height: '24px',
        border: '1px solid',
        background: '#F5F5F5',
        display: 'flex',
        marginBottom: '6px',
    },
    headerCell: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '10px',
        textTransform: 'uppercase',
        borderRight: '1px solid',
        '&:last-child': {
            borderRight: 'none',
        },
        '& p': {
            margin: '0',
            padding: '0px 20px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    },
    header_status: {
        justifyContent: 'center',
        width: '180px',
        cursor: 'pointer',
    },
    flex: {
        display: 'flex',
        width: 'calc(100% - 180px)',
    },
    header_large: {
        width: 'calc(200% /15)',
    },
    header_small: {
        width: 'calc(100% /15)',
    },

    header_100px: {
        justifyContent: 'center',
        width: '200px',
        cursor: 'pointer',
    },
    tableBody: {
        width: '100%',
        overflowY: 'auto',
    }
}));

function CustomRequestsTableAdmin({
    crsList,
    visibleColumns,
}) {
    const classes = setStyles();
    const [items, setItems] = useState(null);
    const [t] = useTranslation();

    useEffect(() => setItems(crsList), [crsList]);

    const calcMaxHeight = () => {
        let containerArea = document.getElementById('customRequestFilterAdmin')
        let valContainerArea = containerArea ? containerArea.getBoundingClientRect().height : 0;
        return (170 + valContainerArea);
    }


    //calcola dimensione delle colonne: per quelle più larghe number arriva = 200, per quelle più strette number = 100;
    const calculateSize = (number) => {
        let index = 0;

        visibleColumns && visibleColumns.forEach(colmumn => {
            if (colmumn === 'customRequests.price') {
                index = index + 2
            } else {
                index = index + 3;
            }
        })
        return `calc(${number + 100}% / ${visibleColumns ? (index) : 23})`

    }

    const showColumn = column => visibleColumns.indexOf(column) !== -1;


    return (
        <>
            <div className={classes.root}>
                <div className={classes.tableHeader}>

                    <div className={classNames(classes.headerCell, classes.header_status)}>
                        <p>{t('customRequests.state')} </p>
                    </div>

                    <div className={classes.flex}>

                        {showColumn('customRequests.date') && (
                            <div className={classes.headerCell} style={{ width: calculateSize(200) }}>
                                <p> {t('customRequests.date')}</p>
                            </div>
                        )}

                        {showColumn('customRequests.user') && (
                            <div className={classes.headerCell} style={{ width: calculateSize(200) }}>
                                <p>{t('customRequests.user')}</p>
                            </div>
                        )}

                        {showColumn('customRequests.mail') && (
                            <div className={classes.headerCell} style={{ width: calculateSize(200) }}>
                                <p> {t('customRequests.mail')}</p>
                            </div>
                        )}

                        {showColumn('customRequests.entity') && (
                            <div className={classes.headerCell} style={{ width: calculateSize(200) }}>
                                <p>{t('customRequests.entity')}</p>
                            </div>
                        )}

                        {showColumn('customRequests.inventory') && (
                            <div className={classes.headerCell} style={{ width: calculateSize(200) }}>
                                <p>{t('customRequests.inventory')}</p>
                            </div>
                        )}

                        {showColumn('customRequests.description') && (
                            <div className={classes.headerCell} style={{ width: calculateSize(200) }}>
                                <p>{t('customRequests.description')}</p>
                            </div>
                        )}

                        {showColumn('customRequests.usage') && (
                            <div className={classes.headerCell} style={{ width: calculateSize(200) }}>
                                <p>{t('customRequests.usage')}</p>
                            </div>
                        )}

                        {showColumn('customRequests.price') && (
                            <div className={classes.headerCell} style={{ width: calculateSize(100), justifyContent: 'center' }}>
                                <p>{t('customRequests.price')}</p>
                            </div>
                        )}

                    </div>
                </div>

                <div className={classes.tableBody} style={{ maxHeight: `calc(100vh - ${calcMaxHeight()}px)` }}>
                    {!!items?.length && (items.map(item => (
                        <CustomRequestItemAdmin
                            key={item.idCustomRequest}
                            classes={classes}
                            item={item}
                            visibleColumns={visibleColumns}
                        />
                    )))}
                </div>
            </div>
        </>
    )
};

CustomRequestsTableAdmin.propTypes = {
    crsList: PropTypes.array,
    visibleColumns: PropTypes.array,
};

export default CustomRequestsTableAdmin;