import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import Button from '@mui/material/Button';
import { TextareaAutosize } from '@mui/material';
import { HiOutlineLink } from 'react-icons/hi';
import CreateNewQuotationModal from './CreateNewQuotationModal';
import { FaPencilAlt } from 'react-icons/fa';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { formatDate } from '../../../../../../commons/formatData';
import { useTranslation } from "react-i18next";

const setStyles = makeStyles(() => ({
  header: {
    padding: '.7em 2em',
    justifyContent: 'end!important'
  },
  sendButton: {
    width: '10em',
    alignSelf: 'end',
    fontSize: '12px!important'
  },
  // messageAreaContainer: {
  //   width: '100%'
  // },
  paragraphBeforeTextarea: {
    marginBottom: '.5em!important',
    color: '#9d9d9d'
  },
  textArea: {
    boxSizing: 'border-box !important',
    borderRadius: '5px',
    border: '1px solid #9d9d9d',
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: '8em',
    height: '8em',
    maxHeight: '8em',
    overflow: 'auto',
    '&:focus': {
      outline: 'none',
    }
  },
  linkImageContainer: {
    display: 'flex',
    alignItems: 'end',
    borderBottom: '1px solid #9d9d9d',
    justifyContent: 'space-between',
    marginTop: '2em',
    '& input': {
      border: 'none',
      width: '100%',
      '&:focus': {
        outline: 'none',
      }
    },
    '& svg': {
      marginBottom: '.1em'
    }
  },
  buttonsContainer: {
    marginTop: '2em',
    display: 'flex'
  },
  createNewQuotationButton: {
    width: '24em',
    padding: '.3em 1em !important',
    fontSize: '12px!important',
    marginRight: '1em!important'

  },
  rejectRequestButton: {
    width: '15em',
    padding: '.3em 1em !important',
    border: '1px solid #e7004e!important',
    fontSize: '12px!important'
  },
  greyContainer: {
    marginTop: '1.5em',
    boxSizing: 'border-box !important',
    width: '100%',
    padding: '1.5em .5em',
    backgroundColor: '#f5f5f5',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& p': {
      margin: 0,
      fontWeight: 600,
      fontSize: '12px'
    }
  },
  quotationName: {
    color: '#e7004e'
  },
  iconsContainer: {
    fontSize: '20px',
    display: 'flex',
    justifyContent: 'space-around',
    width: '4em'
  },
  deleteIcon: {
    color: '#e7004e',
    fontSize: '25px',
    cursor: 'pointer'
  },
  modifyIcon: {
    cursor: 'pointer'
  },
  imgInfo: {
    fontSize: '0.7em',
    fontStyle: 'italc'
  }
}));

const AdminReplyMessage = ({
  classes,
  onSendMessage,
  idDigitalResource,
  renewConcession
}) => {
  const resetCustomizedQuotation = () => ({
    isSet: false,
    isFree: false,
    economicValue: 0,
    expiresAt: null,
    drTitle: null,
    drDescr: null,
    drInvNbr: null
  });

  const internalClasses = setStyles();
  const [openCreateNewQuotationModal, setOpenCreateNewQuotationModal] = useState(false);
  const [customizedQuotationData, setCustomizedQuotationData] = useState(resetCustomizedQuotation);
  const [message, setMessage] = useState();
  const [url, setUrl] = useState();

  const [t] = useTranslation();

  const canSend = () => {
    if (!message?.trim()) {
      return false;
    }
    if (url) {
      try {
        const protocol = new URL(url).protocol.toLowerCase();
        if (protocol !== "http:" && protocol !== "https:") {
          return false;
        }
      } catch {
        return false;
      }
    }
    return true;
  };

  const sendMessage = state => {
    onSendMessage({
      stateLabel: state,
      messageText: message,
      imageUrl: url,
      idMuseumDefinedLicense: customizedQuotationData.idConcession,
      toBePaidBefore: customizedQuotationSet()
        ? customizedQuotationData.expiresAt
        : null,
      productionCost: customizedQuotationSet()
        ? {
          isFree: customizedQuotationData.isFree,
          economicValue: customizedQuotationData.economicValue
        }
        : null,
      digitalResourceTitle: customizedQuotationData.drTitle,
      digitalResourceDescr: customizedQuotationData.drDescr,
      digitalResourceInventoryNumber: customizedQuotationData.drInvNbr,
    });
  };

  const customizedQuotationSet = () => !!customizedQuotationData?.isSet;

  const getAcquisitionPrice = () => {
    if (customizedQuotationSet()) {
      if (customizedQuotationData.isFree) {
        return t('customRequest.free');
      }
      return parseFloat(customizedQuotationData.economicValue || 0).toFixed(2) + '€';
    }
    return '--';
  };

  const getLicensePrice = () => {
    if (customizedQuotationSet()) {
      if (!customizedQuotationData.licensePrice) {
        return t('customRequest.free');
      }
      return parseFloat(customizedQuotationData.licensePrice || 0).toFixed(2) + '€';
    }
    return '--';
  };

  const getTotalPrice = () => {
    if (customizedQuotationSet()) {
      const total =
        parseFloat(customizedQuotationData.licensePrice || 0) +
        parseFloat(customizedQuotationData.isFree
          ? 0 : customizedQuotationData.economicValue || 0);
      return total ? total.toFixed(2) + '€' : t('customRequest.free');
    }
    return '--';
  };

  return (
    <div className={classNames('message-container', classes.root)}>
      <div className={classNames('message-header', classes.header, internalClasses.header)}>
        <Button
          className={classNames('message-button', 'message-full-color-button', internalClasses.sendButton)}
          onClick={() => sendMessage('WAITING_FOR_CUSTOMER_RESPONSE')}
          disabled={!canSend()}>
          {t('adminReplyMessage.sendReply')}
        </Button>
      </div>
      <div className='message-body'>
        <p className={internalClasses.paragraphBeforeTextarea}>{t('customRequest.textArea')}</p>
        <TextareaAutosize
          aria-label="admin reply"
          className={internalClasses.textArea}
          maxLength={3000}
          onChange={event => setMessage(event.target.value)}
        />
        <div className={internalClasses.imgInfo}>3000 {t('message.charactersavailable')}</div>
        <div className={internalClasses.linkImageContainer}>
          <input type="text"
            className={classes.inputFile} placeholder={t('customRequest.imgLink')}
            onChange={event => setUrl(event.target.value)}>
          </input>
          <HiOutlineLink />
        </div>
        {!customizedQuotationSet() && (
          <div className={internalClasses.buttonsContainer}>

            {idDigitalResource === 0 &&

              <Button
                className={classNames('message-button', 'message-full-color-button', internalClasses.createNewQuotationButton)}
                onClick={() => setOpenCreateNewQuotationModal(true)}>
                {t('customRequest.createQuotation')}
              </Button>
            }
            <Button
              className={classNames('message-button', 'message-empty-color-button', internalClasses.rejectRequestButton)}
              onClick={() => sendMessage('CLOSED_BY_MUSEUM')}
              disabled={!canSend()}>
              {t('customRequest.refuseRequest')}
            </Button>
          </div>
        )}
        {customizedQuotationSet() && (
          <div className={internalClasses.greyContainer}>
            <p className={internalClasses.quotationName}>{t('customRequest.quotationTitle')}</p>
            <p>{t('adminReplyMessage.customizedQuotationData')}{formatDate(customizedQuotationData.expiresAt, '', false)}</p>
            <p>{t('adminReplyMessage.acquisitionPrice')}: {getAcquisitionPrice()}</p>
            <p> {t('adminReplyMessage.licensePrice')}: {getLicensePrice()}</p>
            <p>{t('adminReplyMessage.totalPrice')}: {getTotalPrice()}</p>
            <div className={internalClasses.iconsContainer}>
              <AiOutlineCloseCircle
                className={internalClasses.deleteIcon}
                onClick={() => setCustomizedQuotationData(resetCustomizedQuotation())}>
              </AiOutlineCloseCircle>
              <FaPencilAlt
                className={internalClasses.modifyIcon}
                onClick={() => setOpenCreateNewQuotationModal(true)}>
              </FaPencilAlt>
            </div>
          </div>
        )}
        {openCreateNewQuotationModal && (
          <CreateNewQuotationModal
            showModal={openCreateNewQuotationModal}
            onCloseModal={() => setOpenCreateNewQuotationModal(false)}
            onApplyQuotation={quotation => setCustomizedQuotationData({ ...quotation, isSet: true })}
            customizedQuotationData={customizedQuotationData}
            idDigitalResource={idDigitalResource}
            renewConcession={renewConcession}>
          </CreateNewQuotationModal>
        )}
      </div>
    </div>
  );
};

export default AdminReplyMessage;
