import { createSlice } from '@reduxjs/toolkit'


export const linkToBack = createSlice({
    name: 'linkToBack', // Reducer Name
    initialState: { link: '/App' },
    reducers: {

        saveLink: (state, action) => {
            let details = action.payload.data;
            state.link = details;
        },

    },
})

export const { saveLink } = linkToBack.actions

export default linkToBack.reducer;