import { constants } from "../../config/config";
import request from "../base/baseService";


const getIdTenantFromSubdomain = (subDomain) => {
    let url = `${constants.api.url}api/authentication/get-id-tenant?subDomain=${subDomain}`;
    return request({
        url: url,
        method: 'GET',
    });
}


export const CommonConfService = {
    getIdTenantFromSubdomain,
}


export default CommonConfService;