import React, { useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import CustomRequestService from '../../service/adminServices/customRequestService';
import CustomRequestsTableAdmin from './CustomRequestsTableAdmin/CustomRequestsTableAdmin';
import { constants } from "../../config/config";
import CustomRequestFilterAdmin from './CustomRequestsTableAdmin/CustomRequestFilterAdmin';
import SectionHeader from '../common/SectionHeader/SectionHeader';
import ShowHideLoader from '../../hooks/showHideLoader';
import { Checkbox, FormControlLabel, IconButton, Pagination } from '@mui/material';
import { usePersistentState, storePersistentState } from '../../commons/persistentState';
import { HiViewBoards } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';

const setStyles = makeStyles(() => ({
    root: {
        width: 'calc(100% - 70px)',
        display: 'inline-block',
        maxHeight: '100vh',
        overflowY: 'auto',
        fontFamily: 'Roboto',
    },
    bodyContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 70px)'
    },
    flex: {
        display: 'flex',
    },
    pagination: {
        // Used this style to prevent the table from going over the pagination
        marginTop: '12px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '12px',
        // position: 'absolute!important',
    },
    // headers classes
    headerActionsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        color: '#d4d4d4',
        cursor: 'pointer'
    },
    iconButton: {
        height: '1.5em',
        width: '1.5em'
    },
    columnsSelectContainer: {
        position: 'absolute',
        zIndex: '10',
        top: '3em',
        right: '.5em',
        background: 'white',
        width: '10em',
        padding: '.5em',
        boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    },
    columnSelectTitle: {
        margin: '0',
        fontSize: '.9em',
        fontWeight: '600',
        color: '#5e5e5e'
    },
    checkboxesContainer: {
        marginTop: '.5em',
        maxHeight: '10em',
        overflowY: 'scroll',
        overflowX: 'hidden'
    },
}));


const FILTERS_PERSIST_KEY = 'CustomRequestsListAdmin';

function CustomRequestsListAdmin() {
    const classes = setStyles();
    const [t] = useTranslation();
    const [showLoader, hideLoader] = ShowHideLoader();
    const refFilters = useRef();

    const [crsList, setCRsList] = useState({
        items: [],
        pagination: {
            PageNumber: 1,
            PageCount: 1
        }
    });

    const columnsToShow = [
        'customRequests.date',
        'customRequests.user',
        'customRequests.mail',
        'customRequests.entity',
        'customRequests.inventory',
        'customRequests.description',
        'customRequests.usage',
        'customRequests.price'
    ];

    const [showSelectColumns, setShowSelectColumns] = useState(false);

    const [visibleColumns, setVisibleColumns] = usePersistentState(
        FILTERS_PERSIST_KEY, 'VisibleColumns', [...columnsToShow]);

    const getCRsList = async (page, filters) => {
        try {
            showLoader(true);
            // await request({
            //     url: `${constants.api.url}api/test/validate`,
            //     method: 'GET'
            // });
            const items = await CustomRequestService.getCRsList(
                page || crsList.pagination.PageNumber,
                constants.pageSize(),
                filters
            );
            setCRsList(items);
        } catch (ex) {
            setCRsList([]);
            throw ex;
        } finally {
            hideLoader();
        }
    };

    const changePage = async page => {
        if (page !== crsList.pagination.PageNumber) {
            await refFilters.current.applyFilters(page);
        }
    };

    const renderHeaderActions = () => {
        return <div className={classes.headerActionsContainer}>
            <IconButton className={classes.iconButton} onClick={() => setShowSelectColumns(!showSelectColumns)}>
                <HiViewBoards />
            </IconButton>

            {showSelectColumns && (
                <div className={classes.columnsSelectContainer}>
                    <OutsideClickHandler onOutsideClick={() => setShowSelectColumns(false)}>
                        <p className={classes.columnSelectTitle}>{t('customRequest.columnTitle')}: </p>
                        <div className={classes.checkboxesContainer}>
                            {columnsToShow.map((col, index) =>
                                <FormControlLabel
                                    key={index}
                                    label={t(col) ? t(col) : col}
                                    checked
                                    control={
                                        <Checkbox
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: '#E7004E !important',
                                                },
                                                '& .MuiSvgIcon-root': { fontSize: '1.2em' },
                                                paddingTop: '.3em !important',
                                                paddingBottom: '.3em !important',
                                            }}
                                            checked={visibleColumns.indexOf(col) !== -1}
                                            onChange={evt => showHideColumn(col, evt.target.checked)}
                                        ></Checkbox>
                                    }
                                    sx={{
                                        color: 'black!important',
                                        width: '100%',
                                        '& .MuiFormControlLabel-label': {
                                            fontSize: '.9em!important'
                                        }
                                    }}
                                >
                                </FormControlLabel>
                            )}
                        </div>
                    </OutsideClickHandler>
                </div>
            )}
        </div>
    }


    const showHideColumn = (column, visible) => {
        if (visible) {
            setVisibleColumns([...visibleColumns, column]);
        } else {
            setVisibleColumns(visibleColumns.filter(x => x !== column));
        }
        storePersistentState(FILTERS_PERSIST_KEY);
    };

    return (
        <>
            <div id='customRequestsListAdmin' className={classes.root}>
                <SectionHeader
                    title={t('customRequest.title')}
                    renderActions={renderHeaderActions}
                />

                <div className={classes.bodyContainer}>

                    <div>
                        <CustomRequestFilterAdmin
                            ref={refFilters}
                            searchCallback={getCRsList}
                        />

                        <CustomRequestsTableAdmin
                            classes={classes}
                            visibleColumns={visibleColumns}
                            crsList={crsList.items}
                        />

                    </div>

                    <div className={classes.pagination}>
                        {!!crsList.items?.length && (
                            <Pagination
                                showFirstButton
                                showLastButton
                                page={crsList.pagination?.PageNumber}
                                count={crsList.pagination?.PageCount}
                                onChange={async (_, page) => await changePage(page)}
                            />
                        )}
                    </div>

                </div>
            </div>
        </>
    );
}

export default CustomRequestsListAdmin;
