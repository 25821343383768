import { lazy } from 'react';
import ListOfDigitalResources from '../../AdminArea/ListOfDigitalResources/ListOfDigitalResources';
import CustomRequestsListAdmin from '../../AdminArea/CustomRequests/CustomRequestsListAdmin';
import AdminLogin from '../../AdminArea/AdminLogin/AdminLogin';

const Configuration = lazy(() => import('../../AdminArea/Configuration/Configuration'));
const Licenses = lazy(() => import('../../AdminArea/Concessions/Concessions'));
const TenantsManagement = lazy(() => import('../../AdminArea/TenantsManagement/TenantsManagement'));
const CustomRequestDetailsPage = lazy(() => import('../../AdminArea/CustomRequests/CustomRequestDetails'));
const OrdersListAdmin = lazy(() => import('../../AdminArea/OrdersListAdmin/OrdersListAdmin'));
const ExportCsv = lazy(() => import('../../AdminArea/Export/ExportCsv'));
const Dashboard = lazy(() => import('../../AdminArea/Dashboard/Dashboard'));

const adminRoutes = [
    {
        path: '/AdminArea/AdminLogin',
        component: AdminLogin,
        name: 'AdminLogin',
        // exact: true
    },
    {
        path: '/AdminArea/DigitalResources',
        component: ListOfDigitalResources,
        name: 'ListOfDigitalResources',

    },
    {
        path: '/AdminArea/Configuration',
        component: Configuration,
        name: 'Configuration',
    },
    {
        path: '/AdminArea/Licenses',
        component: Licenses,
        name: 'Licenses',
    },
    {
        path: '/AdminArea/TenantsManagement',
        component: TenantsManagement,
        name: 'TenantsManagement',
    },
    {
        path: '/AdminArea/CustomRequests/Details/:idCR',
        component: CustomRequestDetailsPage,
        name: 'CustomRequestDetailsPage'
    },
    {
        path: '/AdminArea/CustomRequests/List',
        component: CustomRequestsListAdmin,
        name: 'CustomRequestsListAdmin',
    },

    {
        path: '/AdminArea/OrdersListAdmin',
        component: OrdersListAdmin,
        name: 'OrdersListAdmin',
    },

    {
        path: '/AdminArea/ExportCsv',
        component: ExportCsv,
        name: 'ExportCsv',
    },

    {
        path: '/AdminArea/Dashboard',
        component: Dashboard,
        name: 'Dashboard',
    },

];

export default adminRoutes;
