/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import LeftColumn from '../../common/LeftColumn';
import SidebarInfoContainer from './SidebarInfoContainer';
import Button from '@mui/material/Button';
import { HiOutlineLink } from 'react-icons/hi';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import ChatSectionContainer from './ChatSection/ChatSectionContainer';
import SectionHeader from '../../common/SectionHeader/SectionHeader';
import { useParams, useHistory } from "react-router-dom";
import CustomRequestService from '../../../service/adminServices/customRequestService';
import { constants } from '../../../config/config';
import GlobalDataStore from '../../../GlobalDataStore';
import ShowHideLoader from '../../../hooks/showHideLoader';
import { EditConcession } from '../../Concessions/ConcessionsModal/EditConcession';
import { useTranslation } from 'react-i18next';

/* eslint-disable jsx-a11y/anchor-is-valid */

const setStyles = makeStyles(() => ({
  root: {
    width: 'calc(100% - 70px)',
    display: 'inline-block',
    fontFamily: "Roboto",
  },
  pageContainer: {
    display: 'flex',
    height: 'calc(100vh - 70px)',
  },
  artworkLink: {
    cursor: 'pointer'
  },
  concessioneButton: {
    marginTop: '1em !important'
  }
}));

const CustomRequestDetailsPage = () => {
  const classes = setStyles();
  const { idCR } = useParams();
  const [showLoader, hideLoader] = ShowHideLoader();
  const [sidebarInfo, setSidebarInfo] = useState([]);
  const [chatInfo, setChatInfo] = useState();
  const [openConcession, setOpenConcession] = useState(false);
  const history = useHistory();
  const [t] = useTranslation();


  useEffect(async () => {
    try {
      showLoader();
      const details = await CustomRequestService.getCRDetails(idCR);
      const states = await CustomRequestService.getStatesList();
      const idDR = details.digitalResource?.idDigitalResource;


      setChatInfo({
        idCustomRequest: details.idCustomRequest,
        idDigitalResource: idDR,
        state: states.filter(x => x.idState === details.idState)[0],
        messages: details.messages || [],
        allStates: states,
        toBePaidBefore: details.toBePaidBefore,
        renewConcession: details.renewConcession,
        organization: details.organization || null
      });
      setSidebarInfo({
        idDR: idDR,
        items: [
          {
            titleSection:
              <>{details.digitalResource?.title || '--'}
                {!!idDR && (
                  <>
                    <a data-tip data-for='global'
                      onClick={() => history.push('/App/DigitalResourceDetails/' + idDR)}>
                      <HiOutlineLink className={classes.artworkLink}></HiOutlineLink>
                    </a>

                    <ReactTooltip id='global' aria-haspopup='true' place="right" type="dark" effect="solid">
                      {t('customRequest.drDetails')}
                    </ReactTooltip>
                  </>
                )}
              </>,
            texts: [
              details.digitalResource?.author || '--',
              details.digitalResource?.dating || '--',
              details.digitalResource?.inventoryNumber || '--'
            ]
          },
          {
            titleSection: 'customrequest.sidebar.title',
            texts: [
              ...Object.keys(details.licenseUserSelected || {})
                .map(key => `${key}: ${details.licenseUserSelected[key] || '--'}`),
              details.existLicense
                ? <></>
                : <Button
                  className={classNames('message-button', 'message-empty-color-button', classes.concessioneButton)}
                  onClick={() => setOpenConcession(true)}>
                  {t('customRequest.newConcession')}
                </Button> // TODO: ADDED FUNCTION TO OPEN MODAL TO CREATE A CONCESSIONS (THE MODAL ALREADY EXISTS IN ConcessionsModal FOLDER)
            ],
          },
          {
            titleSection: 'customRequest.userDetails',
            texts: [
              `${details.userFirstName} ${details.userLastName}`,
              details.organization,
              details.userEmail,
              details.physicalAddress,
              details.phoneNumber
            ]
          }
        ]
      });
    } finally {
      hideLoader();
    }
  }, []);

  const renderSidebarInfo = () => {
    return <SidebarInfoContainer data={sidebarInfo.items} />
  };

  return (
    <>
      {chatInfo && (
        <div className={classes.root}>
          <SectionHeader title={t('customRequest.title')}></SectionHeader>
          <div className={classes.pageContainer}>
            <LeftColumn
              renderInformation={renderSidebarInfo}
              backgroundImage={sidebarInfo.idDR
                ? constants.paths.thumbnail(GlobalDataStore.getTenantId(), sidebarInfo.idDR)
                : '/images/not-found.png'}
            />
            <ChatSectionContainer chatInfo={chatInfo} />
          </div>
          {openConcession && (
            <EditConcession
              idConcession={-1}
              idCustomRequest={idCR}
              closingModal={() => setOpenConcession(false)}
            />
          )}
        </div>
      )}
    </>
  );
};

export default CustomRequestDetailsPage;
