import { createSlice } from '@reduxjs/toolkit';

export const vocabularySlice = createSlice({
    name: 'licensesVocabulary', // Reducer Name
    initialState: {
        //mandatory
        usage: {},
        medium: {},
        typology: {},
        distributionFormat: {},

        //other
        placement: {},
        size: {},
        quantity: {},
        duration: {},
        region: {},
        language: {}
    },

    reducers: {
        saveData: (state, action) => {

            if (action.payload && action.payload.data) {
                let internalObj = action.payload.object;
                let details = action.payload.data;
                details = {
                    idLicenceMainMenu: details.idLicenceMainMenu,
                    itemLabel: details.itemLabel,
                    desc: details.desc,
                    itemOrder: details.itemOrder,
                    licenseTerm: [...details.terms],
                }
                state[internalObj] = details;
            }
        },
    },
})

export const { saveData } = vocabularySlice.actions

export default vocabularySlice.reducer;