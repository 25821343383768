if (!window) {
    throw new Error('variable "window" is not defined!');
}

if (!navigator) {
    throw new Error('variable "navigator" is not defined!');
}

export const isSubdomain = () => {
    // // RESUME AFTER DEVELOP
    // // use for override the problem of check subdomain in local
    // return true;
    if (!window.location?.hostname) {
        return false;
    }
    const host = window.location.hostname.toLowerCase();
    const roots = process.env.REACT_APP_ROOT_DOMAINS.toLowerCase();
    for (const root of roots.split(',').map(x => x.trim())) {
        const index = host.lastIndexOf(root);
        if (index === (host.length - root.length)) {
            const domain = host.substring(0, index);
            return domain.length >= 2 && domain[domain.length - 1] === '.';
        }
    }
    console.warn('domains mismatch', { host, roots });
    throw new Error('invalid value of setting "REACT_APP_ROOT_DOMAINS"');
};

export const isAdminArea = () => {
    const path = window.location?.pathname?.toLowerCase();
    return !!path?.startsWith('/adminarea');
};

export const isUrlRooted = url => {
    url = url?.toLowerCase() || '';
    return url.startsWith('http://') || url.startsWith('https://');
};

export const getLanguage = () => {
    let lang = navigator.language || '';
    if (lang.length < 2) {
        lang = 'en';
    } else if (lang.length > 2) {
        lang = lang.substring(0, 2);
    }
    return lang.toLowerCase();
};