import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../config/i18n';
import { makeStyles } from '@mui/styles';
import { IoMdArrowDropdown } from 'react-icons/io';
import TranlsationsService from '../../../service/translationsService';
import { useDispatch, useSelector } from 'react-redux';
import { saveCultures, updateActualLang } from '../../../redux/slices/translationSlice';
import { constants } from '../../../config/config';
import GlobalDataStore from '../../../GlobalDataStore';
import UsersService from '../../../service/commons/userService';
import ShowHideLoader from '../../../hooks/showHideLoader';
/* eslint eqeqeq: "off"*/
/* eslint react-hooks/exhaustive-deps: "off"*/

const setStyles = makeStyles((theme) => {
    const themeBackground = theme && theme.background ? theme.background : null;
    return {
        languagesContainer: {
            position: 'absolute',
            textAlign: 'center',
            padding: '6px 15px',
            backgroundColor: themeBackground,
            top: '40px',
            borderRadius: '5px',
            color: 'rgba(75 75 75 1)',
            boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
            '& div': {
                position: 'relative',
                // left: '100%',
                width: '220px',
                zIndex: '101',
                background: 'rgba(255, 255, 255, .3)',

            },
            '& p': {
                cursor: 'pointer',
                margin: 0
            },
            '& p.selected': {
                display: 'none'
            }
        }
    }
})

const SwitchLanguage = ({
    classes,
}) => {
    const internalClasses = setStyles();
    const [showLoader, hideLoader] = ShowHideLoader();
    const [languages, setLanguages] = useState(constants.defaultLanguages);
    const [showSelectLanguage, setShowSelectLanguage] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const dispatch = useDispatch();
    const cultures = useSelector(state => state.translations.cultures);


    useEffect(() => {
        // GET CULTURES OF THE CURRENT USER AFTER LOGIN
        if (localStorage.getItem('isLogin') === 'true') {
            if (!cultures || cultures.length == 0) {
                const tmpItem = TranlsationsService.getTranslationsFilters();
                if (tmpItem instanceof Promise) {
                    tmpItem.then(res => {
                        dispatch(saveCultures(res));
                        setLanguages(res.cultures.map(x => x.code));
                    },
                        err => {
                            console.error('Errore!', { err });
                        })
                }
            }
        } else if (constants.defaultLanguages.findIndex(x => x == i18n.language) == -1) {
            const defaultLanguage = constants.defaultLanguages[0];
            i18n.changeLanguage(defaultLanguage);
            setSelectedLanguage(defaultLanguage);
            dispatch(updateActualLang(defaultLanguage));
        }
        // SWITCH LANGUAGE BASED ON INFO FROM THE JWT TOKEN
        GlobalDataStore.registerLanguageChangedCallback(changeLanguage);
    }, []);

    const changeLanguage = (lang) => {
        if (localStorage.getItem('isLogin') === 'true') {
            showLoader(true);
            UsersService.changeUserCulture(lang)
                .then(() => {
                    getTranslations(lang);
                    hideLoader();
                })
                .catch((err) => {
                    console.error(err);
                    hideLoader();
                })

        } else {
            getTranslations(lang);
        }



    }


    const getTranslations = (lang) => {
        TranlsationsService.getTranslationsForCurrentLanguage(lang)
            .then(() => {
                i18n.changeLanguage(lang);
                setSelectedLanguage(lang);
                setShowSelectLanguage(false);
                dispatch(updateActualLang(lang));
            })
    }

    return (
        <>
            <div id='switchLanguage' className={classes.flexEnd}>

                <div>
                    <span onClick={() => setShowSelectLanguage(!showSelectLanguage)} style={{ cursor: 'pointer' }}>
                        <img alt="" className="rl-headerIcon" src="/images/navbar/global-svgrepo-com.svg" />
                        <IoMdArrowDropdown />
                    </span>
                    {showSelectLanguage && <div className={internalClasses.languagesContainer}>
                        {languages.map(ln =>
                            <p key={ln}
                                onClick={() => changeLanguage(ln)}
                            >{ln}</p>
                        )}
                    </div>}
                </div>
                <span style={{ marginBottom: '1.5px' }} className={classes.marginLeft16px}> {selectedLanguage}</span>
            </div>
        </>
    )
}

SwitchLanguage.propTypes = {
    classes: PropTypes.object.isRequired,
}


export default SwitchLanguage;
