
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import AuthenticationService from '../../service/usersService/authenticationService';
import GlobalDataStore from '../../GlobalDataStore';
import TranlsationsService from '../../service/translationsService';
import { saveCultures } from '../../redux/slices/translationSlice';
import i18n from '../../config/i18n';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import useLoaderHook from '../../hooks/useLoaderHook';
import config from '../../config/config'

const setStyles = makeStyles(() => ({
    root: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Roboto',
        color: '#4a4a4a',
    },
    formStyle: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '500px',
    },
    title: {
        fontSize: '22px',
        fontWeight: '500',
        lineHeight: '1.5',
        letterSpacing: '2.5px',

        alignSelf: 'flex-start',
        margin: '0',
    },
    secondTitle: {
        fontSize: '12px',
        lineHeight: '2',
        letterSpacing: '1.6px',
        alignSelf: ' flex-start',
        marginTop: '0',
        marginBottom: '16px',
    },
    labelStyle: {
        margin: '10px 0 0 0',
        fontSize: '16px',
    },
    inputStyle: {
        margin: '5px 0 10px 0',
        padding: '5px',
        background: 'transparent !important',
        border: 'none',
        borderBottom: '1px solid #707070',
        height: '26px',
        boxSizing: 'border-box',
        width: '100%',
        color: 'black',
        fontSize: '16px',
        fontWeight: '100',
        // '-webkit-box-shadow': '0 0 0px 1000px white inset',
        '&:focus': {
            outline: 'none',
        }
    },
    forgotPsw: {
        width: '100%',
        margin: '0px',
        textAlign: 'right',
        fontSize: '12px',
        fontStyle: 'italic',
        cursor: 'pointer',
    },
    failLogin: {
        color: 'red',
        fontSize: '12px',
        width: '100%',
        textAlign: 'center',
        margin: '0px',
    },
    btnContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    disabledBtn: {
        width: '180px',
        height: '40px',
        marginTop: '15px',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '2px',
        textTransform: 'uppercase',
        color: 'rgba(0, 0, 0, 0.26)',
        cursor: 'default',
        border: '2px solid',
        borderRadius: '100px',
        background: 'white',
        pointerEvents: 'none',
    },
    enableBtn: {
        width: '180px',
        height: '40px',
        marginTop: '15px',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '2px',
        textTransform: 'uppercase',
        color: '#E7004E',
        cursor: 'default',
        border: '2px solid',
        borderRadius: '100px',
        background: 'white',
        // WTC
        // eslint-disable-next-line no-dupe-keys
        cursor: 'pointer',
    }

}))

function SystemAdminLogin(props) {
    const history = useHistory();
    const classes = setStyles();
    const dispatch = useDispatch();
    const [t] = useTranslation();

    const [enableBtn, setEnableBtn] = useState(false);

    const [, initLoading, disableLoadingHook, ,] = useLoaderHook();
    const [isLoading, setIsLoading] = useState(true)

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    // eslint-disable-next-line no-unused-vars
    const [isLogin, setIsLogin] = useState(true);
    const [failLogin, setFailLogin] = useState(false);



    useEffect(() => {
        let params = queryString.parse(props.location.search);
        let token = params['token'];
        // LOGIN
        if (token) {

            localStorage.setItem("Token", token);
            GlobalDataStore.extractInfoFromToken();
            GlobalDataStore.setIsLogin(true);
            onLogin();
        }
        // localStorage.setItem("RefreshToken", loginResp.refreshToken);

        localStorage.removeItem('Basket');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    useEffect(() => {
        // When the value is taken from localStorage, it's always a string. For this reason, check if the param isLogin is not equal to "false"
        if (localStorage.isLogin && localStorage.isLogin !== "false") {
            onLogin();
        } else {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.isLogin])


    const handleLogin = () => {
        // if (isLogin) {
        onLogin();
        // } else {
        //     resetPassword();
        // }
    }


    const onLogin = async () => {
        initLoading(true);
        setFailLogin(false);
        try {
            // LOGIN
            const loginResp = await AuthenticationService.login(username, password);
            localStorage.setItem("Token", loginResp.jwtToken);
            localStorage.setItem("RefreshToken", loginResp.refreshToken);
            GlobalDataStore.setIsLogin(true);
            GlobalDataStore.extractInfoFromToken();
            // TRADUZIONI
            const transResp = await TranlsationsService.getTranslationsFilters();
            dispatch(saveCultures(transResp));
            i18n.changeLanguage(transResp.cultures.map(x => x.code));
            // CARRELLO
            // await BasketService.concatBasket();
            localStorage.removeItem('Basket');
            history.replace(config.routes.tenantsManagement);
            console.log('logged in')
        } catch (err) {
            console.error('login failed', err);
            setPassword('')
            setFailLogin(true);
            GlobalDataStore.setIsLogin(false);
            setIsLoading(false);
        } finally {
            disableLoadingHook();
        }
    };


    // const resetPassword = () => {
    //     UsersService.resetPassword(username)
    //         .then((resp) => {
    //             setIsLogin(true);
    //         }).catch((err) => {
    //             console.log({ err });
    //         })
    // }

    useEffect(() => {

        if (username && (password || !isLogin)) {
            setEnableBtn(true);
        } else {
            setEnableBtn(false);
        }
    }, [username, password, isLogin])


    return (
        <>
            {!isLoading && (
                <div id='systemAdminLogin' className={classes.root}>
                    <div className={classes.formStyle}>

                        <h1 className={classes.title}>{isLogin ? t('systemAdminLogin.titleLogIn') : t('systemAdminLogin.titleReset')}</h1>
                        <p className={classes.secondTitle}>{isLogin ? t('systemAdminLogin.detailTitle') : t('systemAdminLogin.emailTitle')}</p>

                        <label className={classes.labelStyle}>{t('login.username')}</label>
                        <input className={classes.inputStyle} value={username} type='text' onChange={(evt) => setUsername(evt.target.value)} />


                        {isLogin && (
                            <>
                                <label className={classes.labelStyle}> {t('login.password')}</label>
                                <input className={classes.inputStyle} value={password} type='password' onChange={(evt) => setPassword(evt.target.value)} />
                            </>
                        )}


                        <div className={classes.btnContainer}>

                            <p className={classes.failLogin}>{failLogin ? t('login.invalidCredentials') : ' '}</p>

                            <button className={enableBtn ? classes.enableBtn : classes.disabledBtn} onClick={() => handleLogin()} >{('login.login')}</button>
                            {/* <button className={enableBtn ? classes.enableBtn : classes.disabledBtn} onClick={() => handleLogin()} >{isLogin ? (('login.login')) : 'Reset password'}</button> */}
                        </div>
                    </div>

                </div >
            )}
        </>
    )
}


export default SystemAdminLogin;

