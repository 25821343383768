import { createSlice } from '@reduxjs/toolkit';

export const visualizationConfSlice = createSlice({
    name: 'visualizationConf', // Reducer Name
    initialState: {
        "isLoaded": false,
        "idTenantAppearanceSettings": undefined,
        "idTenant": undefined,
        // colore sfondo: string
        // "background": undefined,
        "background": "#FFFFFF",
        //visualizzazione: true => griglia; false => lista
        // "gridVisualization": undefined,
        "viewFormat": "grid",
        // titoli principali
        // "firstTitle": undefined,
        "firstTitle": {
            "font": "Arial",
            "color": "#000000"
        },
        //sottotitoli
        // "secondTitle": undefined,
        "secondTitle": {
            "font": "Arial",
            "color": "#000000"
        },
        // testo dell'intestazione
        "heading": "text",
        // filigrana
        "watermark": undefined,
        "logo": undefined,
    },
    reducers: {
        saveData: (state, action) => {
            if (action.payload && action.payload.data) {
                let details = action.payload.data;
                state.isLoaded = true;
                state.idTenantAppearanceSettings = details.idTenantAppearanceSettings;
                state.idTenant = details.idTenant;
                state.background = details.background;
                state.viewFormat = details.viewFormat;
                state.firstTitle = details.firstTitle;
                state.secondTitle = details.secondTitle;
                state.headingText = details.headingText;
                state.watermark = details.watermark;
                state.logo = details.logo;
            }
        },
    },
})

export const { saveData } = visualizationConfSlice.actions

export default visualizationConfSlice.reducer;