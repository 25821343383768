import { Button, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { formatDate, printDate } from "../commons/formatData";
import { constants } from "../config/config";
import request from "../service/base/baseService";
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { it } from 'date-fns/locale';
import { zonedTimeToUtc } from "date-fns-tz";

export default function UtcDate() {
    const [oldDate, setOldDate] = useState();
    const [newDate, setNewDate] = useState();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        setOldDate((await request({
            url: `${constants.api.url}api/test/utc-load-from-db`,
            method: 'GET'
        }))?.date);
    }, []);

    const saveNewDate = async isLocal => {
        setOldDate((await request({
            url: `${constants.api.url}api/test/utc-save-into-db`,
            method: 'POST',
            data: {
                date: isLocal ? newDate : zonedTimeToUtc(newDate, '+00.00')
            }
        }))?.date);
    };

    return (
        <center>
            <br />
            <br />
            <span>Saved date: {printDate(oldDate, '--')}</span>
            <br />
            <span>Local date: {formatDate(oldDate, '--')}</span>
            <br />
            <br />
            <br />
            <LocalizationProvider locale={it} dateAdapter={DateAdapter}>
                <TextField
                    type='datetime-local'
                    sx={{ width: 250 }}
                    onChange={evt => setNewDate(new Date(evt.target.value))}
                />
            </LocalizationProvider>
            <br />
            <br />
            <br />
            <Button disabled={!newDate} onClick={() => saveNewDate(true)}>
                Save as local
            </Button>
            <span> | </span>
            <Button disabled={!newDate} onClick={() => saveNewDate(false)}>
                Save as UTC
            </Button>
        </center>
    );
};