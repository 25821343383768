import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { AiOutlineClose } from 'react-icons/ai';
import { useHistory } from 'react-router';
import ShowHideLoader from '../../../../hooks/showHideLoader';
import CustomRequestService from '../../../../service/adminServices/customRequestService';
import config from '../../../../config/config';
import DeleteModal from '../../../common/Modals/DeleteModal';
import { useTranslation } from 'react-i18next';


const setStyles = makeStyles(() => ({
  actualStateBanner: {
    height: '20px',
    backgroundColor: '#000',
    // width: '100%',
    padding: '1.5em 2em',
    color: '#fff',
    fontWeight: '600',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& svg': {
      cursor: 'pointer'
    }
  },
  closeRequestBanner: {
    height: '15px',
    backgroundColor: '#e7004e',
    padding: '10px 1.5em',
    // width: '100%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    fontSize: '.7em',
    fontWeight: '600',
    color: '#fff',
    '& span': {
      cursor: 'pointer'
    }
  }
}));

const ChatSectionHeader = ({ chatInfo }) => {
  const classes = setStyles();
  const history = useHistory();
  const [showLoader, hideLoader] = ShowHideLoader();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [t] = useTranslation();


  const goBack = () => history.push(config.routes.customRequestsAdmin);
  // Id state 3 & 4 => Waiting for client payment or museum publication
  const isClosed = () => chatInfo.state.stateLabel.toUpperCase().startsWith('CLOSED_') || chatInfo.state.idState === 3 || chatInfo.state.idState === 4;

  const closeCR = async () => {

    try {
      showLoader();
      const state = chatInfo.allStates.find(x =>
        x.stateLabel.toUpperCase() === 'CLOSED_BY_MUSEUM');
      if (!state) {
        throw new Error('cannot find state "CLOSED_BY_MUSEUM"');
      }
      await CustomRequestService.sendNewMessageUser(chatInfo.idCustomRequest, {
        messageText: t('customRequest.closedRequest'),
        idCustomRequestState: state.idState
      });
      goBack();
    } catch (ex) {
      console.error(ex);
    } finally {
      hideLoader();
    }

  };

  return (
    <>
      <div className={classes.actualStateBanner}>
        Stato della richiesta: {chatInfo?.state?.stateName || '--'}
        <AiOutlineClose onClick={goBack}></AiOutlineClose>
      </div>

      {!isClosed() && (
        <div className={classes.closeRequestBanner} onClick={() => setShowDeleteModal(true)}>
          <span>{t('customRequest.btnCloseRequest')}</span>
        </div>
      )}

      {showDeleteModal && (
        <DeleteModal
          showModal={true}
          title={t('customRequest.modalTitle')}
          modalText={t('customRequest.modalText')}
          btnConfirm={t('customRequest.btnConfirm')}
          closeModal={() => setShowDeleteModal(false)}
          confirmDeletion={() => closeCR()} />
      )}
    </>
  )
};

export default ChatSectionHeader;
