import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import config from '../../config/config'
import { saveLink } from "../../redux/slices/linkToBack";
import { useTranslation } from 'react-i18next';


const setStyles = makeStyles((theme) => {
    const themeTitle = theme && theme.secondTitle && theme.secondTitle.color ? theme.secondTitle.color : null;

    return {
        root: {
            marginTop: '40px',
            textAlign: 'center'
        },
        link: {
            cursor: 'pointer',
            color: themeTitle,
            textDecoration: 'underline'
        }
    }
})

export default function MustBeLogged() {
    const classes = setStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [t] = useTranslation();


    const goToLogin = () => {
        const payload = {
            data: history.location.search ? history.location.pathname.concat(history.location.search) : history.location.pathname,
        }
        dispatch(saveLink(payload));
        history.push(config.routes.login);
    }

    const goToRegistration = () => {
        history.push(config.routes.userCreation);
    }


    return (
        <div className={classes.root}>
            <p>
                <span>{t('mustBeLogged.title')}</span>&nbsp;

                <span className={classes.link} onClick={() => goToLogin()}>{t('mustBeLogged.auth')}</span>&nbsp;

                <span>{t('mustBeLogged.orders')}</span>&nbsp;

                <span className={classes.link} onClick={() => goToRegistration()}>{t('mustBeLogged.register')}</span>
            </p>
        </div>
    );
};