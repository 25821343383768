import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@material-ui/core';

const TextFilter = ({
  emptyFilter,
  onChangeText,
}) => {
  // const [t] = useTranslation();

  const [searchString, setSearchString] = useState(emptyFilter);

  useEffect(() => {
    if (emptyFilter) {
      setSearchString('');
    }
  }, [emptyFilter])

  const onChange = (event) => {
    const value = event && event.target ? event.target.value : undefined;
    setSearchString(value);
    onChangeText(value);
  }

  return (
    <Fragment>
      <div>
        <Input
          fullWidth
          color='secondary'
          type="text"
          name="search"
          value={searchString}
          onChange={onChange}
        />
      </div>
    </Fragment>
  );
};

TextFilter.propTypes = {
  filterData: PropTypes.any,
  activeFilters: PropTypes.any,
  prepareFilter: PropTypes.func,
};

TextFilter.defaultProps = {
};

export default TextFilter;
