/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import VisualizationConfService from './service/visualizationConfService';
import { transformToDetails } from './transformations/visualizationConfTransformation';
import routes from './config/routes/routes';
import config from './config/config';
import './App.css';
import useLoaderHook from './hooks/useLoaderHook';
import { ThemeProvider } from '@mui/system';
import { createTheme } from '@mui/material';
import GlobalDataStore from './GlobalDataStore';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DomainCheck } from './commons/components/DomainCheck';
import { BaseHelper } from './commons/components/BaseHelper';

const colorsTheme = {
  darkSilver: '#cccccc',
  silver: '#d6d5d5',
  lightSilver: '#e7e7e7',
  pinkRed: '#e7004e',
  white: '#ffffff',
  backgroundGrey: '#f5f5f5',
  crimson: '#de003d',
  black: '#000000',
}


const defaultTheme = createTheme({
  idTenantAppearanceSettings: 2,
  idTenant: 1,
  viewFormat: "grid",
  background: "#FFFFFF",
  firstTitle: {
    font: "arial",
    color: "#000000"
  },
  secondTitle: {
    font: "arial",
    color: "#000000"
  },
  heading: "text",
  watermark: {
    active: false,
    isHorizontal: false,
    isFile: false,
    value: ""
  },
  logo: "",
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  colors: colorsTheme,
  typography: {
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    overline: {
      fontSize: '0.625rem',
      fontWeight: 700,
      letterSpacing: '0.1em',
    },
  },
});


const App = () => {
  const [, initLoading, disableLoadingHook, , renderSpinner] = useLoaderHook();

  const [visualizationData, setVisualizationData] = useState(undefined);

  const [theme, setTheme] = useState(defaultTheme);

  // const [toggleTheme, setToggleTheme] = useState(true);
  // const changeTheme = () => {
  //   callVisualizationData(toggleTheme);
  //   setToggleTheme(!toggleTheme);
  // }

  const [, setTenantId] = useState(null);

  const callVisualizationData = (changeBackground = false) => {
    initLoading(true);
    const data = VisualizationConfService.getVisualizationConfig();
    if (data instanceof Promise) {
      data.then(conf => {
        let details = {};
        if (details) {
          details = transformToDetails(conf);
        }
        if (changeBackground) {
          details.background = 'black'
        }
        setVisualizationData(details);
      })
        .catch(error => {
          console.error("error in getting data: ", { error });
          const errorData = {};
          errorData.isLoaded = false;
          setVisualizationData(errorData);
        })
    } else {
      setVisualizationData(data);
    }
  }

  useEffect(async () => {
    setTenantId(GlobalDataStore.getTenantId());
    //onsole.log(GlobalDataStore.getTenantId());
    callVisualizationData();
    GlobalDataStore.initIsLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  /** Don't include initLoading, infinite loop */
  // }, [initLoading])


  useEffect(() => {
    if (visualizationData !== undefined) {
      // const payload = {
      //   data: visualizationData
      // }
      // dispatch(saveData(payload));
      setTheme(createTheme(visualizationData));
      //todo: spostare
      disableLoadingHook()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visualizationData]);
  /** Don't include disableLoadingHook, infinite loop */
  // }, [visualizationData, disableLoadingHook]);

  const renderRoutes = () => {
    return routes.map((route, index) => {

      return <Route
        key={index}
        path={route.path}
        component={route.component}
        exact={route.exact}
      />
    });
  }

  return (

    <div className="App">
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BrowserRouter basename="/">
            <DomainCheck />
            <BaseHelper />

            <Route>
              <Switch>
                {renderRoutes()}

                <Redirect to={config.routes.usersPage} />
              </Switch>
            </Route>


            {renderSpinner()}
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>

    </div>
  );
}

export default App;
