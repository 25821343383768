/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { cloneDeep, isEmpty } from 'lodash';
import CurrencyInput from 'react-currency-input-field';
import { AiOutlineClose } from 'react-icons/ai';
import ShowHideLoader from '../../../hooks/showHideLoader';
import { ConcessionsService } from '../../../service/adminServices/concessionsService';
import { AddVocabulary } from './AddVocabulary';
import { EditTerm } from './EditTerm';
import CustomRequestService from '../../../service/adminServices/customRequestService';
import { useTranslation } from 'react-i18next';

const setStyles = makeStyles(() => ({
    modalContainer: {
        width: '850px',
        fontFamily: 'Roboto',
        // maxHeight: '700px',
        // maxHeight: '',
    },
    //body:
    body: {
        padding: '19px 24px !important'
    },
    inputName: {
        fontWeight: ' 500',
        marginRight: '18px',
        marginTop: 'auto',
        marginBottom: '0',
        width: '160px',
        '&:first-letter': {
            textTransform: 'uppercase',
        }
    },
    inputText: {
        background: 'transparent',
        border: 'none',
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        width: '500px',
        fontSize: '14px',
        '&:focus': {
            outline: 'none',
        }
    },
    conditionsContainer: {
        width: 'calc(100% - 70px)',
        maxHeight: 'calc(85vh - 200px)',
        overflowY: 'auto',
        background: '#F5F5F5',
        marginTop: '18px',
        padding: '0px 35px 12px 35px',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            margin: '8px 0'
        }
    },
    separator: {
        width: '812px',
        margin: '12px'
    },
    width100: {
        width: '100%',
        margin: '8px 0',
    },
    inputPrice: {
        width: '180px',
        textAlign: 'right',
        paddingRight: '16px',
        // '-webkit-appearance': ' none',
    },
    checkbox: {
        height: '16px',
        width: '16px',
        margin: '0 20px 0 90px',
        position: 'relative',
        top: '4px',
        '& input': {
            accentColor: '#e7004e',
        }
    },
    eur: {
        position: 'relative',
        right: '12px'
    },
    disabled: {
        cursor: 'auto',
        opacity: '50%',
        pointerEvents: 'none',
    },
    footer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: '30px',
        '& button': {
            fontSize: '14px',
            border: 'none',
            background: 'transparent',
            textTransform: 'uppercase',
            fontWeight: '600',
            letterSpacing: '0.1em',
        }
    },
    footerBtnCancel: {
        color: '#4a4a4a',
        cursor: 'pointer',
        marginRight: '16px',
    },
    footerBtnSave: {
        color: '#E7004E',
        cursor: 'pointer'
    },
    marginBt36px: {
        marginBottom: '36px'
    }
}))


export const EditConcession = ({
    idConcession,
    idCustomRequest,
    mustBeCloned,
    closingModal
}) => {
    const classes = setStyles();
    const [t] = useTranslation();

    const [opened, setOpened] = useState(true);
    const [title, setTitle] = useState('');
    const [price, setPrice] = useState(0);
    const [isFree, setIsFree] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selection, setSelection] = useState({});

    const [showLoader, hideLoader] = ShowHideLoader();

    useEffect(async () => {
        const fillStates = concession => {
            setPrice(concession.price || 0);
            setIsFree(!!concession.isFree);
            const items = concession.listOfTerms || [];
            sortItems(items);
            setItemList(items);
            initSelection(items);
            if (!mustBeCloned) {
                setTitle(concession.licenseTitle || '');
            }
        };
        if (isEdit()) {
            try {
                showLoader();
                fillStates(await ConcessionsService
                    .loadConcession(idConcession)
                );
            } finally {
                hideLoader();
            }
        } else if (isCR()) {
            try {
                showLoader();
                fillStates({
                    listOfTerms: await CustomRequestService
                        .getTermsSelecedByUser(idCustomRequest)
                });
            } catch (e) {
                if (e.status === 400 && e.data?.isValid === false) {
                    closeModal(false);
                }
            } finally {
                hideLoader();
            }
        }
    }, []);

    useEffect(async () => {
        const {
            mustReload,
            lastOrder,
            ...definedTerms
        } = selection;
        if (isEdit() && isEmpty(definedTerms)) {
            return;
        }
        if (mustReload === false) {
            return;
        }
        if (!mustReload && isCR()) {
            return;
        }
        try {
            showLoader();
            for (const key in definedTerms) {
                if (!isIdSet(definedTerms[key])) {
                    delete definedTerms[key];
                }
            }
            const items = await
                ConcessionsService.loadSelectableTerms(
                    (lastOrder || 0) + 1, {
                    licenseTitle: title,
                    price: price || 0,
                    definedTerms
                }) || [];
            sortItems(items);
            setItemList(items);
            initSelection(items);
        } finally {
            hideLoader();
        }
    }, [selection]);

    const isNew = () => idConcession === -1 || mustBeCloned || isCR();
    const isEdit = () => idConcession !== -1;
    const isOptional = item => !item.hasDependentItems;
    const isCR = () => isIdSet(idCustomRequest);
    const isIdSet = id => id !== null && id !== undefined;


    const canSave = () => {
        if (!title?.trim()?.length) {
            return false;
        }
        if (!isFree && price <= 0) {
            return false;
        }
        for (const item of getSelecteds()) {
            const id = selection[item.itemOrder];
            if (!isIdSet(id)) {
                return false;
            }
        }
        return true;
    };


    const sortItems = items => {
        items.sort((x, y) => {
            const xOrder = x.itemOrder || 0;
            const yOrder = y.itemOrder || 0;
            if (xOrder < yOrder) {
                return -1;
            }
            if (xOrder > yOrder) {
                return 1;
            }
            return 0;
        });
    };


    const initSelection = items => {
        const selection = {
            mustReload: false
        };
        for (const item of items) {
            const id = item.idSelected;
            if (isIdSet(id)) {
                if (isEdit() || !isOptional(item)) {
                    selection[item.itemOrder] = id;
                } else if (isOptional(item)) {
                    selection[item.itemOrder] = null;
                }
            }
        }
        setSelection(selection);
    };


    const saveConcession = async () => {
        try {
            showLoader();
            const items = { ...selection };
            delete items.lastOrder;
            delete items.mustReload;
            await ConcessionsService.saveConcession({
                licenseTitle: title,
                price: isFree ? null : price,
                idLicense: isNew() ? null : idConcession,
                definedTerms: items
            });
            closeModal(true);
        } finally {
            hideLoader();
        }
    };


    const closeModal = saved => {
        setOpened(false);
        if (typeof closingModal === 'function') {
            closingModal(saved);
        }
    };


    const toggleFree = () => {
        if (isFree) {
            setPrice(0);
        }
        setIsFree(!isFree);
    };


    const changeTerm = (itemData, idTerm) => {
        const data = {
            ...selection,
            mustReload: !!itemData.hasDependentItems,
            lastOrder: itemData.itemOrder
        };
        data[itemData.itemOrder] = idTerm ? idTerm : null;
        setSelection(data);

    };


    const getSelecteds = () => {
        const optionals = getOptionals();
        return itemList
            .filter(x => optionals.indexOf(x) === -1);
    };


    const getOptionals = () => {
        return itemList
            .filter(x => isOptional(x))
            .filter(x => !(x.itemOrder in selection));
    };


    const addOptional = (itemOrder) => {
        setSelection({
            ...selection,
            [itemOrder]: itemList
                .filter(x => x.itemOrder === itemOrder)
                .map(x => x.idSelected)[0] || null
        });
    };


    const showOptionals = () => {
        for (const item of itemList.filter(x => !isOptional(x))) {
            if (!isIdSet(selection[item.itemOrder])) {
                return false;
            }
        }
        return !!getOptionals().length;
    };


    const getModalAction = () => {
        if (mustBeCloned) {
            return 'clone';
        }
        if (isNew()) {
            return 'new';
        }
        return 'edit';
    };

    const deleteTerm = (order) => {

        let selectionClone = cloneDeep(selection);
        delete selectionClone[order];

        setSelection(selectionClone);
    }


    return (
        <Modal
            open={opened}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            centered={'true'}
            id='editConcessionModal'
        >
            <Box className={`admin-modal-container ${classes.modalContainer}`} >
                <div className='admin-modal-header' >
                    <span>{t(`license.concessionsModal.${getModalAction()}Concession`)}&nbsp;</span>
                    <AiOutlineClose
                        className='admin-modal-headerClose'
                        onClick={() => closeModal(false)}
                    />
                </div>
                <div className={classes.body}>

                    <div>
                        <span className={classes.inputName} > {t(`license.concessionsModal.concessionName`)}* :</span>
                        <input
                            type='text'
                            className={classes.inputText}
                            value={title}
                            maxLength={100}
                            onChange={evt => setTitle(evt.target.value)}
                        />
                    </div>

                    <div className={classes.conditionsContainer}  >
                        {getSelecteds().map((x, index) => (
                            <div >

                                <EditTerm
                                    key={x.itemOrder}
                                    termData={x}
                                    isLastSelect={index === getSelecteds().length - 1 ? true : false}
                                    termChanged={changeTerm}
                                    deleteTerm={(itemOrder) => deleteTerm(itemOrder)}
                                />

                            </div>
                        ))}

                        {showOptionals() && (
                            <AddVocabulary
                                optionalItems={getOptionals()}
                                optionalAdded={addOptional}

                            />
                        )}

                        <hr className={classes.separator} />

                        <div className={classNames(classes.inputName, classes.width100)}>{t(`license.concessionsModal.rate`)}* :</div>

                        <div className={classes.width100} >
                            <span className={classes.inputName} >{t(`license.concessionsModal.setPrice`)}:</span>
                            <span>
                                <CurrencyInput
                                    className={classNames(classes.inputText, classes.inputPrice)}
                                    placeholder='0.00'
                                    decimalSeparator='.'
                                    groupSeparator=' '
                                    allowNegativeValue={false}
                                    disabled={isFree}
                                    value={isFree ? parseFloat(0).toFixed(2) : price}
                                    decimalsLimit={2}
                                    onValueChange={setPrice}
                                    maxLength={14}
                                />
                                <span className={classes.eur}>€</span>
                            </span>

                            <span>
                                <input
                                    type='checkbox'
                                    className={classes.checkbox}
                                    checked={isFree}
                                    onChange={toggleFree}
                                />
                                <span>{t(`license.concessionsModal.isFree`)}</span>
                            </span>
                        </div>
                    </div>

                    <div className={classes.footer}>
                        <button className={classes.footerBtnCancel} onClick={() => closeModal(false)}>
                            {t('CANCEL')}
                        </button>

                        <button className={classNames(classes.footerBtnSave, { [classes.disabled]: !canSave() })} onClick={saveConcession}>
                            {t('editConcession.confirm')}
                        </button>

                    </div>
                </div>
            </Box>
        </Modal>
    );
};
