import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import SwitchLanguage from './SwitchLanguage/SwitchLanguage';
import IconsContainer from './IconsContainer';
import LogoContainer from './LogoContainer/LogoContainer';
import { useTheme } from '@mui/material';
import BtnBackHome from './BtnBackHome';
import config from '../../config/config'
const setStyles = (theme) => {

    return makeStyles(() => ({

        navbar: {
            width: '100%',
            height: '45px',
            display: 'flex',
            alignItems: 'flex-end',
            position: 'fixed',
            top: '0',
            zIndex: '101',
            backgroundColor: theme.background ? theme.background : '#FFFFFF',
        },
        flexEnd: {
            display: 'flex',
            alignItems: 'flex-end',
        },
        navbarContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: '1px solid #707070',
            width: 'calc(100% - 120px)',
            margin: '0 60px',
            paddingBottom: '10px',
        },
        marginLeft16px: {
            marginLeft: '16px',
            border: '0.5px',
            width: '24px'
        },
        backToHome: {
            display: 'block',
            margin: '155px 60px 0 60px',
            alignItems: 'center',
            height: '37px',
            width: 'calc(100% - 120px)',
            borderBottom: '1px solid #707070',
            textTransform: 'uppercase',
            // WTC
            // eslint-disable-next-line no-dupe-keys
            display: 'flex',
            // eslint-disable-next-line no-dupe-keys
            alignItems: 'center',
            color: '#707070',
            '& span': {
                cursor: 'pointer'
            }
        }
    }))()
}


const MainHeader = () => {

    const theme = useTheme();
    const classes = setStyles(theme);
    const [pathname, setPathname] = useState('');

    useEffect(() => {
        setPathname(window.location.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname])

    const showBtnBackToHome = () => {
        if (pathname !== config.routes.usersPage && pathname !== config.routes.userCreation) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <Fragment >

            <div className={classes.navbar}>
                <div className={classes.navbarContainer}>
                    <SwitchLanguage classes={classes} />
                    <IconsContainer classes={classes} />
                </div>
            </div>

            <LogoContainer />

            {showBtnBackToHome() && (
                <BtnBackHome classes={classes} />
            )}

        </Fragment>
    )
}

export default MainHeader
