/* eslint react-hooks/exhaustive-deps: "off"*/
import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import UsersService from '../../../service/commons/userService';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { GrClose } from 'react-icons/gr';
import { useHistory } from 'react-router-dom';
import config from '../../../config/config'
import { AiOutlineCheck } from 'react-icons/ai';
import ErrorModal from '../../commons/Modals/ErrorModal';

const setStyles = makeStyles((theme) => {
    const themeBackground = theme && theme.background ? theme.background : null;
    const themeTitle = theme && theme.firstTitle && theme.firstTitle.color ? theme.firstTitle.color : null;
    const themeTitleFont = theme && theme.firstTitle && theme.firstTitle.font ? theme.firstTitle.font : null;
    return {
        root: {
            width: '100vw',
            height: '100vh',
            background: themeBackground,
        },
        displayFlex: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
        },
        generalContainer: {
            width: '100%',
            paddingTop: '80px',
            maxHeight: '180px',
        },
        iconContainer: {
            width: '90px',
            height: '90px',
            border: '2px solid',
            borderColor: themeTitle,
            borderRadius: '50%',
        },
        icon: {
            width: '50px',
            height: '50px',
        },
        confirmMsg: {
            width: '100%',
            marginTop: '24px',
            marginBottom: '12px',
            fontFamily: themeTitleFont,
            fontSize: '24px',
            color: themeTitle,
        },
        pointer: {
            cursor: 'pointer'
        }


    }
})



function ConfirmEmail() {
    const classes = setStyles();
    const [t] = useTranslation();
    const history = useHistory();
    const [errorMsg, setErrorMsg] = useState()


    let { guid } = useParams();

    const [valid, setValid] = useState(false);

    useEffect(() => {

        UsersService.confirmEmail(guid)
            .then(() => {
                setValid(true);
                // console.log("EmailConfirmed, going to loginPage");
                setTimeout(() => {
                    window.location.replace("/App/Login");
                }, 5000)
            }).catch((err) => {
                console.log({ err });
                setErrorMsg(err.data.validationErrors[0].field);
            })
    }, [])


    const goToLogin = () => {
        history.replace(config.routes.login);
    }

    return (
        <>
            <div id='confirmEmail' className={classes.root}>
                <div className={classNames(classes.displayFlex, classes.generalContainer)}>
                    <div className={classNames(classes.displayFlex, classes.iconContainer)}>

                        {valid ? (
                            <AiOutlineCheck className={classes.icon} />
                        ) : (
                            <GrClose className={classes.icon} />
                        )}

                    </div>

                    <div className={classNames(classes.displayFlex, classes.confirmMsg)}>
                        {valid ? t('confirmEmail.confirmEmail') : t('confirmEmail.expiredLink')}
                    </div>
                    {valid && (<span>{t('confirmEmail.goingToLogin')}</span>)}
                    <span className={classes.pointer} onClick={() => goToLogin()}>{t('confirmEmail.goToLogin')}</span>


                </div>
            </div>
            {errorMsg && (
                <ErrorModal
                    errorFields={errorMsg}
                    closeModal={() => setErrorMsg()}
                />
            )}
        </>
    )

}

export default ConfirmEmail;