import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

/**
 * formats the date in the local zone: "dd-mm-yyyy [hh:mm]"
 */
export const formatDate = (utcDate, nullText = '', showTime = true) => {
    if (!utcDate) {
        return nullText;
    }
    return format(
        new utcToZonedTime(utcDate),
        showTime ? 'dd-MM-yyyy HH:mm' : 'dd-MM-yyyy'
    );
};

/**
 * prints the date as the original utc: "dd-mm-yyyy [hh:mm]"
 */
export const printDate = (utcDate, nullText = '', showTime = true) => {
    if (!utcDate) {
        return nullText;
    }
    return format(
        new utcToZonedTime(utcDate, '+00.00'),
        showTime ? 'dd-MM-yyyy HH:mm' : 'dd-MM-yyyy'
    );
};
