/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import GlobalDataStore from '../../GlobalDataStore';
import TranlsationsService from '../../service/translationsService';
import { saveCultures } from '../../redux/slices/translationSlice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import config from '../../config/config'
function AdminLogin(props) {
    const history = useHistory();
    const dispatch = useDispatch();

    const onLogin = async () => {
        try {
            // LOGIN
            const params = queryString.parse(props.location.search);
            if (!params['token']) {
                throw new Error('parameter "token" not provided');
            }
            localStorage.setItem("Token", params['token']);
            localStorage.setItem("RefreshToken", params['refreshToken']);
            GlobalDataStore.extractInfoFromToken();
            GlobalDataStore.setIsLogin(true);
            localStorage.removeItem('Basket');
            // TRADUZIONI
            const transResp = await TranlsationsService.getTranslationsFilters();
            dispatch(saveCultures(transResp));
            // CARRELLO
            // await BasketService.concatBasket();
            localStorage.removeItem('Basket');
            // history.replace("/adminArea");
            // console.log('logged in')
            history.replace(config.routes.digitalResourcesListAdmin);
        } catch (err) {
            console.error('login failed', err);
            GlobalDataStore.setIsLogin(false);
        }
    };

    useEffect(onLogin, []);

    return (
        <div>

        </div>
    )
}

AdminLogin.propTypes = {

}

export default AdminLogin

