import { constants } from "../../config/config";
import request from "../base/baseService";

//let url = `${constants.api.url}api/export`;

const getRequest = (page, pageSize = 3) => {
    return request({
        url: constants.paths.getExportCsv(page, pageSize),
        method: 'POST',
        data: {}
    })
}

const deleteRequest = (idRequest) => {
    return request({
        url: constants.paths.deleteRequest(idRequest),
        method: 'DELETE'
    })
}

const createRequest = (type, filters) => {
    return request({
        url: constants.paths.createRequest(type),
        method: 'POST',
        data: {
            ...filters,
            timeZone: -new Date().getTimezoneOffset()
        }
    })
}

export const ExportService = {
    getRequest,
    deleteRequest,
    createRequest
}

export default ExportService;