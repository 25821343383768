/**
 * Gestisce gli errori e i redirect generati dal baseService.js che è JS puro,
 * mentre questo è un componente React.
 */

import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ErrorModalAdmin from "../../AdminArea/common/Modals/ErrorModal";
import ErrorModalUsers from "../../UsersArea/commons/Modals/ErrorModal";
import { setRedirectWhenExpired, setLastOccurredError } from "../../service/base/baseService";
import { isAdminArea, isUrlRooted } from "../utils";
import { useTranslation } from 'react-i18next';


export const BaseHelper = () => {
    const history = useHistory();
    const [showAdminError, setShowAdminError] = useState(false);
    const [showUsersError, setShowUsersError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorTitle, setErrorTitle] = useState(null);
    const [t] = useTranslation();


    const skipSpecificResponse = response => {
        // console.log(response);
        if (response.data?.data?.noErrorPopup === true) {
            // skip "/api/authentication/authenticate"
            return true;
        }
        if (response.data?.data?.noMoreVoc === true) {
            return true;
        }
        return false;
    };

    const setTitleAndMessage = value => {
        const error = value?.response?.data || {};
        if (error.status === 400 && error.isValid === false) {
            setErrorTitle(t('baseHelper.validationError'));
            const items = error.validationErrors;
            if (Array.isArray(items) && items.length) {
                setErrorMessage(items
                    .map(item => item?.error || {})
                    .map(item => {
                        let message = item.errorCodeDescription || '--';
                        if (item.errorCode) {
                            message = `[Code: ${item.errorCode}] ${message}`
                        }
                        return message;
                    }));
            } else {
                setErrorMessage(t('baseHelper.validationMsg'));
            }
        } else {
            setErrorTitle(t('baseHelper.genericError'));
            setErrorMessage(
                error.message || t('baseHelper.genericMsg')
            );
        }
    };

    useEffect(() => {
        setRedirectWhenExpired(value => {
            if (value) {
                if (isUrlRooted(value)) {
                    window.location.href = value;
                } else {
                    history.push(value);
                }
            }
        });
        setLastOccurredError(value => {
            if (value && !showAdminError && !showUsersError) {
                const response = value.response;
                if (!response || !skipSpecificResponse(response)) {
                    setTitleAndMessage(value);
                    if (isAdminArea()) {
                        setShowAdminError(true);
                    } else {
                        setShowUsersError(true);
                    }
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {showAdminError && (
                <ErrorModalAdmin
                    title={errorTitle}
                    errorFields={errorMessage}
                    closeModal={() => setShowAdminError(false)}
                />
            )}
            {showUsersError && (
                <ErrorModalUsers
                    title={errorTitle}
                    errorFields={errorMessage}
                    closeModal={() => setShowUsersError(false)}
                />
            )}
        </>
    );
};