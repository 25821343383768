import React, { Suspense, Fragment, useEffect } from 'react';
import { Route } from 'react-router-dom';
import Loader from '../../commons/Loader';
import { useLocation, useHistory } from 'react-router-dom';
import adminRoutes from '../../config/routes/adminRoutes';
import sysAdminRoutes from '../../config/routes/adminRoutes';
import SideBar from '../SideBar';
import config from '../../config/config'
import './index.scss';

const MainContainer = () => {
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const path = location.pathname.toLowerCase();
        if (path === '/adminarea/' || path === '/adminarea') {
            history.push(config.routes.digitalResourcesListAdmin);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderRoutes = () => {
        return adminRoutes
            // .concat(sysAdminRoutes)
            .map((route, index) => {
                return <Route
                    key={index}
                    path={route.path}
                    component={route.component}
                    exact={route.exact}></Route>
            });
    }

    return (
        <Fragment>
            <div id='adminMainContainer' style={{ display: 'flex' }}>
                <SideBar />

                <Suspense fallback={(<Loader />)}>

                    {renderRoutes()}

                </Suspense>
            </div>
        </Fragment>
    )
}

export default MainContainer
