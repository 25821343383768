import { createSlice } from '@reduxjs/toolkit';

export const translationsSlice = createSlice({
  name: 'translations', // Reducer Name
  initialState: {
    // language: {},
    // jsonTranslations: {},
    cultures: [],
    actualLanguage: '',
  },

  reducers: {
    saveCultures: (state, action) => {
      if (action.payload) {
        state.cultures = action.payload.cultures;
      }
    },

    updateActualLang: (state, action) => {
      if (action.payload) {
        state.actualLanguage = action.payload;
      }
    }
  },
})

export const { saveCultures, updateActualLang } = translationsSlice.actions;

export default translationsSlice.reducer;