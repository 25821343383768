/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { isFunction } from 'lodash';

const setStyles = makeStyles((theme) => {
    const themeTitleColor = theme && theme.secondTitle && theme.secondTitle.color ? theme.secondTitle.color : null;
    const themeTitleFont = theme && theme.secondTitle && theme.secondTitle.font ? theme.secondTitle.font : null;

    return {
        labelStyle: {
            margin: '10px 0 5px 0',
            fontFamily: themeTitleFont,
            fontSize: '16px',
            color: themeTitleColor
        },
        inputStyle: {
            margin: '5px 0 10px 0',
            padding: '5px',
            background: 'rgba(255, 255, 255, .2)',
            border: 'none',
            borderBottom: '1px solid #707070',
            height: '26px',
            boxSizing: 'border-box',
            width: '100%',
            color: 'black',
            fontSize: '16px',
            fontWeight: '100',
            // '-webkit-box-shadow': '0 0 0px 1000px white inset',
            '&:focus': {
                outline: 'none',
            }
        },
        error: {
            color: 'red'
        },
        iconContainer: {
            height: '0px'
        },
        eyeIcon: {
            position: 'relative',
            bottom: '32px',
            left: 'calc(100% - 20px)',
            cursor: 'pointer'
        },
        blueIcon: {
            color: themeTitleColor ? themeTitleColor : '#4A90E2',
        }
    }
})

function Access({
    label,
    type,
    initValue,
    outputValue,
    outputEnter,
    mandatoryError,
}) {
    const classes = setStyles();
    const [value, setValue] = useState("");
    const [showPsw, setShowPsw] = useState(false)

    useEffect(() => {
        setValue(initValue);
    }, [initValue])

    const onChangeInput = (value) => {
        setValue(value);
        outputValue(value.trim());
    }

    return (
        <div>
            <label className={mandatoryError && !value ? classNames(classes.labelStyle, classes.error) : classes.labelStyle}>{label}</label>
            <input
                type={!showPsw ? type : 'text'}
                className={classes.inputStyle}
                value={value}
                onChange={(event) => onChangeInput(event.target.value)}
                onKeyDown={e => e && e.key === 'Enter' && isFunction(outputEnter) ? outputEnter() : null}
            />

            {type === 'password' && (
                <div className={classes.iconContainer}>
                    {showPsw ? (
                        <FaEye className={classes.eyeIcon} onClick={() => setShowPsw(!showPsw)} />
                    ) : (
                        <FaEyeSlash className={classes.eyeIcon} onClick={() => setShowPsw(!showPsw)} />
                    )}

                </div>
            )}
        </div>
    )
}

Access.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    initValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    outputValue: PropTypes.func,
    outputEnter: PropTypes.func,
    mandatoryError: PropTypes.bool,
}

Access.defaultProps = {
    mandatoryError: false,
}

export default Access;