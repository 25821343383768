/* eslint react-hooks/exhaustive-deps: "off" */
import React from 'react';
import {  styled } from '@mui/styles';
import MultivalueFilter from './FiltersType/MultivalueFilter';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import PropTypes from 'prop-types';
import TextFilter from './FiltersType/TextFilter';

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '24px 0 !important',
  paddingTop: ' 0px !important',
  width: '100%',
}));

function FilterContainer({
  filter,
  getNewFilterValue,
  isApplyingFilters,
  emptyFilter
}) {  
  
  return (
    <>
      <AccordionDetails>
        {filter.type === 'multivalue' && (
          <MultivalueFilter
          filterData={filter}
          onSelectOptions={getNewFilterValue}
          emptyFilter={emptyFilter}
          ></MultivalueFilter>
        )}
        {filter.type === 'text' && (
          <TextFilter
          isApplyingFilters={isApplyingFilters}
          onChangeText={getNewFilterValue}
          emptyFilter={emptyFilter}
          ></TextFilter>
        )}
      </AccordionDetails>
    </>
  )
}

FilterContainer.propTypes = {
    valueSelected: PropTypes.string,
    outputValue: PropTypes.func,
}

export default FilterContainer;

