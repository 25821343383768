import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import OutsideClickHandler from 'react-outside-click-handler';
import { isArray } from 'lodash';
import { useTranslation } from 'react-i18next';


const setStyles = makeStyles((theme) => {
    const themeBackground = theme && theme.background ? theme.background : null;
    const themeTitle = theme && theme.firstTitle && theme.firstTitle.color ? theme.firstTitle.color : 'black';
    const themeTitleFont = theme && theme.firstTitle && theme.firstTitle.font ? theme.firstTitle.font : null;
    return {
        modalContainer: {
            width: 500,
        },
        //header
        header: {
            color: themeTitle,
            fontSize: themeTitleFont,
            background: themeBackground,
        },
        //body
        bodyTitle: {
            marginBottom: '12px',
        },
        listItem: {
            margin: '4px 0',
        },
        //footer:
        btnClose: {
            cursor: 'pointer',
            color: themeTitle,
        }
    }
})

function ErrorModal({
    title,
    errorFields,
    //Output
    closeModal,
}) {

    const classes = setStyles();
    const [t] = useTranslation();


    return (
        <>
            <OutsideClickHandler onOutsideClick={() => closeModal()}>
                <Modal
                    open={true}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    centered="true">
                    <Box className={`rl-modal-container ${classes.modalContainer} `} >
                        <div className={`rl-modal-header ${classes.header}`} >
                            <span>{title ? title : t('modals.errorModal.title')}</span>
                        </div>

                        <div className='rl-modal-body'>
                            <div className={classes.bodyTitle}>{t('modals.errorModal.bodyTitle')}:</div>
                            {isArray(errorFields) && errorFields?.length ? (
                                <ul>
                                    {errorFields.map((item, idx) =>
                                        <li className={classes.listItem} key={idx}>{item}</li>
                                    )}
                                </ul>
                            ) : (
                                <ul>
                                    <li className={classes.listItem}>{errorFields}</li>
                                </ul>

                            )}
                        </div>

                        <div className={`rl-modal-footer ${classes.footer} `} >
                            <div className={classes.btnClose} onClick={() => closeModal()}>{t('modals.errorModal.btnClose')}</div>
                        </div>
                    </Box>
                </Modal>
            </OutsideClickHandler>
        </>
    )
}

// ErrorModal.propTypes = {
//     showModal: PropTypes.bool,
//     title: PropTypes.string,
//     errorFields: PropTypes.array,
//     //Output
//     closeModal: PropTypes.func,
// }

export default ErrorModal;

