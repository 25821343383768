import request from "../base/baseService";
import { constants } from "../../config/config"
import GlobalDataStore from "../../GlobalDataStore";

let url = `${constants.api.url}api/basket`;


const getBasket = () => {
    if (GlobalDataStore.getIsLogin()?.toLowerCase() === 'true') {
        return request({
            url: `${url}/getemptybasket/${GlobalDataStore.getTenantId()}`,
            method: 'GET',
        })
    } else {
        const browserBasket = localStorage.getItem("Basket");
        if (browserBasket) {
            return request({
                url: `${url}/getbasket/${GlobalDataStore.getTenantId()}`,
                method: 'POST',
                data: JSON.parse(browserBasket)
            })
        } else {
            return request({
                url: `${url}/getemptybasket/${GlobalDataStore.getTenantId()}`,
                method: 'GET',
            })
        }
    }
    // }
}

const addDigitalResource = (idDigitalResource, idLicense, licenseVersion, forceWip = false) => {
    const digitalResourcesToAdd = {
        idDigitalResource: idDigitalResource,
        idLicense: idLicense,
        licenseVersion: licenseVersion,
        forceBasketToWip: forceWip
    };

    if (GlobalDataStore.getIsLogin()?.toLowerCase() === 'true') {
        return request({
            url: `${url}/adddigitalresource`,
            method: 'POST',
            data: digitalResourcesToAdd
        })
    } else {
        let storge = localStorage.getItem("Basket");
        let array = [];
        if (storge) {
            array = JSON.parse(storge);
            let exist = array.find(remove => remove.idDigitalResource === idDigitalResource
                && remove.idLicense === idLicense);
            if (exist != null) {
                return "Digital resource già presente nel carrello con questa licenza";
            }
        }
        array.push(digitalResourcesToAdd);
        localStorage.setItem("Basket", JSON.stringify(array));
        return Promise.resolve();
    }
}

const removeDigitalResource = (idDigitalResourceToRemove) => {
    if (GlobalDataStore.getIsLogin()?.toLowerCase() === 'true') {
        return request({
            url: `${url}/removedigitalresource/${idDigitalResourceToRemove}`,
            method: 'PUT'
        })
    } else {
        let storge = localStorage.getItem("Basket");
        let array = [];
        array = JSON.parse(storge);
        let remove = array.findIndex(remove => remove.idDigitalResource === idDigitalResourceToRemove);
        array.splice(remove, 1);
        if (array.length === 0) {
            localStorage.removeItem('Basket');
        }
        else {
            localStorage.setItem("Basket", JSON.stringify(array));
        }
        return Promise.resolve();
    }
}

const emptyBasket = () => {
    if (GlobalDataStore.getIsLogin()?.toLowerCase() === 'true') {
        return request({
            url: `${url}/empty`,
            method: 'PUT'
        })
    } else {
        localStorage.removeItem('Basket');
        return Promise.resolve();
    }
}

const concatBasket = () => {
    let local = localStorage.getItem("Basket");
    if (local) {
        return request({
            url: `${url}/concat`,
            method: 'PUT',
            data: JSON.parse(local)
        })
    }
    return Promise.resolve();
}

const confirmBasket = () => {
    // if (_data) {
    //     // return ;
    //     return Promise.resolve(_data);
    // } else {
    return request({
        url: `${url}/confirmbasket`,
        method: 'PUT'
    })
    // }
}

const getUserInfo = () => {
    return request({
        url: `${url}/getuserinfo`,
        method: 'GET'
    })
}

const backToWip = () => {
    return request({
        url: `${url}/backtowip`,
        method: 'PUT'
    })
}

export const BasketService = {
    getBasket,
    addDigitalResource,
    emptyBasket,
    removeDigitalResource,
    concatBasket,
    confirmBasket,
    getUserInfo,
    backToWip
}

export default BasketService;