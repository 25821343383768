//adminArea
import Admin from '../../AdminArea/AdminMainContainer/MainContainer';

//usersArea
import UsersPage from '../../UsersArea/MainContainer/MainContainer';

import SystemAdminLogin from '../../SystemAdminArea/AdminLogin/SystemAdminLogin';
import CmwPlugin from '../../SystemAdminArea/CmwPlugin/CmwPlugin';

import ResetPassword from '../../UsersArea/Login/ResetPassword';
import ConfirmEmail from '../../UsersArea/Login/ConfirmEmail';

//payments
import NexiPay from '../../UsersArea/Payments/NexiPay';
import NexiFeedback from '../../UsersArea/Payments/NexiFeedback';
import PagoPaPay from '../../UsersArea/Payments/PagoPaPay';
import PagoPaFeedback from '../../UsersArea/Payments/PagoPaFeedback';

// import Login from '../../UsersArea/Login';
import DigitalResourceDetails from '../../UsersArea/DigitalResourceDetails';
// import CustomRequestsListAdmin from '../../AdminArea/CustomRequests/CustomRequestsListAdmin';
import CustomRequestDetailsPage from '../../AdminArea/CustomRequests/CustomRequestDetails';
import UtcDate from '../../Tests/UtcDate';

const baseRoutes = [

    //adminArea
    {
        path: '/AdminArea',
        component: Admin,
        name: 'AdminArea',
    },

    //SystemAdmin
    {
        path: '/SystemAdmin/Login',
        component: SystemAdminLogin,
        name: 'SystemAdminLogin',
    },
    {
        path: '/SystemAdmin/CmwPlugin/:idDR/:idTenant',
        component: CmwPlugin,
        name: 'SystemAdminCmwPlugin',
    },

    //usersArea
    {
        path: '/App',
        component: UsersPage,
        name: 'UsersPage',
    },

    {
        path: '/confirm-email/:guid',
        component: ConfirmEmail,
        name: 'ConfirmEmail',
    },

    {
        path: '/reset-pwd/:guid',
        component: ResetPassword,
        name: 'ResetPassword',
    },

    {
        path: '/DigitalResourceDetails',
        component: DigitalResourceDetails,
        name: 'DigitalResourceDetails',
    },

    {
        path: '/pay-with-nexi/:idOrder',
        component: NexiPay,
        name: 'NexiPay'
    },

    {
        path: '/nexi-feedback/',
        component: NexiFeedback,
        name: 'NexiFeedback'
    },

    {
        path: '/pay-with-pagopa/:idOrder',
        component: PagoPaPay,
        name: 'PagoPaPay'
    },

    {
        path: '/pagopa-feedback/',
        component: PagoPaFeedback,
        name: 'PagoPaFeedback'
    },
    {
        path: '/customRequests/Details/:id',
        component: CustomRequestDetailsPage,
        name: 'CustomRequestDetailsPage'
    },

    // routes to test components
    {
        path: '/test-utc-date',
        component: UtcDate,
        name: 'UtcDate'
    }
]

export default baseRoutes;
