import React from 'react';
import { makeStyles } from '@mui/styles';
import ChatSectionHeader from './ChatSectionHeader';
import ChatSectionBody from './ChatSectionBody';

const setStyles = makeStyles(() => ({
  root: {
    width: '100vw',
    // padding: ' 8px',
    background: '#f5f5f5',
    height: 'calc(100vh - 70px)'
  }
}));

const ChatSectionContainer = ({ chatInfo }) => {
  const classes = setStyles();
  return (
    <div className={classes.root}>
      <ChatSectionHeader chatInfo={chatInfo}></ChatSectionHeader>
      <ChatSectionBody chatInfo={chatInfo}></ChatSectionBody>
    </div>
  );
};

export default ChatSectionContainer;
