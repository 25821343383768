/**
 * Axios Request Wrapper
 * ---------------------
 *
 * @author  Sheharyar Naseer (@sheharyarn)
 * @license MIT
 *
 */

import axios from 'axios';
import { constants } from "../../config/config";
import differenceInSeconds from 'date-fns/differenceInSeconds';
import GlobalDataStore from '../../GlobalDataStore';
import { isAdminArea, isSubdomain } from '../../commons/utils';

const urlAuth = `${constants.api.url}api/authentication`;
let refreshingTokenAt = null;
let loginRedirectUrl = null;

/**
 * Create an Axios Client with defaults
 */
const client = axios.create({
    // baseURL: constants.api.url,
    baseURL: '',
    headers: {
        'Content-type': 'application/json',
    },
});

const noErrorAPIs = [
    '/pagopa/start-payment/',
    '/user/role'
];

const isPageFree = () => {
    const path = (window.location.pathname || '').toLowerCase();

    if (path.endsWith('/app') || path.endsWith('/app/')) {
        return true;
    }

    if (path.indexOf('/app/digitalresourcedetails/') !== -1) {
        return true;
    }
    if (path.indexOf('/app/newcr/') !== -1) {
        return true;
    }

    return false;
};

const requestHandler = request => {
    // request.headers['Accept-language'] = i18n.language;

    const jwtToken = localStorage.getItem("Token");
    if (jwtToken) {
        request.headers['Authorization'] = jwtToken;
    }

    const i18nextLng = localStorage.getItem("i18nextLng");
    if (i18nextLng) {
        request.headers['X-UserCulture'] = i18nextLng;
    }

    return request;
};

client.interceptors.request.use(requestHandler);

const showErrorMessage = error => {
    if (loginRedirectUrl) {
        const callback = notificationCallbacks.onRedirectWhenExpired;
        typeof callback === 'function' && callback(loginRedirectUrl);
        loginRedirectUrl = null;
    } else if (!isPageFree()) {
        const apiUrl = error?.config?.url?.toLowerCase();
        if (!apiUrl || noErrorAPIs.find(x => apiUrl.indexOf(x) === -1)) {
            const callback = notificationCallbacks.onLastOccurredError;
            typeof callback === 'function' && callback(error);
        }
    }
};

const refreshToken = async (failedRequest, failedResponse) => {
    if (failedResponse?.status !== 401) {
        return false;
    }

    if (failedRequest._retried) {
        return false;
    }

    failedRequest._retried = true;

    const refreshToken = localStorage.getItem('RefreshToken');
    if (!refreshToken) {
        return false;
    }

    const idUser = localStorage.getItem('idUser');
    if (!idUser) {
        return false;
    }

    if (refreshingTokenAt) {
        const waitForRefresh = () => new Promise(
            resolve => setTimeout(async () => {
                if (!refreshingTokenAt) {
                    resolve(!!localStorage.getItem('Token'));
                } else if (differenceInSeconds(new Date(), refreshingTokenAt) > 10) {
                    resolve(false);
                } else {
                    resolve(await waitForRefresh());
                }
            }, 100)
        );
        if (await waitForRefresh()) {
            failedRequest.headers['Authorization'] = localStorage.getItem('Token');
            return true;
        } else {
            return false;
        }
    } else {
        refreshingTokenAt = new Date();
    }

    try {
        const newTokens = await client.get(
            `${urlAuth}/refresh-token/${idUser}/${encodeURIComponent(refreshToken)}`
        );
        localStorage.setItem('Token', newTokens.data.jwtToken);
        localStorage.setItem('RefreshToken', newTokens.data.refreshToken);
        failedRequest.headers['Authorization'] = newTokens.data.jwtToken;
        GlobalDataStore.extractInfoFromToken();
        return true;
    } catch (ex) {
        if (ex.response?.status === 401) {
            GlobalDataStore.clearAuthentication();
            setRedirectUrl(ex.response?.data?.data?.roleLabel);
        }
        console.error('cannot refresh tokens', { error: ex, response: ex.response });
        return false;
    } finally {
        refreshingTokenAt = null;
    }
};

const setRedirectUrl = userRole => {
    if (isPageFree()) {
        loginRedirectUrl = null;
    } else if (userRole) {
        userRole = userRole.toLowerCase();
        if (userRole === 'tenantadmin') {
            loginRedirectUrl = constants.paths.comworkLogin();
        } else if (userRole === 'sysadmin') {
            loginRedirectUrl = '/SystemAdmin/Login';
        } else {
            GlobalDataStore.clearAuthentication();
            loginRedirectUrl = '/App/Login';
        }
    } else if (isSubdomain()) {
        if (isAdminArea()) {
            loginRedirectUrl = constants.paths.comworkLogin();
        } else {
            GlobalDataStore.clearAuthentication();
            loginRedirectUrl = '/App/Login';
        }
    } else {
        loginRedirectUrl = '/SystemAdmin/Login';
    }
};

/**
 * Request Wrapper with default success/error actions
 */
const request = function (options) {
    const onSuccess = function (response) {
        // eslint-disable-next-line no-console
        return response.data
    };

    const onError = async function (error) {
        if (await refreshToken(error.config, error.response)) {
            try {
                error.config.data = options.data;
                return onSuccess(await client(error.config));
            } catch (ex) {
                logFailure(ex);
                showErrorMessage(ex);
            }
        } else {
            logFailure(error);
            if (error?.response?.status === 401) {
                setRedirectUrl();
            }
            showErrorMessage(error);
        }
        return await Promise.reject(error.response || error.message);
    };

    const logFailure = function (error) {
        console.error('Request Failed:', error.config);
        if (error.response) {
            // Request was made but server responded with something
            // other than 2xx
            console.error('Status:', error.response.status);
            console.error('Data:', error.response.data);
            console.error('Headers:', error.response.headers);
        } else {
            // Something else happened while setting up the request
            // triggered the error
            console.error('Error Message:', error.message);
        }
    };

    return client(options).then(onSuccess).catch(onError);
};

export default request;

const notificationCallbacks = {
    onRedirectWhenExpired: null,
    onLastOccurredError: null
};

export const setRedirectWhenExpired = val => notificationCallbacks.onRedirectWhenExpired = val;
export const setLastOccurredError = val => notificationCallbacks.onLastOccurredError = val;
