/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useState } from 'react';
import { makeStyles, styled } from '@mui/styles';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import LeftColumn from '../LeftColumn';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';

import Typography from '@mui/material/Typography';
import { MdExpandMore } from 'react-icons/md';
import FilterContainer from './FilterContainer';
import { isEmpty } from 'lodash';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const setStyles = makeStyles(() => ({
    root: {
        padding: '0 .5em'
    },
    filterTitleContainer: {
        borderBottom: '1px solid #b1b1b1',
        flexShrink: 0,
        fontSize: '14px',
        lineHeight: '37px',
        width: '100%',
        color: '#4a4a4a',
        letterSpacing: '0.3px',
        fontWeight: '500',
        display: 'flex',
        justifyContent: 'space-between',
    },
    filtersListContainer: {
        overflowY: 'auto',
        overflowX: 'hidden',
        marginBottom: '55px',
        height: 'calc(83vh - 55px)'
    },
    heading: {
        color: '#4a4a4a !important',
        fontSize: '14px !important',
        transition: 'color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',
        fontWeight: '500 !important',
        lineHeight: '21px !important',
        letterSpacing: '0.3px !important',
    },
    headingExpanded: {
        color: '#de003d !important',
        fontWeight: '700 !important'
    },
    divBtnApplyFilters: {
        position: 'absolute',
        left: '4.4em',
        bottom: 0,
        width: '17.3em',
        // borderTop: '1px solid #ececec',
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: '1em'
    },
    btnApplyFilters: {
        backgroundColor: '#E7004E !important',
        height: '40px',
        color: '#ffffff',
        cursor: 'pointer',
        textTransform: 'uppercase',
        fontSize: '0.75rem',
        fontWeight: '500',
        lineHeight: '40px',
        display: "flex",
        justifyContent: 'center',
        borderTop: '1px solid #ececec',
        padding: '0 30px',
        borderRadius: '20px'
    },
    btnClearFilters: {
        // background: '#FFFFFF',
        // borderRadius: '15px',
        // border: '1px solid #E7004E',
        // padding: '2px 3px',
        // cursor: 'pointer',
        // display: 'flex',
        // alignItems: 'center',
        // '& span': {
        //     margin: '0 10px'
        // },
        width: '100px',
        height: 'auto'
    },

}))

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: 'transparent!important',
    boxShadow: 'none',
    '&:before': {
        bottom: '0',
        left: '18px',
        right: '18px',
    },
    // '&$expanded': {
    //     margin: 'auto',
    //     '&:before': {
    //         opacity: '1',
    //     },
    // },
    // '&$expanded + &': {
    //     '&:before': {
    //         display: 'block',
    //     },
    // },
    // '&$disabled': {
    //     backgroundColor: 'transparent',
    // }
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<MdExpandMore sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    padding: '0!important',
    minHeight: '40px !important',
    maxHeight: '40px',
    // '&$expanded': {
    //     minHeight: '60px',
    // },
    // margin: '10px, 0!important',    
    // '& .MuiAccordionSummary-content': {
    //   justifyContent: 'space-between',
    //     alignItems: 'center',
    //     '&$expanded': {
    //     margin: '12px 0',
    //     }
    // },
    // expanded: {}
}));


function SidebarFilters({
    filtersType,
    applyFilters,
    filtersValue,
    filtersStructure
}) {

    const classes = setStyles();
    const [t] = useTranslation();

    const [expanded, setExpanded] = useState(false);

    const [selectedFilters, setSelectedFilters] = useState({ ...filtersValue });


    const onToggleExpand = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const updateFilters = (value, property, type) => {
        const tmpSelectedFilters = { ...selectedFilters };
        tmpSelectedFilters[property] = value;
        setSelectedFilters(tmpSelectedFilters);
    }

    const cleanFilters = () => {
        setSelectedFilters({ ...filtersStructure });
        applyFilters(filtersStructure);
    }

    const renderFiltersSection = () => {
        return <div className={classes.root}>
            <div className={classes.filterTitleContainer}>
                <span>{t('filter.filters')}</span>
            </div>
            <div className={classes.filtersListContainer}>
                {filtersType && filtersType.length > 0 &&
                    filtersType.map((f, index) => (
                        <Accordion expanded={expanded === `panel-${index}`}
                            key={index}
                            onChange={onToggleExpand(`panel-${index}`)}
                        >
                            <AccordionSummary
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"

                            >
                                <Typography sx={{ flexShrink: 0 }} className={classNames(classes.heading, expanded === `panel-${index}` ? classes.headingExpanded : '')}>
                                    {t('filter.' + f.property)}
                                </Typography>
                            </AccordionSummary>
                            <FilterContainer
                                filter={f}
                                getNewFilterValue={(value) => updateFilters(value, f.property, f.type)}
                                emptyFilter={isEmpty(selectedFilters[f.property]) ? selectedFilters[f.property] : null}
                            ></FilterContainer>
                        </Accordion>
                    ))
                }


                <div className={classes.divBtnApplyFilters}>

                    <Button
                        className={`rl-red-border-bottom ${classes.btnClearFilters}`}
                        onClick={() => cleanFilters()}>
                        <span>{t('sidebarFilters.sidebarBtnClean')}</span>
                    </Button>

                    <Button
                        className={`rl-red-button ${classes.width100px}`}
                        onClick={() => applyFilters(selectedFilters)}>
                        <span>{t('sidebarFilters.sidebarBtnApply')}</span>
                    </Button>

                    {/* 
                    <div className={classNames(classes.btnApplyFilters, classes.btnClearFilters)}
                        onClick={() => cleanFilters()}
                    >Pulisci</div>
                    <div className={classes.btnApplyFilters}
                        onClick={() => applyFilters(selectedFilters)}
                    >Applica</div> */}
                </div>
            </div>
        </div>
    }

    return (
        <>
            <LeftColumn renderInformation={renderFiltersSection} isFilterBox={true}>
            </LeftColumn>
        </>
    )
}

SidebarFilters.propTypes = {
    valueSelected: PropTypes.string,
    outputValue: PropTypes.func,
}

export default SidebarFilters;

