/* eslint-disable no-unused-vars */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect, useState } from 'react';
import { constants } from '../../config/config';
import { makeStyles } from '@mui/styles';
import GlobalDataStore from '../../GlobalDataStore';
import BasketService from '../../service/usersService/basketService';
import DigitalResourceService from '../../service/digitalResourceService';
import { isEmpty } from 'lodash';
import DrDetailsCard from './DrDetailsCard';
import { useParams } from 'react-router-dom';
import { ConcessionSelect } from './LicenseSelect/ConcessionSelect';
import { useTranslation } from 'react-i18next';
import ShowHideLoader from '../../hooks/showHideLoader';
import { useHistory } from 'react-router';
import YesNoModal from '../commons/Modals/YesNoModal';
import classNames from 'classnames';

const setStyles = makeStyles((theme) => {
    const themeTitleColor = theme && theme.firstTitle && theme.firstTitle.color ? theme.firstTitle.color : null;
    const themeTitleFont = theme && theme.firstTitle && theme.firstTitle.font ? theme.firstTitle.font : null;
    return {
        titleContainer: {
            textAlign: 'center',
            color: themeTitleColor,
            fontFamily: themeTitleFont,
        },
        bodyContainer: {
            display: 'flex',
            [theme.breakpoints.down(1200)]: {
                justifyContent: 'center',
            },
            justifyContent: 'space-between',
            margin: '2em 4em',
            marginBottom: '0',
            paddingBottom: '4em',
        },
        leftContainer: {
            maxWidth: 'calc(100% - 452px)',
            marginRight: '4em',
        },
        imgDetailsContainer: {
            width: '20em',
            minHeight: '21em',
            // height: 'auto',
            width: '100%',
            borderLeft: '1px solid #737373',
            paddingLeft: '1em',
            display: 'flex',
            flexDirection: 'column',
        },
        spaceBetween: {
            justifyContent: 'space-between',
            minHeight: '30em',
        },
        emptyDiv: {
            width: '20em',
            height: '25em',
            maxWidth: 'calc(100% - 452px)',
        },
        imgContainer: {
            marginRight: '4em',
            width: '25em',
            // marginBottom: '32px',
            // maxHeight: '25em',
            overflow: 'hidden',
        },
        image: {
            width: 'calc(100% - 14px)',
            height: 'auto',
            boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
        },
        centralContainer: {
            display: 'flex',
            maxWidth: 'calc(100% - 400px)'
        },
        detailsCard: {
            fontSize: '14px',
            paddingTop: '8px',
            '& p': {
                margin: '7px 16px',
                textAlign: 'center',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',

            }
        },
        capitalize: {
            textTransform: 'capitalize'
        },
        capitalizeFirstLetter: {
            '&::first-letter': {
                textTransform: 'capitalize'
            }
        }
    }
})


function DigitalResourceDetails() {

    let { id } = useParams();
    const history = useHistory();
    const [t] = useTranslation();
    const [image, setImage] = useState('');
    const classes = setStyles();
    // const [listOfMetadata, setListOfMetadata] = useState([]);
    const [listOfImageQualityMetadata, setListOfImageQualityMetadata] = useState([]);
    const [listOfArtworkDetailsMetadata, setListOfArtworkDetailsMetadata] = useState([]);
    const [listOfCreditLineMetadata, setListOfCreditLineMetadata] = useState([]);
    const [details, setDetails] = useState();
    const [title, setTitle] = useState("");
    const [author, setAuthor] = useState("");
    const [selectedLicense, setSelectedLicense] = useState();
    const [showLoader, hideLoader] = ShowHideLoader();
    const [loaded, setLoaded] = useState(true);
    const [showOrderPending, setShowOrderPending] = useState(false);

    useEffect(() => {
        showLoader();
        setImage(constants.paths.image(GlobalDataStore.getTenantId(), id));
        DigitalResourceService.getDetails(id)
            .then((resp) => {
                setDetails(resp);
                hideLoader();
                setLoaded(false);
            })
            .catch((err) => {
                console.error({ err });
                hideLoader();
                setLoaded(false);
            });
    }, [])


    useEffect(() => {
        if (details !== undefined) {
            //setListOfMetadata([...details.resourceMetadata]);
            let array = [];
            array = details.resourceMetadata;

            let tempListOfArtworkDetailsMetadata = []
            let tempListOfImageQualityMetadata = []
            let tempListOfCreditLineMetadata = []

            // TODO fare enum
            array.forEach(element => {
                if (element.idMetadataCategory === 1) {
                    tempListOfImageQualityMetadata.push(element);
                }
                if (element.idMetadataCategory === 2) {
                    tempListOfArtworkDetailsMetadata.push(element);
                }
                if (element.idMetadataCategory === 3) {
                    tempListOfCreditLineMetadata.push(element);
                }
                if (element.metadata === "Title") {
                    setTitle(element.value);
                }
                if (element.metadata === "Author") {
                    setAuthor(element.value);
                }

            })
            setListOfArtworkDetailsMetadata(tempListOfArtworkDetailsMetadata);
            setListOfImageQualityMetadata(tempListOfImageQualityMetadata);
            setListOfCreditLineMetadata(tempListOfCreditLineMetadata);
        }
    }, [details])

    useEffect(() => {
    }, [selectedLicense])

    const addDr = async (forceWip = false) => {
        try {
            showLoader();
            setShowOrderPending(false);
            const success = await BasketService.addDigitalResource(
                id, selectedLicense.idLicense, selectedLicense.version, forceWip);
            if (success) {
                history.push("/App/Basket");
            } else {
                if (GlobalDataStore.getIsLogin()?.toLowerCase() === 'true') {
                    setShowOrderPending(true);
                }
                else {
                    history.push("/App/Basket");
                }
            }
        } finally {
            hideLoader();
        }
    };


    return (
        <>
            {!loaded && (
                <>
                    <div className={classes.titleContainer}>
                        {!isEmpty(title) &&
                            <h2>{title}</h2>
                        }
                        {!isEmpty(author) &&
                            <p>{author}</p>
                        }

                    </div>

                    <div className={classes.bodyContainer}>
                        <div className={classes.leftContainer}>
                            {image && (
                                <div
                                    className={classNames(classes.imgDetailsContainer,
                                        (!isEmpty(listOfArtworkDetailsMetadata) && !isEmpty(listOfImageQualityMetadata) && !isEmpty(listOfCreditLineMetadata) ? classes.spaceBetween : null))}>

                                    {!isEmpty(listOfArtworkDetailsMetadata) &&
                                        <div>
                                            <DrDetailsCard
                                                title={t('dr.imagedetails')}
                                                data={listOfArtworkDetailsMetadata}>
                                            </DrDetailsCard>
                                        </div>
                                    }

                                    {!isEmpty(listOfImageQualityMetadata) &&
                                        <div>
                                            <DrDetailsCard
                                                title={t('dr.imagequality')}
                                                data={listOfImageQualityMetadata}>
                                            </DrDetailsCard>
                                        </div>
                                    }

                                    {!isEmpty(listOfCreditLineMetadata) &&
                                        <div>
                                            <DrDetailsCard
                                                title={t('dr.creditline')}
                                                data={listOfCreditLineMetadata}>
                                            </DrDetailsCard>
                                        </div>
                                    }
                                </div>
                            )}

                        </div>
                        <div className={classes.centralContainer}>
                            <div className={classes.imgContainer}>
                                <img alt='' src={image} className={classes.image} />
                            </div>

                            <ConcessionSelect
                                addDr={addDr}
                                selectedLicense={selectedLicense}
                                outputLicense={(value) => setSelectedLicense(value)}
                                idDigitalResource={id}
                            />

                        </div>
                        <div className={classes.emptyDiv}></div>
                    </div>
                    {/* <License
                outputLicense={(value) => setSelectedLicense(value)} /> */}
                </>
            )}
            {showOrderPending && (
                <YesNoModal
                    showModal={showOrderPending}
                    title={t('drdetails.modaltitle')}
                    modalText={t('drdetails.modaldescr')}
                    yesPressed={async () => await addDr(true)}
                    noPressed={() => setShowOrderPending(false)}
                />
            )}
        </>
    )
}

export default DigitalResourceDetails;