import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomRequestService from '../../../service/adminServices/customRequestService';
import { saveStates } from '../../../redux/slices/customRequestSlice';
import { FormControl, InputLabel, MenuItem, ListItemText, Select, Checkbox, Input } from '@mui/material';
import DateRange from '../../../commons/components/DateRange';
import { usePersistentState, storePersistentState } from '../../../commons/persistentState';
import ShowHideLoader from '../../../hooks/showHideLoader';
import ExportService from '../../../service/adminServices/exportService';
import MessageModal from '../../common/Modals/MessageModal';
import config from '../../../config/config'
/* eslint react-hooks/exhaustive-deps: "off"*/

const setStyles = makeStyles(() => ({
    root: {
        width: 'calc(100% - 40px)',
        marginRight: '6px',
        minHeight: '70px',
        background: '#F5F5F5',
        padding: '3px 20px',
        marginBottom: '6px',
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        fontFamily: "Roboto",
    },
    formControlContainer: {
        display: 'flex'
    },
    filterTitle: {
        height: '70px',
        display: 'flex !important',
        alignItems: 'center',
        '& label': {
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '37px',
            marginRight: '50px',
            letterSpacing: '0.125em',
            textTransform: 'uppercase',
        }
    },
    menuItem: {
        padding: '6px 8px !important'
    },
    listItemText: {
        '& span': {
            overflowX: 'hidden !important',
            textOverflow: 'ellipsis'
        }
    },
    buttonContainer: {
        height: '70px',
        display: 'flex',
        alignItems: 'center',
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const DATE_LIMIT_MIN = '1/1/1900';
const DATE_LIMIT_MAX = '12/31/2099';

const SELECT_MENU_PROPS = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        }
    }
};

const FILTERS_PERSIST_KEY = 'CRFilterAdmin';

const CustomRequestFilterAdmin = forwardRef(({
    searchCallback
}, ref) => {
    const classes = setStyles();
    const [t] = useTranslation();
    const [args] = useState({ searchCallback });
    const [filterDates, setFilterDates] = useState(null);
    const [filterStates, setFilterStates] = useState(null);
    const [selectedStates, setSelectedStates] = usePersistentState(FILTERS_PERSIST_KEY, 'SelectedStates', []);
    const [firstLoad, setFirstLoad] = usePersistentState(FILTERS_PERSIST_KEY, 'FirstLoad', true);
    const [apply, setApply] = useState();
    const dispatch = useDispatch();
    const groupedStates = useSelector(state => state.customRequests.states.groupedStates);
    const loadedForStates = useSelector(state => state.customRequests.states.loadedFor);
    const idUserStates = useSelector(state => state.user.idUser);
    const refRange = useRef();
    const history = useHistory();
    const [showLoader, hideLoader] = ShowHideLoader();
    const [openModal, setOpenModal] = useState(false);
    const [enabled, setEnabled] = useState(true)




    const buildFilters = () => ({
        filters: {
            ...filterDates,
            idStates: filterStates
        }
    });

    const applyFilters = async page => {
        if (isEnabled()) {
            if (typeof args.searchCallback === 'function') {
                storePersistentState(FILTERS_PERSIST_KEY);
                await args.searchCallback(page, buildFilters());
            }
        }
    };

    const cleanFilter = () => {
        setFilterDates(null);
        setFilterStates(null);
        setSelectedStates([]);
        refRange.current.setDefaultRange();
        setTimeout(() => setApply(!apply));
    };

    const exportList = async () => {
        try {
            showLoader();
            await ExportService.createRequest('CR', buildFilters());
            setOpenModal(true);
        } finally {
            hideLoader();
        }
    };

    const goToExportPage = () => {
        setOpenModal(false);
        history.push(config.routes.exportCsv);
    }

    const changeDates = (from, to) => {
        if ((Date.parse(from) > Date.parse(DATE_LIMIT_MIN) && Date.parse(from) < Date.parse(DATE_LIMIT_MAX))
            && (Date.parse(to) > Date.parse(DATE_LIMIT_MIN) && Date.parse(to) < Date.parse(DATE_LIMIT_MAX))) {
            setEnabled(true)
        } else {
            setEnabled(false)
        }
        // console.log({
        //     from: from?.toLocaleDateString("it-IT") || '-',
        //     to: to?.toLocaleDateString("it-IT") || '-'
        // });
        setFilterDates({
            startDate: [from],
            endDate: [to]
        });
    };

    const changeStates = event => {
        const idStates = [];
        for (const key of event.target.value) {
            for (const state of groupedStates[key]) {
                idStates.push(state);
            }
        }
        setSelectedStates(event.target.value);
        setFilterStates(idStates);
    };

    const areDefaultFilters = () => {
        if (filterStates?.length) {
            return false;
        }
        if (!refRange.current?.isDefaultRange()) {
            return false;
        }
        if (!filterDates?.startDate) {
            return false;
        }
        if (!filterDates?.endDate) {
            return false;
        }
        return true;
    };

    const isEnabled = () => {
        return enabled ? true : false;
    }

    useImperativeHandle(ref, () => ({
        applyFilters
    }));

    useEffect(async () => {
        const idUser = idUserStates || -1;
        const culture = localStorage.getItem('i18nextLng') || '--';
        if (loadedForStates?.idUser !== idUser || loadedForStates?.culture !== culture) {
            dispatch(saveStates({
                states: await CustomRequestService.getStatesList(),
                loadedFor: { idUser, culture }
            }));
        }
    }, []);

    useEffect(async () => {
        if (apply === true || apply === false) {
            await applyFilters(null);
        }
    }, [apply]);

    useEffect(() => {
        if (apply === undefined && (!firstLoad || areDefaultFilters())) {
            setFirstLoad(false);
            setApply(true);
        }
    }, [filterDates, filterStates]);

    useEffect(() => {
        if (selectedStates) {
            changeStates({
                target: {
                    value: selectedStates
                }
            });
        }
    }, [history]);

    return (
        <>
            <div id='customRequestFilterAdmin' className={classes.root}>
                <div className={classes.formControlContainer}>

                    <div className={classes.filterTitle}>
                        <label>{t('filter.filters')} </label>
                    </div>
                    <FormControl variant="standard" sx={{
                        m: 1,
                        minWidth: '15em'
                    }}>
                        <InputLabel id='lblStates'>{t('customRequest.states')}</InputLabel>
                        <Select

                            labelId='lblStates'
                            input={<Input label={t('customRequest.states')} />}
                            multiple
                            value={selectedStates}
                            onChange={changeStates}
                            renderValue={selecteds => `${t('customRequest.selected')} :` + selecteds.length}
                            MenuProps={SELECT_MENU_PROPS}
                        >
                            {groupedStates && Object
                                .keys(groupedStates)
                                .sort((x, y) => x.localeCompare(y))
                                .map(key => (
                                    <MenuItem key={key} value={key} className={classes.menuItem}>
                                        <Checkbox checked={selectedStates.indexOf(key) !== -1} />
                                        <ListItemText primary={key} className={classes.listItemText} />
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <DateRange
                        resize={true}
                        ref={refRange}
                        changeCallback={changeDates}
                        persistKey={FILTERS_PERSIST_KEY}>
                    </DateRange>
                </div>
                <div className={classes.buttonContainer}>
                    <button
                        className='rl-transparent-button'
                        onClick={exportList}
                    >{t('customRequest.btnExport')}</button>
                    <button
                        className='rl-transparent-button'
                        onClick={cleanFilter}
                        disabled={areDefaultFilters()}
                    >{t('customRequest.btnClean')}</button>
                    <button
                        className={'rl-red-button'}
                        onClick={async () => await applyFilters(null)}
                    >{t('customRequest.btnApply')}</button>
                </div>
            </div>

            {openModal && (
                <MessageModal
                    showModal={openModal}
                    title={t('customRequest.btnDownload')}
                    btnConfirm={t('customRequest.btnExport')}
                    closeModal={() => setOpenModal(false)}
                    confirmMessage={() => goToExportPage()}
                />
            )}
        </>
    );
});

CustomRequestFilterAdmin.propTypes = {
    searchCallback: PropTypes.func.isRequired
};

export default CustomRequestFilterAdmin;
